import React from 'react'
import classes from './Textarea.module.css'

function isInvalid({valid, touched, shouldValidate}) {
	return !valid && shouldValidate && touched
}

function setFocus(event) {
	//console.log('event.target', event.target)

	let btn = document.getElementById('csBtn');

	if (btn) btn.classList.add('show')

}

const Textarea = props => {

	//console.log('TEXTAREA PROPS: ', props);

	
	
	const cls = [classes.Textarea]
	const htmlFor = `${Math.random()}`

	if (isInvalid(props)) {
		cls.push(classes.invalid)
	}

	if (props.className==='nomargin') {
		cls.push(classes.nomargin)
		cls.push(classes.nomaxwidth)
	}

	// let isManager = []
	// let isExecutor = []

	// if (props.users) {
	// 	isManager = props.users.filter( user => {return user.id === props.manager})
	// 	isExecutor = props.users.filter( user => {return user.id === props.executor})
	// }
	//<div className={cls.join(' ')}></div>

	return (
		<div className={cls.join(' ')}>

			<div className="spBlock w100percent">
				<div className="spbHeader"><label htmlFor={htmlFor}>{props.label}</label></div>
				<div className="spbBody vaTop">

					{
					props.userId
					?
					<>
						<span className={'currentUserComment' + (props.manager && props.userId === props.manager ? ' taskManagerSign' : '' ) + (props.executor && props.userId === props.executor ? ' taskExecutorSign' : '' ) } style={{backgroundImage: "url(" + props.userPhoto + ")"}}></span>
						<input type="hidden" name="commentUserId" value={props.userId} />	 
					</>	
					:
					null
					}

					<div className="commentShell">

						<textarea 				
							id={htmlFor}										
							onChange={props.onChange}
							placeholder={props.placeholder}
							value={(props.value ? props.value : '')}
							className={( (props.name=='f_taskTitle' || props.name=='f_goalTitle') && !props.isValid ? 'error' : '')}
							onFocus={event => setFocus(event)}
							
						>				
						</textarea>
						{
							isInvalid(props) 
							? <span>{props.errorMessage || `Введите верное значение`}</span> 
							: null
						}

						{
							props.name=='f_comment'
							?
							<button className='commentSaveButton' onClick={() => props.onBtnClick(true)} id="csBtn">Сохранить</button>
							:
							null
						}

					</div>

				</div>
			</div>
			
		</div>
	)
}

export default Textarea