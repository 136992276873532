import "../styles/ClientList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { stopUpdateCurrentClient } from "../store/actions/goal"

import { setTaskTableFilterClient } from "../store/actions/task"

class ClientList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  clients: [],
		  currentClient: [],
		  clientId: this.props.clientId
		};
	}

	updateClientList = () => {

		const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 184
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/clients/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
			this.setState({
				isLoaded: true,
				clients: result.data
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				isLoaded: true,
				error
			});
			}
		)
	}

	setValue = (event, id, name, logo, client=null) => {	

		console.log('setValue: EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-client'))

		// let control_val = event.target.getAttribute('data-client');
		// let control_text = event.target.innerText

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;

		//console.log('CLOSEST: ', event.target.closest('.cpFilter'))

		

		this.state.currentClient = [logo, name];

		//event.target.closest('.cpFilter').querySelector('.cpfSelected').innerHTML = '<span class="clientLogo cn_2"><img src="'+logo+'" alt="" /></span><span class="clientName cn_2">'+name+'</span>'
		event.target.closest('.cpFilter').querySelector('.cpfHiddenField').value = id

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		


		if (client) {
			const { dispatch } = this.props;

			//dispatch({type: "SET_TASKTABLE_FILTER_CLIENT", payload: {client: client}})

			console.log('---- SET VALUE -----', client);

			dispatch(setTaskTableFilterClient(client))
			
			this.props.onChangeHandlerList(id, 'f_clientId', client)	

		} else {

			this.props.onChangeHandlerList(id, 'f_clientId', client)		
		}
		
		this.hideDropDown()

		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}		
	}


	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick, false)
		this.updateClientList()

		const { dispatch } = this.props;

		//dispatch({type: "SET_TASKTABLE_FILTER_CLIENT", payload: {client: client}})
		dispatch(setTaskTableFilterClient(false))

		//if (this.props.value)
		//console.log('CLIENT LIST PROPS: ', this.props);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	componentDidUpdate() {		
		// if (this.props.clientId != this.state.clientId) {
		// 	this.setState({
		// 		clientId: this.props.clientId ? this.props.clientId : this.state.clientId
		// 	});
		// }
		
		if (this.props.isNeedUpdateCurrentClient) {
			//console.log('Обновляем клиента в списке клиентов - ставим текущего: ', this.props.isNeedUpdateCurrentClient);

			// this.setState({
			// 	clientId: this.props.clientId ? this.props.clientId : this.state.clientId
			// },
			// () => {
			// 	this.props.stopUpdateCurrentClient()
			// });		


		}		

		return null

		//return null
	}


	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};
	
	// getCurrentClient = (needle) => {

	// 	const { clients } = this.state;

	// 	for (let i=0; i<clients; i++) {
	// 		if (clients[i].id==needle) {
	// 			return <>
	// 			<span className="clientLogo"><img src={clients[i].logo} alt="" /></span>
	// 			<span className="clientName">{clients[i].name}</span>
	// 			</>
	// 		}
	// 	}

	// 	return <>
	// 		<span className="clientName">Выбрать...</span>
	// 	</>		

	// }

	toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	setTaskTableClient = (client, event) => {

		console.log('function setTaskTableClient', client)

		const { dispatch } = this.props;

		//dispatch({type: "SET_TASKTABLE_FILTER_CLIENT", payload: {client: client}})
		dispatch(setTaskTableFilterClient(client))

		//this.toggleDropDown(event)
		//console.log('handleClick 2');
		this.hideDropDown();
	}

	render() {

		/*console.log('ID: ', this.props.id);

		console.log('CLIENT LIST PROPS: ', this.props);
		console.log('CLIENT LIST PROPS CLIENT: ', this.props.clientId);
		console.log('CLIENT LIST STATE: ', this.state);
		console.log('CLIENT VALUE: ', this.props.value);
		console.log('CLIENT CURCL: ', this.props.isNeedUpdateCurrentClient.id);
		console.log('isNeedUpdateCurrentClient: ', this.props.isNeedUpdateCurrentClient);*/

		const { error, isLoaded, clients } = this.state;

		const { isSimple } = this.props;	

		

		
		let clLogo = '';
										
		let showValue
		
		//console.log('------ RENDER CLIENT LIST');

		if (this.props.isEditGoal || this.props.value || this.props.isNeedUpdateCurrentClient) {

			//console.log('this.props.isEditGoal: ', this.props.isEditGoal);

			clients.map( client => {			

				// if (client.id==this.props.value || client.id==this.props.clientId) {

				// 	console.log('+++++++++++ CLIENT MATCH: ', client.name);

				// 	clLogo = client.logo;

				// 	return showValue = <>
				// 		<span className="clientLogo"><img src={client.logo} alt="" /></span>
				// 		<span className="clientName">{client.name}</span>
				// 	</>
					
				// } else {
				// 	console.log('----------- CLIENT DOES NOT MATCH');	

				// 	clLogo = 'NO CL LOGO!';

				// 	return false
				// }


				if (client.id==this.props.value) {

					//console.log('+++++++++++ VSALUE CLIENT MATCH: ', client.name);

					clLogo = client.logo;

					showValue = <>
						<span className="clientLogo d003"><img src={client.logo} alt="" /></span>
						<span className="clientName">{client.name}</span>
					</>
					
				} 

				return null
			})


			if (!showValue) {
				clients.map( client => {

					if (client.id==this.props.isNeedUpdateCurrentClient.id) {
						clLogo = client.logo;

						showValue = <>
							<span className="clientLogo d0031"><img src={client.logo} alt="" /></span>
							<span className="clientName">{client.name}</span>
						</>
					}

					return null
				})
			}


		}

		if (this.state.currentClient.length > 0 && !this.props.isNeedUpdateCurrentClient) {
			showValue = <>
				<span className="clientLogo d002"><img src={this.state.currentClient[0]} alt="" /></span>
				<span className="clientName">{this.state.currentClient[1]}</span>
			</>
			this.state.currentClient = []

			clLogo = 'SPOILED CL LOGO!';
		}

		//console.log('++++++++ showValue: ', showValue)	
		//console.log('++++++++ clLogo: ', clLogo)
		//console.log('++++++++ isSimple: ', isSimple)

		if (!showValue) {
			//console.log('NO showValue')
			showValue = <>
				<span className="clientName defaultClient">Клиент</span>
			</>	
		} else {}

		if (error) {
			return <ul><li key='0'>Ошибка загрузки данных по клиентам. (1)</li></ul>;
		} else if (!isLoaded) {
			return <ul><li key='0'>Загружаем данные о клиентах... (2)</li></ul>;
		} else {

			return (
				
				(isSimple ? 
				<>
					<div className="spbHeader">Клиент</div>
					<div className="spbBody">						
						<div className="cpFilter">
						<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />
							<div className={'cpfSelected' + ((this.props.name=='f_clientId' && !this.props.isValid ? ' error' : ''))} onClick={(event) => this.toggleDropDown(event)}>{showValue}</div>
							<div className="cpfMenuShell">
								<div className="cpfMenu">
									<ul data-id={Math.random()}>
									{
									clients.map((client, index) => (
										<li key={index}>						
											<a href={'#clientId_'+index} className={this.props.isEditGoal && client.id===this.props.value ? 'checkedClass' : ''} data-client={client.id} onClick={(event) => this.setValue(event, client.id, client.name, client.logo, client)}>
												<span className="clientLogo data000"><img src={client.logo} alt="" /></span>
												<span className="clientName">{client.name}</span>
											</a>						
										</li>
									))
									}
									</ul>	
								</div>	
							</div>
						</div>
					</div>				
				</>		
				: 				
				<ul data-id={Math.random()}>					
					{
					this.props.id==4 
					? 
					<li key={-1}>						
						<a href="#clientId_0" onClick={ () => this.setTaskTableClient(false) } className={this.props.clientId===0 ? 'active' : ''}>						
							<span className="clientLogo Index_1"></span>
							<span className="clientName">Все</span>						
						</a>
					</li>
					:
					''
					}
				{
				clients.map((client, index) => (
					<li key={index}>						
						<a href={'#clientId_'+client.id} onClick={ this.props.id==4 ? (event) => this.setTaskTableClient(client, event) : (event) => this.props.getClientGoals(client, event)} className={this.props.clientId===client.id ? 'active' : ''}>
							{isSimple 
							?
							<>{client.name}</>
							:
							<>
							<span className="clientLogo Index_1"><img src={client.logo} alt="" /></span>
							<span className="clientName">{client.name}</span>
							</>
							}
						</a>						
					</li>
				))
				}</ul>	
				)			
			);
			
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	isNeedUpdateCurrentClient: state.goal.isNeedUpdateCurrentClient
});  

function mapDispatchToProps(dispatch) {
	return {
		stopUpdateCurrentClient: () => dispatch(stopUpdateCurrentClient()),
		setTaskTableFilterClient: (client) => dispatch(setTaskTableFilterClient(client)),
		dispatch: dispatch			
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);