//import shortid from "shortid";

export default function seed(store) {
  // console.log("Insert first list");
  //const firstListId = shortid.generate();

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '1', listTitle: "Backlog" }
  });  

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '2', listTitle: "Месяц" }
  });
  
  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '3', listTitle: "Следующая неделя" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '4', listTitle: "Неделя" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '5', listTitle: "В работе" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '6', listTitle: "Доработка" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '7', listTitle: "Ожидание третьих лиц" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '8', listTitle: "Проверка" }
  });

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: '9', listTitle: "Отработано" }
  });

  /*store.dispatch({
    type: "ADD_CARD",
    payload: {
      listId: firstListId,
      cardId: shortid.generate(),
      cardText: "First card"
    }
  });

  store.dispatch({
    type: "ADD_CARD",
    payload: {
      listId: firstListId,
      cardId: shortid.generate(),
      cardText: "Second card"
    }
  });
  

  console.log("Insert second list");
  const secondListId = shortid.generate();

  store.dispatch({
    type: "ADD_LIST",
    payload: { listId: secondListId, listTitle: "Second list" }
  });

  store.dispatch({
    type: "ADD_CARD",
    payload: {
      listId: secondListId,
      cardId: shortid.generate(),
      cardText: "Card 1"
    }
  });

  store.dispatch({
    type: "ADD_CARD",
    payload: {
      listId: secondListId,
      cardId: shortid.generate(),
      cardText: "Card 2"
    }
  });
  */

};
