//import "../styles/SimpleList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class SimpleList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  categories: []
		};
		//this.inputRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick, false);		
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};

	setValue = (event, value, name) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-category'))

		let control_val = value
		let control_text = name

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.closest('.cpFilter').querySelector('.cpfSelected').innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		this.props.onChangeHandler(control_val, 'f_goalCategoryId')

		this.hideDropDown()

		event.preventDefault()
	}

	toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	render() {

		const { error, isLoaded, } = this.state;	
		
		const { list } = this.props;	

		let showValue = 'Выбрать...';	
		
		//console.log('SIMPLELIST: this.props.value: ', this.props.value)
		//console.log('SIMPLELIST: props.list: ', list)

		if (list && this.props.value && this.props.value != '' && this.props.value != 0) {
			let showValueData = list.filter(one_list => {return one_list.value==this.props.value});//'Выбрать...';	
			if (showValueData.length > 0) {
				showValue = showValueData[0].name
			}
		}

		// if (this.props.isEditGoal) {
		// 	categories.map((category, index) => {
		// 		if (category.id===this.props.value) {
		// 			showValue = category.name
		// 		}
		// 	})
		// }

		// if (error) {
		// 	return <ul><li key='0'>Ошибка загрузки данных списка. (1)</li></ul>;
		// } else if (!isLoaded) {
		// 	return <ul><li key='0'>Загружаем данные о списке... (2)</li></ul>;
		// } else {
//this.props.isEditGoal && 		

			return (
				<>
				<div className="spbHeader">{this.props.label}</div>
				<div className="spbBody">
					<div className="cpFilter">
						<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />	
						<div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>{showValue}</div>
						<div className="cpfMenuShell">
							<div className="cpfMenu left50">
								<ul>{
									list.map((list_item, index) => (
										<li key={index}>						
											<a href={'#listId_'+index} className={list_item.value==this.props.value ? 'checkedClass' : ''} onClick={(event) => this.setValue(event, list_item.value, list_item.name)}>{list_item.name}</a>						
										</li>
									))
								}</ul>
							</div>
						</div>
					</div>
				</div>
				</>
			);
			
		//}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId
});  

export default connect(mapStateToProps)(SimpleList);