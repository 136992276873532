import React from 'react'
import classes from './Notice.module.css'

const Notice = props => {
  const cls = [
    classes.Notice,
    classes[props.type]
  ]

  return (
    <div
      className={cls.join(' ')}     
    >
      {props.text}	  
    </div>
  )
}

export default Notice