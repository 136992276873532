//import "../styles/JobCategoryList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class JobCategoryList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  categories: [],
		  clientId: false
		};
		//this.inputRef = React.createRef();
	}

	updateJobsCategoryList() {

		const { userId } = this.props;	
		
		const clientId = this.props.clientId;

		const taskData = {	
			userId,  
			clientId,    
			isNaked: 1,
			nc_ctpl: 187
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
			this.setState({
				isLoaded: true,
				categories: result.data,
				clientId
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				isLoaded: true,
				error,
				clientId
			});
			}
		)	
	}

	componentDidMount() {

		//console.log('Jobs Cat CDM');

		document.addEventListener("mousedown", this.handleClick, false);

		this.updateJobsCategoryList()
		
	}

	componentDidUpdate() {

		//console.log('Jobs Cat UPDATE');

		if (this.state.clientId != this.props.clientId)	{
			this.updateJobsCategoryList()
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};

	setValue = (event, target) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-category'))

		let control_val = event.target.getAttribute('data-category');
		let control_text = event.target.innerText

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.closest('.cpFilter').querySelector('.cpfSelected').innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		this.props.onChangeHandlerList(control_val, 'f_goalCategoryId')

		this.hideDropDown()

		event.preventDefault()
	}

	toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	render() {

		//console.log('Job category list RENDER, props: ', this.props);

		const { error, isLoaded, categories } = this.state;		

		let showValue = 'Выбрать...';		

		if (this.props.isEditGoal) {
			categories.map((category, index) => {
				if (category.id===this.props.value) {
					showValue = category.name
				}
			})
		}

		if (error) {
			return <ul><li key='0'>Ошибка загрузки данных по категориям. (1)</li></ul>;
		} else if (!isLoaded) {
			return <ul><li key='0'>Загружаем данные о категориях... (2)</li></ul>;
		} else {

			return (
				<>
				<div className="spbHeader">Категория работ</div>
				<div className="spbBody">
					<div className="cpFilter">
						<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />	
						<div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>{showValue}</div>
						<div className="cpfMenuShell">
							<div className="cpfMenu">
								<ul>{
									categories.map((category, index) => (
										<li key={index}>						
											<a href={'#categoryId_'+index} className={this.props.isEditGoal && category.id===this.props.value ? 'checkedClass' : ''} data-category={category.id} onClick={this.setValue}>{category.name}</a>						
										</li>
									))
								}</ul>
							</div>
						</div>
					</div>
				</div>
				</>
			);
			
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId
});  

export default connect(mapStateToProps)(JobCategoryList);