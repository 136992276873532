import "../styles/UserList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class UserList extends Component {

	constructor(props) {
		super(props);

		this.state = {
		  error: null,
		  isLoaded: false,
		  categories: [],
		  participants: [],
		  taskId: 0
		};

		//this.inputRef = React.createRef();
	}

	componentDidUpdate() {
		if (this.props.name === 'f_taskParticipantsIds' && this.state.taskId !== this.props.taskId) {
			this.setState({
				...this.state,
				taskId: this.props.taskId,
				participants: this.props.value
			})
		}		
	}

	componentDidMount() {

		document.addEventListener("mousedown", this.handleClick, false);

		const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 187
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
			this.setState({
				...this.state,
				isLoaded: true,
				categories: result.data
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				...this.state,
				isLoaded: true,
				error
			});
			}
		)
		
		//console.log('-03- USERLIST PROPS: ', this.props)
		
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};

	setValue = (event) => {
		// console.log('EVENT: ', event)
		//console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-category'))

		let control_val = event.target.getAttribute('data-user') ? event.target.getAttribute('data-user') : event.target.parentElement.getAttribute('data-user');
		let control_text = event.target.getAttribute('data-name') ? event.target.getAttribute('data-name') : event.target.parentElement.getAttribute('data-name');
		let control_photo = event.target.getAttribute('data-photo') ? event.target.getAttribute('data-photo') : event.target.parentElement.getAttribute('data-photo');

		// console.log('control_val: ', control_val);
		// console.log('control_text: ', control_text);
		// console.log('control_photo: ', control_photo);

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.closest('.cpFilter').querySelector('.userTaskBoxPhoto').classList.remove('noUser')
		event.target.closest('.cpFilter').querySelector('.userTaskBoxPhoto').style.backgroundImage = 'url('+control_photo+')'
		event.target.closest('.cpFilter').querySelector('.userTaskBoxName').innerHTML = control_text
		//innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		this.props.onChangeHandlerList(control_val, 'f_taskExecutorId')

		this.hideDropDown()

		event.preventDefault()
	}

	toggleDropDown = event => {
		event.target.closest('.spBlock').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.spBlock')).map( item => (item.classList.remove('menuDropDown')))
	}

	setExecutorSelf = event => {
		
		const user_id = this.props.userId
		const user_name = this.props.userName
		const user_photo = this.props.userPhoto

		document.querySelector('#data_f_taskExecutorId .userTaskBoxPhoto').classList.remove('noUser')
		document.querySelector('#data_f_taskExecutorId .userTaskBoxPhoto').style.backgroundImage = 'url('+user_photo+')'
		document.querySelector('#data_f_taskExecutorId .userTaskBoxName').innerHTML = user_name
		
		console.log('user_id: ', user_id);

		this.props.onChangeHandlerList(user_id, 'f_taskExecutorId')
         
	}

	setParticipantsValue = (event, user) => {		

		event.target.closest('.cpFilter').querySelector('.userTaskBoxPhoto').classList.remove('noUser')
		//event.target.closest('.cpFilter').querySelector('.userTaskBoxPhoto').style.backgroundImage = 'none'	
		
		let participants = this.state.participants

		//console.log('+ setParticipantsValue: user: ', user);
		//console.log('++ setParticipantsValue: participants: ', participants);

		let found_user_in_participants_array = participants.filter( participant => {
			//console.log('participant.participant_id: ', participant.participant_id)
			//console.log('user.id: ', user.id)
			return participant.participant_id===user.id
		});

		//console.log('++ found_user_in_participants_array: ', found_user_in_participants_array);

		if (found_user_in_participants_array.length === 0) {
			participants.push({'participant_id': user.id, 'name': user.name, 'photo': user.photo})
		} else {
			participants = participants.filter( participant => {return participant.participant_id!==user.id})
		}

		//console.log('+++ setParticipantsValue: participants 2: ', participants);

		this.setAfterStateAction(participants)

		this.hideDropDown()

		event.preventDefault()

	}

	setAfterStateAction = (participants) => {

		console.log('participants: ', participants)

		this.setState({
			...this.state,
			participants
		})

		this.props.onChangeHandlerList(participants, 'f_taskParticipantsIds')
		
	}
	
	joinParticipants = () => {

		let participants = this.state.participants

		//console.log('+ setParticipantsValue: current user ID: ',this.props.userId);
		//console.log('++ setParticipantsValue: participants: ', participants);

		// let found_user_in_participants_array = participants.filter( participant => {
		// 	console.log('participant.participant_id: ', participant.participant_id)
		// 	console.log('user.id: ', user.id)
		// 	return participant.participant_id===user.id
		// });

		// console.log('++ found_user_in_participants_array: ', found_user_in_participants_array);

		//if (found_user_in_participants_array.length === 0) {			

			participants.push({'participant_id': this.props.userId, 'name': this.props.userName, 'photo': this.props.userPhoto})
		//} else {
		//	participants = participants.filter( participant => {return participant.participant_id!==user.id})
		//}

		//console.log('+++ setParticipantsValue: participants 2: ', participants);

		this.setAfterStateAction(participants)

		this.hideDropDown()

		//event.preventDefault()
	}

	render() {

		const { error, isLoaded, categories, participants } = this.state;
		const { users, userId } = this.props;			
		
		// console.log('-02- USERLIST STATE: ', this.state)
		// console.log('-02- USERLIST PROPS: ', this.props)

		let showValue = '';		

		if (this.props.isEditGoal) {
			categories.map((category, index) => {
				if (category.id===this.props.value) {
					showValue = category.name
				}
			})
		}

		let hiddenId;
		let userBlock;
		let isCanParticipate = true;

		if (this.props.userId === this.props.manager || this.props.userId === this.props.executor ) {
			isCanParticipate = false;
		}

		if (this.props.name==='f_taskParticipantsIds' && participants) {
			//console.log('participants: ', participants);
			participants.map( participant_id => {
				// console.log('PAIR 1: ' + participant_id.participant_id+'-'+this.props.manager)
				// console.log('PAIR 2: ' + participant_id.participant_id+'-'+this.props.executor)
				// console.log('PAIR 3: ' + participant_id.participant_id+'-'+this.props.userId)
				// NOT A  MANAGER, NOT A EXECUTOR, NOT IN PARTICIPANT LIST
				if (participant_id.participant_id === this.props.userId) {
					isCanParticipate = false;
				}
			})
		}	

		if (this.props.name==='f_taskManagerId') {
			hiddenId = this.props.value ? this.props.value : 0;
			
			//console.log('USERS: ', users);
			//console.log('hiddenId: ', hiddenId);
			const taskManagerArray = hiddenId>0 && users.length>0 ? (users.filter(user => {return user.id === hiddenId}))[0] : {photo: '', name: ''}

			//console.log('taskManagerArray: ', taskManagerArray);
			
			userBlock = <>
				<input className="cpfHiddenField" type="hidden" name={this.props.name} value={hiddenId} />
				<div className="userTaskBox">
					<div className={'userTaskBoxPhoto'  + (hiddenId ? '' : ' noUser')} style={hiddenId ? {backgroundImage: "url(" + taskManagerArray.photo + ")"} : null}></div>
					<div className="userTaskBoxName">{taskManagerArray.name}</div>
				</div>
			</>
		}

		if (this.props.name==='f_taskExecutorId') {
			hiddenId = this.props.value ? this.props.value : 0;	

			const taskManagerArray = hiddenId>0 && users.length>0 ? (users.filter(user => {return user.id === hiddenId}))[0] : {photo: '', name: ''}
			
			userBlock = <>
				<input className="cpfHiddenField" type="hidden" name={this.props.name} value={hiddenId} />
				<div className={'userTaskBox' + (this.props.name=='f_taskExecutorId' && !this.props.isValid ? ' error' : '')}>
					<div className={'userTaskBoxPhoto noCrown' + (hiddenId>0 ? '' : ' noUser')} style={hiddenId>0 ? {backgroundImage: "url(" + taskManagerArray.photo + ")"} : null} onClick={(event) => this.toggleDropDown(event)}></div>
					<div className="userTaskBoxName">
						{
						hiddenId						
						? 
						<span>{taskManagerArray.name}</span> 
						: 
						<span 
							className="userTaskBoxNameAdd" 
							onClick={
								event => this.setExecutorSelf(event)
							}
						>
							Я — исполнитель
						</span>
						}
					</div>
				</div>
				<div className="cpfMenuShell">
					<div className="cpfMenu topZero">
						<ul>{
							users.map((user, index) => (
								<li key={index}>						
									<a href={'#userId_'+index} className={this.props.isEditGoal && user.id===this.props.value ? 'checkedClass' : ''} data-user={user.id} data-name={user.name} data-photo={user.photo} onClick={ event => this.setValue(event) }>
										<span className="userListOneUserPhoto" style={{backgroundImage: "url(" + user.photo + ")"}}></span>
										<span className="userListOneUserName">{user.name}</span>										
									</a>						
								</li>
							))
						}</ul>
					</div>
				</div>
			</>
		}



		// console.log('PARTICIPANTS: ', participants);	
		// console.log('USERS: ', users);


		if (error) {
			return <ul><li key='0'>Ошибка загрузки данных пользователей. (1)</li></ul>;
		} else if (!isLoaded) {
			return <ul><li key='0'>Загружаем данные о пользователях... (2)</li></ul>;
		} else {

			return (
				<>
				<div className="spbHeader">{this.props.label}</div>
				<div className="spbBody">
					<div className="cpFilter" id={'data_'+this.props.name}>
						{
						this.props.name==='f_taskManagerId' || this.props.name==='f_taskExecutorId' 						
						?
						userBlock
						:
						<>	
						{
							participants
							?
							participants.map( (participant_id, index) => (
								<input className="cpfHiddenField" key={index} type="hidden" name={this.props.name} value={participant_id.participant_id} />
							))
							:
							<input className="cpfHiddenField" key="0" type="hidden" name={this.props.name} value="" />
						}
						<div className="userTaskBox">
							<div className={'userTaskBoxPhoto'+ (participants && participants.length > 0 ? '' : ' noUser')} onClick={(event) => this.toggleDropDown(event)}>
							{
								participants.length > 0
								?								
								<div className="cardUsersCardCommentsUsers taskParticipants">
									{
										

										participants.map( (participant_id, index) => {

											let current_user = users.length>0 ? users.filter( user => {return user.id === (participant_id.participant_id ? participant_id.participant_id : participant_id)})[0] : {photo: '', name: ''};	
											// console.log('users: ', users)
											// console.log('participant_id: ', participant_id)
											// console.log('current_user: ', current_user)
											// console.log('current_user.photo: ', current_user.photo)

											return (<div key={index} className="cardUsersCardCommentsUsersUser"><img src={current_user.photo} alt="" /></div>)
										})
									}									
								</div>								
								:
								<></>
							}
							</div>
							<div className="userTaskBoxName">{(isCanParticipate ? <span className="userTaskBoxNameAdd" onClick={() => this.joinParticipants()}>Присоединиться</span> : null)}</div>
						</div>						
						<div className="cpfMenuShell">
							<div className="cpfMenu topZero left55">
								<ul>									
									{
									users.map((user, index) => {
										if (participants && (user.id !== this.props.manager && user.id !== this.props.executor)) {

											let isCurrentUserInList = participants.filter(participant => {return user.id === participant.participant_id})

											return (
											<li key={index}>						
												<a href={'#userId_'+index} className={this.props.isEditGoal && isCurrentUserInList.length>0 ? 'checkedClass' : ''} onClick={ (event) => this.setParticipantsValue(event, user) }>
													<span className={isCurrentUserInList.length>0 ? 'userListOneUserPlus minus' : 'userListOneUserPlus'}></span>
													<span className="userListOneUserPhoto" style={{backgroundImage: "url(" + user.photo + ")"}}></span>
													<span className="userListOneUserName">{user.name}</span>										
												</a>						
											</li>
											)
										} else {
											return null
										}
									})
									}
								</ul>
							</div>
						</div>
						</>
						}
					</div>
				</div>
				</>
			);
			
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	userPhoto: state.auth.userPhoto,
 	userName: state.auth.userName
});  

export default connect(mapStateToProps)(UserList);