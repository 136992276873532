import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import throttle from "lodash.throttle";
import seed from "./seed";
import authReducer from "./store/reducers/auth";
import modalReducer from "./store/reducers/modal";
import goalReducer from "./store/reducers/goal";
import taskReducer from "./store/reducers/task";
import appReducer from "./store/reducers/app";
import thunk from 'redux-thunk'

import { updateTasksTable } from "./store/actions/task"

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;


const task = (state = {}, action) => {

 // console.log('TASK IN STORE', action);

  switch (action.type) {   

    case "SHOW_TASK_PANEL": {
      const { data } = action.payload.data;

      //console.log('==== DATA TASK IN STORE', data);

      return state;
    }   
    
    default:
      return state;
  }

}    

const board = (state = { lists: [] }, action) => {

  //console.log('BOARD REDUCER ACTION', action);

  switch (action.type) {   

    case "ADD_LIST": {
      const { listId } = action.payload;
      return { lists: [...state.lists, listId] };
    }    
    case "MOVE_LIST": {
      const { oldListIndex, newListIndex } = action.payload;
      const newLists = Array.from(state.lists);
      const [removedList] = newLists.splice(oldListIndex, 1);
      newLists.splice(newListIndex, 0, removedList);
      return { lists: newLists };
    }
    case "DELETE_LIST": {
      const { listId } = action.payload;
      const filterDeleted = tmpListId => tmpListId !== listId;
      const newLists = state.lists.filter(filterDeleted);
      return { lists: newLists };
    }
    default:
      return state;
  }
};

const listsById = (state = {}, action) => { 

  switch (action.type) {

    case "UPDATE_LISTS": {     

      // const {...lbid} = action.data.listsById;

      // let lid = action.data.listsById[0];
      // let lid2 = action.data.listsById[1];
      // let lid3 = action.data.listsById[2];
      // let lid4 = action.data.listsById[3];
      // let lid5 = action.data.listsById[4];
      // let lid6 = action.data.listsById[5];
      // let lid7 = action.data.listsById[6];
      // let lid8 = action.data.listsById[7];
      // let lid9 = action.data.listsById[8];

      // console.log(':::::action.data:::::', action.data.listsById);      
      // console.log('-------- listsById REDUCER ACTION, listsById: ', lbid);
      // console.log('-------- listsById REDUCER ACTION, lid: ', lid);
      //console.log('-------- listsById REDUCER ACTION, listsById: ', ...lbid);
      //console.log('listsById REDUCER ACTION, cardsById: ', cardsById);

      let lists = Object.assign({}, ...action.data.listsById)

      return {...lists}
      //[cardsById]: cardsById
     
    }

    case "ADD_LIST": {
      const { listId, listTitle } = action.payload;
      return {
        ...state,
        [listId]: { _id: listId, title: listTitle, cards: [] }
      };
    }
    case "CHANGE_LIST_TITLE": {
      const { listId, listTitle } = action.payload;
      return {
        ...state,
        [listId]: { ...state[listId], title: listTitle }
      };
    }
    case "DELETE_LIST": {
      const { listId } = action.payload;
      const { [listId]: deletedList, ...restOfLists } = state;
      return restOfLists;
    }
    case "ADD_CARD": {
      const { listId, cardId } = action.payload;
      return {
        ...state,
        [listId]: { ...state[listId], cards: [...state[listId].cards, cardId] }
      };
    }
    case "MOVE_CARD": {
      const {
        oldCardIndex,
        newCardIndex,
        sourceListId,
        destListId
      } = action.payload;

      //updateTasksTable()     

      // Move within the same list
      if (sourceListId === destListId) {
        const newCards = Array.from(state[sourceListId].cards);
        const [removedCard] = newCards.splice(oldCardIndex, 1);
        newCards.splice(newCardIndex, 0, removedCard);
        return {
          ...state,
          [sourceListId]: { ...state[sourceListId], cards: newCards }
        };
      }
      // Move card from one list to another
      const sourceCards = Array.from(state[sourceListId].cards);
      const [removedCard] = sourceCards.splice(oldCardIndex, 1);
      const destinationCards = Array.from(state[destListId].cards);
      destinationCards.splice(newCardIndex, 0, removedCard);
      return {
        ...state,
        [sourceListId]: { ...state[sourceListId], cards: sourceCards },
        [destListId]: { ...state[destListId], cards: destinationCards }
      };
    }
    case "DELETE_CARD": {     

      const { cardId: deletedCardId, listId } = action.payload;

      console.log('listsById DELETE_CARD, cardId: ', deletedCardId);

      const filterDeleted = cardId => cardId !== deletedCardId;
      return {
        ...state,
        [listId]: {
          ...state[listId],
          cards: state[listId].cards.filter(filterDeleted)
        }
      };
    }
    default:
      return state;
  }
};

const cardsById = (state = {}, action) => {
  switch (action.type) {

    case "UPDATE_CARDS": {

      //console.log('222 WTF????');
      //console.log('UPDATE_CARDS, action.data: ', action.data);

      let cards = Object.assign({}, ...action.data.cardsById)
      
      //console.log('++++ cardsById REDUCER ACTION, cardsById: ', cardsById);
      //console.log('listsById REDUCER ACTION, cardsById: ', cardsById);

      //return cards.map( cid_current => ...cid_current)
      return {...cards}
      //[cardsById]: cardsById
     
    }

    case "ADD_CARD": {
      const { cardText, cardId } = action.payload;
      return { ...state, [cardId]: { text: cardText, _id: cardId, goalName: '', clientName: '', planTime: '0', usedTime: '0', participants: []} };
    }
    case "CHANGE_CARD_TEXT": {
      const { cardText, cardId } = action.payload;
      return { ...state, [cardId]: { ...state[cardId], text: cardText } };
    }
    case "DELETE_CARD": {      

      const { cardId } = action.payload;

      console.log('cardsById DELETE_CARD, cardId: ', cardId);

      const { [cardId]: deletedCard, ...restOfCards } = state;
      return restOfCards;
    }
    // Find every card from the deleted list and remove it
    case "DELETE_LIST": {
      const { cards: cardIds } = action.payload;
      return Object.keys(state)
        .filter(cardId => !cardIds.includes(cardId))
        .reduce(
          (newState, cardId) => ({ ...newState, [cardId]: state[cardId] }),
          {}
        );
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  board,
  listsById,
  cardsById,
  auth: authReducer,
  modal: modalReducer,
  goal: goalReducer,
  task: taskReducer,
  app: appReducer
});

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }  
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedState = loadState();

const store = createStore(
  reducers, 
  persistedState, 
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

//console.log(store.getState());

if (!store.getState().board.lists.length) {
  //console.log("SEED");
  seed(store);
}

export default store;