import React, { Component } from 'react'
import Button from '../components/UI/Button/Button'
import Input from './UI/Input/Input'
import classes from '../styles/Auth.module.css'

import { connect } from 'react-redux'
import { auth } from '../store/actions/auth'

import Notice from './UI/Notice/Notice'

const validateEmail = (email) => {
	return String(email)
	  .toLowerCase()
	  .match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  );
};

class Auth extends Component {

	state = {
		isFormValid: false,
		isAuthNotice: false,		
		formControls: {
			email: {
				value: '',
				type: 'email',
				label: 'Email',
				errorMessage: 'Введите корректный email',
				valid: false,
				touched: false,
				validation: {
					required: true,
					email: true
				}
			},
			password: {
				value: '',
				type: 'password',
				label: 'Пароль',
				errorMessage: 'Введите корректный пароль',
				valid: false,
				touched: false,
				validation: {
					required: true,
					minLength: 6
				}
			}
		}
	}

	loginHandler = () => {	

		this.setState({
			...this.state, isAuthNotice: true
		})	

		this.props.auth(
			this.state.formControls.email.value,
			this.state.formControls.password.value,
			true
		)	

	}

	// registerHandler = () => {
		
	// }

	submitHandler = event => {
		event.preventDefault()
	}

	validateControl(value, validation) {
		if (!validation) {
			return true
		}

		let isValid = true

		if (validation.required) {
			isValid = value.trim() !== '' && isValid
		}

		if (validation.email) {
			isValid = validateEmail(value) && isValid
		}

		if (validation.minLength) {
			isValid = value.length >= validation.minLength && isValid
		}

		return isValid
	}

	onChangeHandler = (event, controlName) => {		

		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }

		control.value = event.target.value
		control.touched = true
		control.valid = this.validateControl(control.value, control.validation)

		formControls[controlName] = control

		let isFormValid = true

		let isAuthNotice = false

		Object.keys(formControls).forEach(name => {
			//isFormValid = formControls[name].valid && isFormValid
		})

		this.setState({
			formControls, isFormValid, isAuthNotice
		})

	}

	renderInputs() {
		return Object.keys(this.state.formControls).map((controlName, index) => {
			const control = this.state.formControls[controlName]
			return (
				<Input 
					key={controlName + index}
					type={control.type}
					value={control.value}
					valid={control.valid}
					touched={control.touched}
					label={control.label}
					shouldValidate={!!control.validation}
					errorMessage={control.errorMessage}	
					onChange={event => this.onChangeHandler(event, controlName)}			
				/>
			)
		})		
	}

	render() {	
		
		return (
			<div className={classes.Auth}>
				<div>
					<h1>Авторизация</h1>

					<form onSubmit={this.submitHandler} className={classes.AuthForm}>

						{ this.renderInputs() }

						<Button 
							type="success" 
							onClick={this.loginHandler}
							disabled={!this.state.isFormValid}
						>
							Войти
						</Button>

						{ (this.state.isAuthNotice && this.props.isAuthNotice ? <Notice text="Неверные данные авторизации" type="error" /> : null) }
{ 
/*
						<Button 
							type="primary" 
							onClick={this.registerHandler}
						>
							Зарегистрироваться
						</Button>
*/
}						
					</form>
				</div>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		auth: (email, password, isLogin) => dispatch(auth(email, password, isLogin))
	}
}

function mapStateToProps(state) {	

	return {
		isAuthNotice: state.auth.authFail
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)