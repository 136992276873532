import React from 'react'
import classes from './Checkbox.module.css'

function isInvalid({valid, touched, shouldValidate}) {
	return !valid && shouldValidate && touched
}

const Checkbox = props => {

	const checkboxType = props.type || 'text'
	const cls = [classes.Checkbox]
	const htmlFor = `${checkboxType}-${Math.random()}`

	if (isInvalid(props)) {
		cls.push(classes.invalid)
	}

	return (
		props.type==='F' || props.type==='R'
		?
		<div className={cls.join(' ')}>

			<div className="spbHeader"><label htmlFor={htmlFor}>{props.label}</label></div>
			<div className="spbBody">
				<div className="spbbCheckBox">
					<div className="goalRowMarks">{props.type==='F' ? <span className="fixedSum">F</span> : null}{props.type==='R' ? <span className="repeatGoal">R</span> : null}</div>
					<div className="checkboxBody">
						<div className="checkboxBodyWrapper">
							<label>

								<input 
									type="checkbox"
									id={htmlFor}									
									disabled={props.disabled}
									onChange={props.onChange}
									placeholder={props.placeholder}
									value={props.value=='1' ? '1' : '0'}
									checked={props.value=='1' ? true : false}
								/>

								<span></span>

								{
								isInvalid(props) 
								? <p>{props.errorMessage || `Введите верное значение`}</p> 
								: null
								}

							</label>												
						</div>
					</div>
				</div>
			</div>			

		</div>
		:
		<div className="checkboxBodyWrapper">
			<label>

				<input 
					type="checkbox"
					id={htmlFor}									
					disabled={props.disabled}
					onChange={props.onChange}
					placeholder={props.placeholder}
					value={props.value=='1' ? '1' : '0'}
					checked={props.value=='1' ? true : false}
				/>
				<span></span>
				{
				isInvalid(props) 
				? <p>{props.errorMessage || `Введите верное значение`}</p> 
				: null
				}

			</label>												
		</div>
	)
}

export default Checkbox