import "../styles/Card.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import CardEditor from "./CardEditor";

import axios from "axios";

import { modal } from "../store/actions/modal";
import { task } from "../store/actions/task";



class Card extends Component {
  state = {
    hover: false,
    editing: false
  };

  processDate = (date) => {
		if (date.includes('-')) {
			const date_array = date.split(' ')[0].split('-')
			return new Date(date_array[0], date_array[1]-1, date_array[2])
		}
		return date
	}

  processDateTime = (date) => {
		if (date.includes('-') && date.includes(':')) {
			const split_array = date.split(' ')
			const date_array = split_array[0].split('-')
			const time_array = split_array[1].split(':')
			return new Date(date_array[0], date_array[1]-1, date_array[2], time_array[0], time_array[1], time_array[2])
		}
		return date
	}

  startHover = () => this.setState({ hover: true });
  endHover = () => this.setState({ hover: false }); 
  
  showModal = async () => {
    //this.setState({ hover: false }); 

    const { card, userId } = this.props;

    //console.log('this.props.card.text: ', this.props.card.text);

    let formData = {}

    const taskData = {
			cardId: card._id,
      userId,      
			isNaked: 1,
			nc_ctpl: 174
		}

		let response = null
		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )    

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
			const data = response.data
			
			//console.log('get task data: ', response.data)

			if (data.error) {
				//console.log('13.1');				
			} else {			
        //console.log('13.2');	
        formData = data
        //cardId = data.taskId;
			}

		} catch (e) {
			
		}


    this.props.modal(card._id, this.props.card.text, formData)

    // dispatch({
    //   type: "SHOW_MODAL",
    //   payload: { cardId: card._id, userId }
    // });

  }  

  startEditing = (event) => {
    event.stopPropagation();
    this.setState({
      hover: false,
      editing: true,
      text: this.props.card.text
    })
  }

  endEditing = () => this.setState({ hover: false, editing: false });

  editCard = async text => {
    const { card, dispatch, userId } = this.props;

    this.endEditing();

    const taskData = {
			cardId: card._id,
      userId,
      cardTitle: text,
			isNaked: 1,
			nc_ctpl: 172
		}

		let response = null
		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )    

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
			const data = response.data
			
			//console.log('update task title data: ', response.data)

			if (data.error) {
				//console.log('12.1');				
			} else {				
        //cardId = data.taskId;
			}

		} catch (e) {
			
		}   

    dispatch({
      type: "CHANGE_CARD_TEXT",
      payload: { cardId: card._id, cardText: text }
    });
  };

  deleteCard = async () => {
    const { listId, card, dispatch, userId } = this.props;

    console.log('this.props: ', this.props)
    console.log('dispatch: ', dispatch)

    if (window.confirm("Удалить карточку? Это действие нельзя будет отменить.")) {

      const taskData = {
        cardId: card._id,
        userId,       
        isNaked: 1,
        nc_ctpl: 183
      }
  
      let response = null
      const params = (Object.entries(taskData)
        .reduce((acc,e,i) => 
          `${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
        '')
        ) 

      try {			
        response = await axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
        const data = response.data
        
        console.log('delete task response data: ', response.data)
  
        if (data.error) {
          console.log('13.1');				
        } else {				
          //cardId = data.taskId;
        }
  
      } catch (e) {
        
      }

      dispatch({
        type: "DELETE_CARD",
        payload: { cardId: card._id, listId }
      });
    }
  };

  copyTask = async (event, taskId) => {
    event.stopPropagation()
    
		let userId = this.props.userId

		let response = null

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/?cardId=${taskId}&isNaked=1&nc_ctpl=210&userId=${userId}`)
			const data = response.data
			
			//console.log('copy task response.data', response.data)

			if (data.error) {
				//console.log('task - 4.1');
				//dispatch(saveFail())
				//console.log('==== copyTask ERROR 1');	
			} else {
				//console.log('task 41.2');
				//dispatch(saveSuccess(data))

				//console.log('copy task response.data', response.data)
				//console.log('==== copyTask SUCCESS, close popup');					
				//console.log('close popup');
				/*
				await this.props.updateTasksData(this.props.userHash, 
					{
					  'amIManager': this.props.amIManager,
					  'amIExecutor': this.props.amIExecutor,
					  'amIInvolved': this.props.amIInvolved,
					  'taskClient': this.props.taskClient
					}
				)
				*/
				//this.closeAddPlanTaskPanel()							
					
			}

		} catch (e) {
			//console.log('task 4.3', e)
			//dispatch(saveFail())
			//console.log('==== copyTask ERROR 2');
		}		

		//console.log('COPY - OLD TASK ID: ', taskId);
	}



  showPanel = (taskData) => {

    //const { dispatch } = this.props;

		//console.log('= TASK, DATA: ', taskData);
    //console.log('edit task - this.props: ', this.props);

    this.props.task(taskData)

    // dispatch({
    //   type: "SHOW_TASK_PANEL",
    //   payload: { task }
    // });

		//this.state.task.showTaskPanel(task)

	}

  render() {

    const { card, index } = this.props;
    const { hover, editing } = this.state;

    //console.log('this.props: ', this.props);

    //  if (card) {
    //    console.log('CARD: ', card);
    //  }

    const taskDate = card && card.taskData && card.taskData.deadLine ? this.processDateTime(card.taskData.deadLine) : new Date()

    //console.log('WHATS IN CARD: ', card);

    const monthArray = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']

    if (!editing) {

      if (!card) return null

      return (
        <Draggable draggableId={card._id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="Card"             
              onClick={() => this.showPanel(card)}
            >
              {hover && (
                <div className="Card-Icons">
                  <div className="Card-Icon" onClick={this.startEditing}>
                    <ion-icon name="create" />
                  </div>
                </div>
              )}

              <div className="cardGoalClient">
                <div className="cardGoalClientLogo">{card.client && card.client.logo && card.client.logo !== 'https://task.apriori-marketing.ru/netcat_files/' ? <img src={card.client.logo} alt="" /> : null}</div>
                <div className="cardGoalClientName">{card.client && card.client.name ? card.client.name : null}</div>
              </div>
              <div className="cardTaskName">{card.taskData && card.taskData.title ? card.taskData.title : 'Нет названия задачи'}</div>
              <div className="cardGoalName">{card.goalData && card.goalData.title ? card.goalData.title : null}</div>
              <div className="cardUsersCardComments">
                <div className="cardUsersCardCommentsUsers">

                  {
                  card.manager && card.manager.photo && card.manager.photo !== 'https://task.apriori-marketing.ru/netcat_files/'
                  ?    
                  <div className="cardUsersCardCommentsUsersUser taskManager"><img src={card.manager.photo} alt="" /></div>
                  :
                  null
                  }

                  {
                  card.executor && card.executor.photo && card.executor.photo !== 'https://task.apriori-marketing.ru/netcat_files/'
                  ?  
                  <div className="cardUsersCardCommentsUsersUser taskPerformer"><img src={card.executor.photo} alt="" /></div>
                  :
                  null
                  }  


                  {
                    card.participants && card.participants.length > 0 
                    ?
                    card.participants.map((participant, index) => 
                      (<div className="cardUsersCardCommentsUsersUser" key={index}><img src={participant.photo} alt="" /></div>)
                    )
                    :
                    null
                  }                  
                </div>
                <div className="commentRightPart">
                  <div className="tmCopyLink"><button data-value="#copyGoal" onClick={(event) => this.copyTask(event, card._id)} title="Скопировать задачу"></button></div>
                  <div className={"cardUsersCardCommentsComments" + (card.comments && card.comments.length && card.comments.length >0 ? "" : " grey")}>{card.comments && card.comments.length ? card.comments.length : 0}</div>
                </div>
              </div>
              <div className="cardDeadLineCardUsedTime">
                <div className="cardDeadLineCardUsedTimeDeadLine">{(taskDate.getDate()<10 ? '0'+taskDate.getDate() : taskDate.getDate())} {monthArray[taskDate.getMonth()]} {(taskDate.getHours()<10 ? '0'+taskDate.getHours() : taskDate.getHours() )}:{(taskDate.getMinutes()<10 ? '0'+taskDate.getMinutes() : taskDate.getMinutes())}</div>
                <div className="cardDeadLineCardUsedTimeUsedTime">
                  <div className="usedTime">{(card.taskData && card.taskData.usedTime ? card.taskData.usedTime : 0)}</div>
                  <div className="usedTimeOf">из</div>
                  <div className="totalTime">{card.taskData && card.taskData.planTime ? card.taskData.planTime : '-'}</div>
                </div>
              </div>
              

              {
              /*  
              <div className="cardName">{card.text}</div>
              <div className="cardData">
                <p>Задача: {card.goalName}</p>
                <p>Клиент: {card.clientName}</p>
                <p>План: {card.planTime} ч.</p>
                <p>Затрачено: {card.usedTime} ч.</p>
                <p>Участники: {card.participants}</p>
              </div>
              */
              }


            </div>
          )}
        </Draggable>
      );
    } else {
      return (
        <CardEditor
          text={card.text}
          onSave={this.editCard}
          onDelete={this.deleteCard}
          onCancel={this.endEditing}
        />
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
	return {
		modal: (cardId, cardText, formData) => dispatch(modal(cardId, cardText, formData)),
    task: (data) => dispatch(task(data)),
    dispatch: dispatch
	}
}

const mapStateToProps = (state, ownProps) => ({
  card: state.cardsById[ownProps.cardId],
  userId: state.auth.userId
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);