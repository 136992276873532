import axios from 'axios'

export function modal(cardId, cardText, formData) {

	console.log('MODAL ACTION');

	return dispatch => {

		const modal = {
			cardId,				
			cardText,
			formData			
		}

		console.log('MODAL ACTION 2, modal: ', modal);

		dispatch(showModal(modal))
	}
}

export function hModal() {

	console.log('HIDE MODAL ACTION');

	return dispatch => {

		const modal = {				
		}

		console.log('HIDEW MODAL ACTION 2, modal: ', modal);

		dispatch(hideModal(modal))
	}
}

export function showModal(data) {
	return {
		type: 'SHOW_MODAL',
		data
	}
}

export function hideModal(data) {
	return {
		type: 'HIDE_MODAL',
		data
	}
}

export function saveModalData(cardId, cardText, formData, fields, userId) {

	// console.log('ACTION saveModalData, cardId: ', cardId);	
	// console.log('ACTION saveModalData, formData: ', formData);	
	// console.log('ACTION saveModalData, fields: ', fields);
	// const modal = {
	// 	cardId,	
	// 	cardText,		
	// 	formData, 
	// 	fields			
	// }

	// return {
	// 	type: 'SAVE_MODAL_DATA',
	// 	modal
	// }


	return async dispatch => {	

		let key_array = {}
		
		Object.keys(fields).map( key => (
			key_array[key] = encodeURIComponent(fields[key].value)
		))

		//console.log('key_array: ', key_array);

		// const authData = {			
		// 	payload: key_array			
		// }

		let response = null
		// const params = (Object.entries(authData)
		// 	.reduce((acc,e,i) => 
		// 		`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
		// 	'')
	    // )

		let param2 = JSON.stringify(key_array)


		console.log('param2', param2)

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/?cardId=${cardId}&payload=${param2}&isNaked=1&nc_ctpl=177&userId=${userId}`)
			const data = response.data
			
			console.log('4', response.data)

			if (data.error) {
				console.log('4.1');
				dispatch(saveFail())
			} else {
				console.log('41.2');
				dispatch(saveSuccess(data))
			}

		} catch (e) {
			console.log('4.3', e)

			dispatch(saveFail())
		}



		const updateData = {
			userId,
			isNaked: 1,
			nc_ctpl: 182
		}

		let updateResponse = null
		const updateParams = (Object.entries(updateData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )

		try {			
			updateResponse = await axios.get(`https://task.apriori-marketing.ru/check-auth/${updateParams}`)
			const updateData = updateResponse.data
			//localStorage.setItem('token', data.token)
			console.log('1', updateResponse.data)

			if (updateData.error) {
				//console.log('1.1');
				//dispatch(authFail())
			} else {
				//console.log('1.2');

				//console.log('store.getState(): ', store.getState())

				//store.getState().board.listsById.length

				//console.log('>>>>>> data.cardsById: ', ...data.cardsById);

				const data2 = {						
					listsById: updateData.listsById		
				}				

				//console.log('>>>>>> data.listsById: ', data2);

				const data3 = {						
					cardsById: updateData.cardsById
				}
				
				dispatch(updateLists(data2))

				dispatch(updateCards(data3))
				
				//dispatch(authSuccess(data))

			}

		} catch (e) {
			//console.log('2', e)

			//dispatch(authFail())
		}		
	}
}

export function updateLists(data) {

	console.log('ACTION, updateLists', data);

	return {
		type: 'UPDATE_LISTS',
		data
	}
}

export function updateCards(data) {

	console.log('ACTION, updateCards', data);

	return {
		type: 'UPDATE_CARDS',
		data
	}
}

export function saveSuccess(data) {
	return {
		type: 'SAVE_SUCCESS',
		data
	}
}

export function saveFail() {
	return {
		type: 'SAVE_FAIL'
	}
}