//import "../styles/TaskTable.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { stopUpdateTaskTable } from "../store/actions/task";
//import task from "../store/reducers/task";
import ClientList from "./ClientList";
import MonthListNew from "./MonthListNew";
import YearListNew from "./YearListNew";
import WorkerListNew from "./WorkerListNew";

class TaskTable extends Component {

	constructor(props) {

		super(props);

		this.state = {
		  error: null,
		  isLoaded: false,
		  taskTableData: {},
		  clientId: 0,
      amIManager: false,
      amIExecutor: true,
      amIInvolved: false,
      statusList: [],
      isNeedUpdateTaskTable: false,
      goalMonthFilter: false,
      currentMonth: false
		};
    
    //this.updateTableRows = this.updateTableRows.bind(this)
	}	
 
  // static getDerivedStateFromProps(props, state) {
	// 	if (props.isNeedUpdateTaskTable) {
	// 		this.updateTableRows()
	// 		return null
	// 	}		
	// 	//console.log('BUDGET STATE, ', state);
	// 	//console.log('BUDGET PROPS, ', props);	  
	// 	return null;
	// } 

  componentDidUpdate() {

    //console.log('TT - componentDidUpdate');
		console.log('Обновление компонента (componentDidUpdate): месяц из пропс -> ' +  this.props.currentMonth + ' месяц из стэйт -> ', this.state.currentMonth);   

		if (this.props.isNeedUpdateTaskTable || (this.state.currentMonth !=  this.props.currentMonth)) {

      if (this.state.currentMonth !=  this.props.currentMonth) {

        console.log('месяц из пропс != месяц из стэйт ИЛИ this.props.isNeedUpdateTaskTable')
        console.log('this.props.isNeedUpdateTaskTable: ', this.props.isNeedUpdateTaskTable)
      }

      console.log('запускаем обновление таблицы задач');
			this.updateTableRows()	

      console.log('останавливаем обновление таблицы задач');
      this.props.stopUpdateTaskTable()	      
      
		} else {

      console.log('месяц из пропс == месяц из стэйт')
    }		

		return null
	}

  updateTableRows = (client = 0) => {

    console.log('ЗАПУЩЕНО ОБНОВЛЕНИЕ - updateTableRows');

    const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 194,
      currentWorkerId: this.props.currentWorkerId ? this.props.currentWorkerId : 0
		}

    if (this.state.amIManager) {
      taskData.amIManager = 1
    }
		
		if (this.state.amIExecutor && this.props.userRole == 2) {
		 	taskData.amIExecutor = 1
		}

    if (this.state.amIInvolved) {
      taskData.amIInvolved = 1
    }

    /*  
    if (this.props.taskTableFilterClient && this.props.taskTableFilterClient.id) {
      taskData.clientId = this.props.taskTableFilterClient.id
    }
    */

    if (this.props.taskClient) {
      taskData.clientId = this.props.taskClient
    }

    if (this.props.currentYear) {
      taskData.currentYear = this.props.currentYear
    }

    if (this.props.currentMonth) {

      taskData.currentMonth = this.props.currentMonth
      console.log('Берем месяц из ПРОПС, ', taskData.currentMonth);
    } else {

      if (typeof this.state.currentMonth !== 'undefined') {
        taskData.currentMonth = this.state.currentMonth
        console.log('Берем месяц из СТЭЙТ, ', taskData.currentMonth);
      }

    }

    console.log('BA- taskData.currentYear: ', taskData.currentYear);
    console.log('BA- taskData.currentMonth: ', taskData.currentMonth);

    //console.log('STATE: ', this.state)

    if (this.state.statusList.length > 0) {
      taskData.statusList = this.state.statusList.join(',')
    }

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)		
		.then(
			(result) => {
				this.setState({
					...this.state,
					isLoaded: true,
					taskTableData: result.data,
          currentMonth: this.props.currentMonth
				}, function() {
          console.log('выполнено обновление таблицы !, this.state.currentMonth: ',  this.state.currentMonth);
          const hcRows = document.querySelectorAll('.hcRow');
          var elemCount = hcRows.length;
          for (var i = 0; i < elemCount; i++) {
            hcRows[i].classList.add('hiddenCell')		
          }

          if (document.querySelector('tfoot')) {
            document.querySelector('tfoot').classList.remove('hiddenCell')
          }  

        });
			},			
			(error) => {
				this.setState({
					...this.state,
					isLoaded: true,
					error
				}, function() {
          const hcRows = document.querySelectorAll('.hcRow');
          var elemCount = hcRows.length;
          for (var i = 0; i < elemCount; i++) {
            hcRows[i].classList.add('hiddenCell')		
          }

          if (document.querySelector('tfoot')) {
            document.querySelector('tfoot').classList.remove('hiddenCell')
          }          
          
        });
			}
		)
  }

  getStatus = () => {
    
    //console.log('TT - getStatus');

    let statusArray = []

    if (this.state.statusList.length === 0) {
      return ''
    } else {     
      statusArray = this.state.statusList.map(oneStatus => {
        if (oneStatus === 5) {
          return 'Работа'
        }
        if (oneStatus === 6) {
          return 'Доработка'
        }
        if (oneStatus === 6) {
          return 'Доработка'
        }
        if (oneStatus === 7) {
          return 'Ожидание'
        }
        if (oneStatus === 8) {
          return 'Проверка'
        }
        if (oneStatus === 9) {
          return 'Завершен'
        }
        return null
      })

      return ': ' + statusArray.join(' | ')
    }

  }

  componentDidMount() {			
    //console.log('TT - componentDidMount');
	}	

  componentWillUnmount() {
    //console.log('TT - componentWillUnmount');
  }  

	enumerateMonthDays = (date = false, obj = false, index = 0) => {

    //console.log('TT - enumerateMonthDays');
		
		const thisDate = date ? date : new Date()
		const daysInMonth = 33 - new Date(thisDate.getFullYear(), thisDate.getMonth(), 33).getDate()

		let daysData = []
		const weekDays = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']

		let today = new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate());

    let statusClass = ''

		for (let i=1; i<=daysInMonth; i++) {
			let currentWeekDay = new Date(thisDate.getFullYear(), thisDate.getMonth(), i).getDay()
      let currentDay = new Date(thisDate.getFullYear(), thisDate.getMonth(), i)

      if (obj) {

        let currentHours = obj.taskUsedTimeArray.filter( oneDay => {       
          const dateArray = oneDay.date.split('-')
          return currentDay.toString() === new Date(dateArray[0], dateArray[1]-1, dateArray[2]).toString()
        })

        let currentStatus = obj.taskChangeStatus.filter( oneDay => {       
          const dateArray = oneDay.date.split('-')
          return currentDay.toString() === new Date(dateArray[0], dateArray[1]-1, dateArray[2]).toString()
        })        

        if (currentStatus.length > 0) {

          switch (+currentStatus[0].statusId) {
            case 5:
              statusClass = ' greenBorderBottom'
              break;
            case 6:
              statusClass = ' pinkBorderBottom'
              break;
            case 7:
              statusClass = ' blueBorderBottom'
              break;
            case 8:
              statusClass = ' yellowBorderBottom'
              break; 
            case 9:
              statusClass = ' greyBorderBottom'
              break; 
            default:
              //statusClass = ''   
          }
          
        }
 
        daysData.push(<td key={'td_' + index + '_' + (i+7)} className={'greenBorder' + (today.toString() === currentDay.toString() ? ' greenBg' : '') + (( (currentWeekDay===6 || currentWeekDay===0) && today.toString() !== currentDay.toString()) ? ' yellowBg' : '') + (statusClass && today >= currentDay ? statusClass : '') }>
          <input type="text" value={currentHours.length > 0 ? currentHours[0].hours : ''} onBlur={(event) => this.timeInputSave(event, obj, index, currentDay)} onChange={(event) => this.timeInputChange(event, obj, index, currentDay)} disabled={today < currentDay} />
        </td>)

      } else {

        daysData.push(<th key={i+7} className={(today.toString() === currentDay.toString() ? 'todayCell' : '')}>
        <div className={'thCell' + (currentWeekDay===6 || currentWeekDay===0 ? ' redCell' : '')}> 
          <div className="thNumber">{i}</div>
          <div className="thWeekDay">{weekDays[currentWeekDay]}</div>
        </div>   
        </th>)

      }
		}

		return daysData
	}

  activateSwitch = (switchPosition) => {

    console.log('ФУНКЦИЯ activateSwitch запущена');

    //console.log('TT - activateSwitch');

    // let elem = document.querySelector('.tmFooterHeaderSwitchBlock.active')
    // if (elem) {
    //   elem.classList.remove('active')
    // }  

    switch (switchPosition) {
      case 1:
        //document.querySelector('.switchFirst').classList.add('active')
        document.querySelector('.tmFooterBody').classList.add('hiddenBlock')
        break;
      case 2:
       // document.querySelector('.switchSecond').classList.add('active')
        document.querySelector('.tmFooterBody').classList.remove('hiddenBlock')

        //if (this.state.amIExecutor === false) {
          this.setState({
            ...this.state,
            amIExecutor: true,
            amIInvolved: false
          },
          function(){
            //console.log('I am executor: ', this.state);
            
            this.updateTableRows()
          })

       // }

        break;
      case 3:
       // document.querySelector('.switchThird').classList.add('active')
        document.querySelector('.tmFooterBody').classList.remove('hiddenBlock')

        if (this.state.amIInvolved === false) {

          this.setState({
            ...this.state,
            amIExecutor: false,
            amIInvolved: true
          },
          function(){
            //console.log('I am involved: ', this.state);
            
            this.updateTableRows()
          })

        }
        break;
      default:

    }
  }

  toggleDropDown = event => {

   // console.log('TT - toggleDropDown');
    //event.stopPropagation()
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

  timeInputSave = (event, obj, index, currentDay) => {   
    
    //console.log('TT - timeInputSave');

    const dateToAdd = currentDay.getFullYear()+'-'+((currentDay.getMonth()+1) < 10 ? '0'+(currentDay.getMonth()+1) : (currentDay.getMonth()+1))+'-'+(currentDay.getDate()<10 ? '0'+currentDay.getDate() : currentDay.getDate())
    this.setTime(obj.taskId, dateToAdd, event.target.value)
  }

  timeInputChange = (event, obj, index, currentDay) => {

    //console.log('TT - timeInputChange');
    
    // console.log('this.state.taskTableData.taskUsedTimeArray: ', this.state.taskTableData.tasks[index].taskUsedTimeArray);
    // console.log('obj: ', obj);
    // console.log('index: ', index);
    // console.log('currentDay: ', currentDay);
    // console.log('event.target.value: ', event.target.value);

    let currentHours = obj.taskUsedTimeArray.filter( oneDay => {       
      const dateArray = oneDay.date.split('-')
      return currentDay.toString() === new Date(dateArray[0], dateArray[1]-1, dateArray[2]).toString()
    })

    //console.log('currentHours: ', currentHours);
    const taskUsedTimeArray = this.state.taskTableData.tasks[index].taskUsedTimeArray
    //console.log('taskUsedTimeArray: ', taskUsedTimeArray);

    const dateToAdd = currentDay.getFullYear()+'-'+((currentDay.getMonth()+1) < 10 ? '0'+(currentDay.getMonth()+1) : (currentDay.getMonth()+1))+'-'+(currentDay.getDate()<10 ? '0'+currentDay.getDate() : currentDay.getDate())

    if (currentHours.length === 0) {
      taskUsedTimeArray.push({date: dateToAdd, hours: event.target.value})      
    } else {
      let currentArray = taskUsedTimeArray.filter( oneDay => {       
        const dateArray = oneDay.date.split('-')
        return currentDay.toString() === new Date(dateArray[0], dateArray[1]-1, dateArray[2]).toString()
      })[0]

      currentArray['hours'] = event.target.value
    }

    //this.setTime(obj.taskId, dateToAdd, event.target.value)

      this.setState({
        ...this.state,
        taskUsedTimeArray
      },
      function(){
       // console.log('NOW STATE: ', this.state);
      })
    // this.setState({
    //   ...this.state,
    // })
  }

  toggleRows = () => {

    //console.log('TT - toggleRows');

    const hcRows = document.querySelectorAll('.hcRow');
    var elemCount = hcRows.length;

    for (var i = 0; i < elemCount; i++) {
      hcRows[i].classList.toggle('hiddenCell')		
    }

    document.querySelector('tfoot').classList.toggle('hiddenCell');
    //.classList.toggle('hiddenCell')		
    //console.log('hcRows: ', hcRows)
    //console.log('hcRows.classList: ', hcRows.classList)
  }

  declOfNum = (number, titles) => {  

    //console.log('TT - declOfNum');

		const cases = [2, 0, 1, 1, 1, 2];  
		return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
	}

  setTime = (taskId, date, hours) => {

    //console.log('TT - setTime');

    const { userId } = this.props;		

		const taskData = {	
			userId,
      taskId, 
      date, 
      hours,      
			isNaked: 1,
			nc_ctpl: 195
		}
		
		// if (this.state.clientId !== 0) {
		// 	taskData.clientId = this.state.clientId
		// }

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/set-time/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					...this.state,
					isLoaded: true
				});

				//this.props.stopUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					...this.state,
					isLoaded: true,
					error
				});

				//this.props.stopUpdateBudget()
			}
		)
  }

  setUserRole = (event, roleId) => {

    console.log('Функция setUserRole запущена');

    switch (roleId) {
      case 1:
        this.toggleDropDown(event)
        this.setState({
          ...this.state,
          amIManager: true,
          amIExecutor: false,
          amIInvolved: false
        },
        function(){          
          this.updateTableRows()
        });
        break;
      case 2:
        this.toggleDropDown(event)
        this.setState({
          ...this.state,
          amIManager: false,
          amIExecutor: true,
          amIInvolved: false
        },
        function(){          
          this.updateTableRows()
        });
        break;
      case 3:
        this.toggleDropDown(event)
        this.setState({
          ...this.state,
          amIManager: false,
          amIExecutor: false,
          amIInvolved: true
        },
        function(){          
          this.updateTableRows()
        });
        break;
      default:
        this.toggleDropDown(event)
    }    
  }


  setStatus = (event, statusID) => {

    console.log('Функция setStatus запущена');

    event.stopPropagation()

    //this.toggleDropDown(event);

    let currentStatusList = this.state.statusList;

    //console.log('1 currentStatusList: ', currentStatusList);

    if (currentStatusList.includes(statusID)) {
      currentStatusList = currentStatusList.filter(oneStatus => (oneStatus !== statusID))
    } else {
      currentStatusList.push(statusID)
    }

    //console.log('2 currentStatusList: ', currentStatusList);

    //this.toggleDropDown(event)

    this.setState({
      ...this.state,
      statusList: currentStatusList
    },
    function(){  
      
      //console.log('3 currentStatusList: ', this.state.statusList);
      this.updateTableRows()

      //console.log('4 currentStatusList: ', this.state.statusList);

    });

  }

  getUsedTimeCommon = (row, isJustThisMonth = false) => {

    //console.log('TT - getUsedTimeCommon');

    //console.log('+ row: ', row)
    let currentHours = row.taskUsedTimeArray.reduce(
      (previousValue, currentValue) => previousValue + 
      (parseFloat(currentValue.hours.replace(',', '.')) ? parseFloat(currentValue.hours.replace(',', '.')) : 0), 
      (
      isJustThisMonth  
      ?
      0
      :
      (row.taskUsedTimeBeforeThisMonth ? parseFloat(row.taskUsedTimeBeforeThisMonth.replace(',', '.')) : 0)
      )
    );

    //console.log('currentHours: ', currentHours)

    return currentHours
  }

  setTableActive = () => {

    console.log('ФУНКЦИЯ setTableActive запущена');

    let element = document.getElementById('tmFH')

  	element.classList.add('active')

    this.activateSwitch(2)
  }

  setTableInactive = (event) => {

    //console.log('TT - setTableInactive');

    event.stopPropagation()

    //console.log('setTableInactive !');

    let element = document.getElementById('tmFH')

  	element.classList.remove('active')

    document.querySelector('.tmFooterBody').classList.add('hiddenBlock')
  }

  getClientGoals = (client, event) => {	
    
    //console.log('TT - getClientGoals');

		console.log('Обновляем таблицу задач при обновлении клиента в фильтре: ', client)

		//const { dispatch } = this.props;

   // this.updateTableRows()	
   //   this.props.stopUpdateTaskTable()

   //console.log('0 this.state.clientId: ', this.state);
   //console.log('client: ', client);

   //let curState = {...this.state}
   //console.log('curState: ', curState);

    // this.setState((state, props) => {
    //   return {
    //     ...this.state,
    //     newClientX: client,
    //     clientId: client.id,
    //     newClient: client
    //   }
    // },
    // () => {
    //   console.log('AFTER SET STATE: ', this.state);
    // })

    // this.setState((state, props) => {
    //   return {
        
    //     newClientX: {id:28},
    //     clientId: client.id,
    //     newClient: client
    //   }
    // },
    // () => {
    //   console.log('AFTER SET STATE 2: ', this.state);
    // })

    //event.target.closest('.cpFilter').querySelector('.cpfHiddenField').value = id
		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))		

		this.toggleDropDown(event)

		event.preventDefault()
    // ,
    // () => {
    //   console.log('Обновляем таблицу задач 1: ', this.state);
    //   this.updateTableRows(client.id)	
    //   console.log('Обновляем таблицу задач 2^ ', this.state);
    //   this.props.stopUpdateTaskTable()
    // }   
    //incrementCounter = () => this.setState((prevState) => ({ counter: prevState.counter + 1 }));

    // const clonedState = cloneDeep(this.state);
    // clonedState.newClient = client;
    // this.setState(clonedState); 

		this.setState({
			...this.state, 
			newClient: client
		},
    () => {
      //console.log('>>>>>>>>> AFTER SET STATE: ', this.state.newClient.id);
    })    

		// dispatch({
		// 	type: "NEED_UPDATE_CURRENT_CLIENT",
		// 	payload: {
		// 		isNeedUpdateCurrentClient: client
		// 	}
		// });

	}

  getClient = () => {  

    //console.log('TT - getClient');

    if (!this.props.taskTableFilterClient) {
      return ''
    } else {     
      return ' - '+this.props.taskTableFilterClient.name
    }

  }

  getFilterMonth = () => {   
    
    //console.log('TT - getFilterMonth');
    //console.log('this.state.goalMonthFilter: ', this.state.goalMonthFilter);

    if (!this.state.goalMonthFilter) {
      return ''
    } else {   
      
      let months_array = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

      let current_date = this.state.goalMonthFilter
      let local_month = months_array[current_date.getMonth()]
      let local_year = current_date.getFullYear().toString().substr(-2)      

      return ' - '+local_month+'‘'+local_year
    }

  }

  filterMonth = (month, event) => {    

    //console.log('TT - filterMonth');
    //console.log('month: ', month);
   // console.log('this.state.goalMonthFilter: ', this.state.goalMonthFilter);

    if (this.state.goalMonthFilter.toString() === month.toString()) {
      this.setState({
        ...this.state,
        goalMonthFilter: false         
      })
    } else {    

      //console.log('======== 1 SET NEW FILTER MONTH', month);

      this.setState({
        ...this.state,
        goalMonthFilter: month            
      },
      () => {
        //console.log('========= 2 SET NEW FILTER MONTH STATE', this.state);
      })
    }

    if (event) {
      this.toggleDropDown(event)
      event.stopPropagation()
    }
  }

  

	render() {

    console.log('текущий месяц ПРОПС, рендер:', this.props.currentMonth);
    //console.log('RENDER ==== this.state.goalMonthFilter: ', this.state.goalMonthFilter);

    let filterMonths = []
    let current_month = new Date().getMonth()
    let current_year = new Date().getFullYear()

    let months_array = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    for (let i=-1; i<6; i++) {
      let current_date = new Date(current_year, current_month-i, 1)
      let local_month = months_array[current_date.getMonth()]
      let local_year = current_date.getFullYear().toString().substr(-2)      
      filterMonths.push({name: local_month+'‘'+local_year, value: new Date(current_date.getFullYear(), current_date.getMonth(), 1)})
    }

    if (!this.state.goalMonthFilter) this.filterMonth(filterMonths[1].value)

		//console.log('enumerateMonthDays: ', this.enumerateMonthDays())

    //console.log('---- TaskTable THIS PROPS: ', this.props)
    //console.log('123 TaskTable PROPS: ', this.props);
    //console.log('123 TaskTable STATE: ', this.state);

		const { error} = this.state;

    const statusColors = ['', '', '', '', '', 'green', 'pink', 'blue', 'yellow', 'grey']

		if (error) {
			//return <div className="tmbbRow" key="4">Ошибка загрузки данных по бюджету. (1)</div>;
			return null
		} else {
			return (
          <div className="tmFooter">
            <div className="tmFooterHeader" id="tmFH" onClick={() => this.setTableActive()}>
              <div className="tmFooterHeaderTitle">Табель </div>


              {/*
              <div className="tmFooterHeaderSwitch">
                <div className="tmFooterHeaderSwitchBlock switchFirst active" onClick={() => this.activateSwitch(1)}></div>
                <div className="tmFooterHeaderSwitchBlock switchSecond" onClick={() => this.activateSwitch(2)}></div>
                <div className="tmFooterHeaderSwitchBlock switchThird" onClick={() => this.activateSwitch(3)}></div>
              </div>
              */}

              {/*
                <div className="cpFilter">
                  <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Роль{this.state.amIManager ? ': Я постановщик' : ''}{this.state.amIExecutor ? ': Я исполнитель' : ''}{this.state.amIInvolved ? ': Я участник' : ''}</div>
                  <div className="cpfMenuShell">
                    <div className="cpfMenu">                     
                      <div className="cpfmBody">                        
                        <div className="cpfmBodyBlock">                        
                          <div className="cpfmBodyBlockRow nopadding">
                            <ul className="flexClass">                            
                              <li key="0">						
                                <a href="#roleId_1" className={'roleRow listPlus' + (this.state.amIManager ? ' checkedClass' : '')} onClick={(event) => this.setUserRole(event, 1)}>
                                  <span className="clientLogo"><img src="/img/role_crown.svg" alt="" /></span>
                                  <span className="clientName">Я постановщик</span>
                                </a>						
                              </li>
                              <li key="1">						
                                <a href="#roleId_2" className={'roleRow listPlus' + (this.state.amIExecutor ? ' checkedClass' : '')} onClick={(event) => this.setUserRole(event, 2)}>
                                  <span className="clientLogo"><img src="/img/role_star.svg" alt="" /></span>
                                  <span className="clientName">Я исполнитель</span>
                                </a>						
                              </li>
                              <li key="2">						
                                <a href="#roleId_3" className={'roleRow listPlus' + (this.state.amIInvolved ? ' checkedClass' : '')} onClick={(event) => this.setUserRole(event, 3)}>
                                  <span className="clientLogo"><img src="/img/role_involved.svg" alt="" /></span>
                                  <span className="clientName">Я участник</span>
                                </a>						
                              </li>                           
                            </ul>                            
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>             
                </div>
              */}

                <div className="cpFilter cpf_type_1">
                  <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Статус{this.getStatus()}</div>
                  <div className="cpfMenuShell">
                    <div className="cpfMenu">                     
                      <div className="cpfmBody">                        
                        <div className="cpfmBodyBlock">                        
                          <div className="cpfmBodyBlockRow nopadding">
                            <ul className="flexClass">                            
                              <li key="0" style={(this.state.statusList.includes(6) ? {'order':'1'} : {'order':'2'})} className={this.state.statusList.includes(6) ? 'topClass' : ''}>						
                                <a href="#statusId_1" className={'roleRow listPlus' + (this.state.statusList.includes(6) ? ' minus checkedClass' : '')} onClick={(event) => this.setStatus(event, 6)}>                                  
                                  <span className="statusName pink">Доработка</span>
                                </a>						
                              </li>
                              <li key="1" style={(this.state.statusList.includes(5) ? {'order':'1'} : {'order':'2'})} className={this.state.statusList.includes(5) ? 'topClass' : ''}>						
                                <a href="#statusId_2" className={'roleRow listPlus' + (this.state.statusList.includes(5) ? ' minus checkedClass' : '')} onClick={(event) => this.setStatus(event, 5)}>                                  
                                  <span className="statusName green">Работа</span>
                                </a>						
                              </li>
                              <li key="2" style={(this.state.statusList.includes(8) ? {'order':'1'} : {'order':'2'})} className={this.state.statusList.includes(8) ? 'topClass' : ''}>						
                                <a href="#statusId_3" className={'roleRow listPlus' + (this.state.statusList.includes(8) ? ' minus checkedClass' : '')} onClick={(event) => this.setStatus(event, 8)}>                                  
                                  <span className="statusName yellow">Проверка</span>
                                </a>						
                              </li>
                              <li key="3" style={(this.state.statusList.includes(7) ? {'order':'1'} : {'order':'2'})} className={this.state.statusList.includes(7) ? 'topClass' : ''}>						
                                <a href="#statusId_4" className={'roleRow listPlus' + (this.state.statusList.includes(7) ? ' minus checkedClass' : '')} onClick={(event) => this.setStatus(event, 7)}>                                  
                                  <span className="statusName blue">Ожидание</span>
                                </a>						
                              </li>
                              <li key="4" style={(this.state.statusList.includes(9) ? {'order':'1'} : {'order':'2'})} className={this.state.statusList.includes(9) ? 'topClass' : ''}>						
                                <a href="#statusId_5" className={'roleRow listPlus' + (this.state.statusList.includes(9) ? ' minus checkedClass' : '')} onClick={(event) => this.setStatus(event, 9)}>                                  
                                  <span className="statusName grey">Завершен</span>
                                </a>						
                              </li>                          
                            </ul>                            
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>             
                </div>

                {/*}
                <div className="cpFilter taskTableClients">
                  <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Заказчик{this.getClient()}</div>
                  <div className="cpfMenuShell">
                    <div className="cpfMenu">                     
                      <div className="cpfmBody">                        
                        <div className="cpfmBodyBlock">                        
                          <div className="cpfmBodyBlockRow nopadding">
                            <ClientList 
                              getClientGoals={this.getClientGoals}
                              clientId={this.state.newClient ? this.state.newClient.id : 0}
                              id="4"
                            />                            
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>             
                </div>
                */}


                <div className="cpFilter">

                  <div className="cpfSelected" onClick={(event) => this.props.toggleDropDown(event)}>Клиент{(this.props.taskClient > 0 ? ': '+this.props.clients.filter(oneClient => (oneClient.id===this.props.taskClient))[0].name : '')}</div>

                  <div className="cpfMenuShell">
                    <div className="cpfMenu">					  
                      <div className="cpfmHeader canHover" onClick={this.props.switchClientsOff}>Все клиенты</div>
                      <div className="cpfmBody">                     
                        
                        <div className="cpfmBodyBlock">                        
                          <div className="cpfmBodyBlockRow nopadding">
                            <ul className="flexClass">
                            {
                            this.props.clients.map((client, index) => (
                              <li key={index}>						
                                <a href={'#clientId_'+index} className={client.id===this.props.taskClient ? 'checkedClass' : ''} onClick={() => this.props.setClient(client.id)}>
                                  <span className="clientLogo"><img src={client.logo} alt="" /></span>
                                  <span className="clientName">{client.name}</span>
                                </a>						
                              </li>
                            ))
                            }
                            </ul>                            
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>             
                </div>






                {/*}
                <div className="cpFilter taskTableClients">
                  <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Месяц{this.getFilterMonth()}</div>
                  <div className="cpfMenuShell">
                    <div className="cpfMenu">                     
                      <div className="cpfmBody">                        
                        <div className="cpfmBodyBlock">                        
                          <div className="cpfmBodyBlockRow nopadding">
                              <div className="filterMonthsBox column">
                              {
                              filterMonths.map((month, index) => (
                                <div className={"filterMonth" + (this.state.goalMonthFilter.toString() === month.value.toString() ? ' active' : '')} key={index} onClick={(event) => this.filterMonth(month.value, event)}>{month.name}</div>
                              ))
                              }
                              </div>                           
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>             
                </div>  
                */}                  

                <YearListNew />
                <MonthListNew />                

                <WorkerListNew 
                  setCurrentWorkerId={this.props.setCurrentWorkerId}
                />  

                <button className="tableClose" onClick={(event) => this.setTableInactive(event)}></button>

            </div>
            <div className="tmFooterBody hiddenBlock">
              <div className="tmFooterBodyTable">
                <table>
                  {
                  this.state.taskTableData.tasks
                  ?
                  <thead>
                    <tr>
                      <th key="th1">&nbsp;</th>
                      <th key="th2">&nbsp;</th>
                      <th key="th3">&nbsp;</th>
                      <th key="th4" className="w65">План<br />общ./мес.</th>
                      <th key="th5" className="w65">Факт<br />общ./мес.</th>
                      <th key="th6" className="w107">Статус</th>
                      <th key="th7">&nbsp;</th>
                      {this.enumerateMonthDays()}					   
                    </tr>  
                  </thead>
                  :
                  null
                  }                  

                  <tbody>
                    {
                    this.state.taskTableData.tasks
                    ?
                    this.state.taskTableData.tasks.map( (tableRow, index) => (
                      <tr key={'tr_' + index} className={index > 1 ? ''  : ''}>
                        <td key={'td_' + index + '_1'} className="cellImage">{tableRow.taskClientImg ? <img src={tableRow.taskClientImg} alt="" /> : null}</td>
                        <td key={'td_' + index + '_2'} className="cellTask"><div>{tableRow.taskName}</div></td>
                        <td key={'td_' + index + '_3'} className="cellGoal"><div>{tableRow.taskGoalName}</div></td>
                        <td key={'td_' + index + '_4'}>
                          <div className="cellNumbers">
                            <div className="cnCommon">{tableRow.taskPlanTimeCommon ? tableRow.taskPlanTimeCommon : 0}</div>
                            <div className="cnMonth">-</div>
                          </div>  
                        </td>
                        <td key={'td_' + index + '_5'}>
                          <div className="cellNumbers">
                            <div className={'cnCommon' + (this.getUsedTimeCommon(tableRow) > parseFloat(tableRow.taskPlanTimeCommon) ? ' redCnCommon' : '')}>{this.getUsedTimeCommon(tableRow)}</div>
                            <div className={'cnMonth'  + (this.getUsedTimeCommon(tableRow) > parseFloat(tableRow.taskPlanTimeCommon) ? ' redCnCommon' : '')}>{this.getUsedTimeCommon(tableRow, true)}</div>
                          </div>  
                        </td>
                        <td key={'td_' + index + '_6'}>
                          <div className={'cellStatus ' + statusColors[+tableRow.taskStatusId]}>{tableRow.taskStatusName}</div>                        
                        </td>
                        <td key={'td_' + index + '_7'} className="redBell">
                          {+tableRow.taskCommentsCounter > 0 ? <div className="cellComments"></div> : null}
                        </td>
                        {this.enumerateMonthDays(false, tableRow, index)}		
                      </tr>
                    ))
                    :
                    null
                    }
                    
                  </tbody>

                  {
                  this.state.taskTableData.tasks && (this.state.taskTableData.tasks.length-2 > 0)
                  ?
                  null
                  :
                  null
                  }  

                </table>

              </div>
            </div>
          </div>	
			);
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
  userRole: state.auth.userRole,
  isNeedUpdateTaskTable: state.task.isNeedUpdateTaskTable,
  taskTableFilterClient: state.task.taskTableFilterClient,
  currentWorkerId: state.app.currentWorkerId, 
  
});  

function mapDispatchToProps(dispatch) {

  //console.log('TT - mapDispatchToProps');

	return {
		stopUpdateTaskTable: () => dispatch(stopUpdateTaskTable()),
    dispatch: dispatch		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);