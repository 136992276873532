import "../styles/AddGoalPanelWrapper.css";

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ClientList from "./ClientList";
import ClientGoalsList from "./ClientGoalsList";
import UserList from "./UserList";

import Input from "./UI/Input/Input";
import Textarea from "./UI/Textarea/Textarea";
import Checkbox from "./UI/Checkbox/Checkbox";
/*import Select from "../UI/Select/Select";
import Textarea from "../UI/Textarea/Textarea";
import Button from "../UI/Button/Button";*/

import { saveGoalData } from "../store/actions/goal";
import { hideTaskPanel, saveTaskData, updateTasksData } from "../store/actions/task";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)

class AddTaskPanelWrapper extends Component {

	constructor(props) {
		super(props);

		/*this.state = {
		  error: null,
		  isLoaded: false,
		  goal: []
		};*/

		//console.log('CONSTRUCTOR');

		this.state = {
			isFormValid: false,
			formControls: {
				f_taskTitle: {
					value: '',
					type: 'text',
					label: 'Подзадача',
					errorMessage: 'Укажите название подзадачи',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},

				f_clientId: {
					value: '',
					type: 'hidden',
					label: 'Клиент',
					errorMessage: 'Укажите клиента',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},

				f_clientGoalId: {
					value: '',
					value_name: '',
					client: '',
					type: '',
					label: 'Задача (из плана клиента)',					
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},

				f_taskManagerId: {
					value: this.props.userId,
					type: '',
					label: 'Постановщик:',
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},

				f_taskExecutorId: {
					value: '',
					type: '',
					label: 'Исполнитель:',
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},

				f_taskParticipantsIds: {
					value: [],
					type: '',
					label: 'Другие участники:',
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},

				f_standardTime: {
					value: props.goalData && props.goalData.timePlan ? props.goalData.timePlan : '',
					type: 'text',
					label: 'Норма',
					errorMessage: 'Укажите количество часов',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},	

				f_planTime: {
					value: props.goalData && props.goalData.timePlan ? props.goalData.timePlan : '',
					type: 'text',
					label: 'План.',
					errorMessage: 'Укажите количество часов',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},			

				f_usedTime: {
					value: props.goalData && props.goalData.timePlan ? props.goalData.timePlan : '',
					type: 'text',
					label: 'Afrn',
					errorMessage: 'Укажите количество часов',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},	
							
				f_descText: {
					value: '',
					type: 'text',
					label: 'Описание',
					errorMessage: 'Укажите описание к подзадаче',
					placeholder: 'Укажите описание к подзадаче',
					valid: true,
					touched: false,				
					validation: {
						required: false					
					}
				},	
				
				f_taskResult: {
					value: '',
					type: 'text',
					label: 'Ссылка на результат',
					errorMessage: 'Укажите ссылку на результат (https://...)',
					placeholder: 'Ссылка на результат (https://...)',
					valid: true,
					touched: false,				
					validation: {
						required: false					
					}
				},	
				
				f_comment: {
					value: props.goalData && props.goalData.comment ? props.goalData.comment : '',
					type: 'text',
					label: 'Комментарий',
					errorMessage: 'Укажите комментарий',
					valid: true,
					touched: false,
					validation: {
						required: false					
					}
				},	

				f_deadLine: {
					value: new Date(),
					type: 'text',
					label: 'Дедлайн',
					placeholder: 'гггг-мм-дд чч:мм:сс',
					errorMessage: 'Укажите срок для задачи',
					valid: true,
					touched: false,
					validation: {
						required: false					
					}
				}

			},
			isEditGoal: false,
			oldEditGoalId: 0,
			processDate: this.processDate,			
			processDateTime: this.processDateTime,
			oldClientId: 0,
			clientGoals: [],
			onChangeHandlerList: this.onChangeHandlerList,
			updateGoalList: true,
			isShown: false,
			updateGoalsList: this.updateGoalsList,
			setJustUpdatedFalse: this.setJustUpdatedFalse			
		}

		//this.onChangeHandler = this.onChangeHandler.bind(this)	
	}

	processDate = (date) => {
		if (date.includes('-')) {
			const date_array = date.split('-')
			return new Date(date_array[0], date_array[1]-1, date_array[2])
		}
		return date
	}
	
	processDateTime = (date) => {
		if (date.includes('-') && date.includes(':')) {
			const split_array = date.split(' ')
			const date_array = split_array[0].split('-')
			const time_array = split_array[1].split(':')
			return new Date(date_array[0], date_array[1]-1, date_array[2], time_array[0], time_array[1], time_array[2])
		}
		return date
	}

	updateGoalsList = (id, name) => {
		//console.log(' --- updateGoalsList in action ---');
		this.onChangeHandlerList(id, name)
	}

	setJustUpdatedFalse = () => {
		this.setState({
			...this.state,
			justUpdated: false
		})
	}

	static getDerivedStateFromProps(props, state) {

		//console.log('1111111111 TASK getDerivedStateFromProps state: ', state);
		//console.log('1111111111 TASK getDerivedStateFromProps props: ', props);

		if (!props.taskData) return null;

		//console.log('+++ 1');

		if (!state.isEditGoal || (state.oldEditGoalId != props.taskData._id)) {

			//console.log('+++ 2');

			if ((props.taskData && props.taskData._id) || (!props.taskData._id && state.oldEditGoalId) || state.justUpdated){

				//const formControls = { ...state.formControls }

				if (state.justUpdated) {
					state.setJustUpdatedFalse()
				}

				//console.log('22222222 TASK getDerivedStateFromProps - Значения ИЗ PROPS !!!!');

				if (props.taskData && props.taskData.client && props.taskData.client.client_id) {
					//console.log('777: ', props.taskData.client.client_id);

					if (state.oldClientId != props.taskData.client.client_id) {
						state.updateGoalsList(props.taskData.client.client_id, 'f_clientId')
					}

					//(state.formControls['f_taskParticipantsIds'].value ? state.formControls['f_taskParticipantsIds'].value : (

					// state.setState({
					// 	updateGoalList: true
				    // })

					// if (state.updateGoalList) {
					// 	console.log('TRIGGER updateGoalList: ', props.taskData.client.client_id);
					// 	state.onChangeHandlerList(props.taskData.client.client_id, 'f_clientId')						
					// }

				} else {
					//console.log('666: ', props);
				
				}

				//Change in props
				return {
					oldEditGoalId: props.taskData._id,
					isEditGoal: true,
					formControls: {
						f_taskTitle: {
							value: props.taskData.taskData && props.taskData.taskData.title ? props.taskData.taskData.title : '',
							type: 'text',
							label: 'Подзадача',
							errorMessage: 'Укажите название подзадачи',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},	

						f_clientId: {
							value: props.taskData && props.taskData.client && props.taskData.client.client_id ? props.taskData.client.client_id : '',
							type: 'hidden',
							label: 'Клиент',
							errorMessage: 'Укажите клиента',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},

						f_clientGoalId: {
							value: props.taskData && props.taskData.goalData && props.taskData.goalData.goal_id ? props.taskData.goalData.goal_id : '',
							value_name: props.taskData && props.taskData.goalData && props.taskData.goalData.tittle ? props.taskData.goalData.title : '',
							client:  props.taskData && props.taskData.client && props.taskData.client.client_id ? props.taskData.client.client_id : '',
							type: '',
							label: 'Задача (из плана клиента)',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},

						f_taskManagerId: {
							value: props.taskData && props.taskData.manager && props.taskData.manager.manager_id ? props.taskData.manager.manager_id : props.userId,
							type: '',
							label: 'Постановщик:',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},

						f_taskExecutorId: {
							value: props.taskData && props.taskData.executor && props.taskData.executor.executor_id ? props.taskData.executor.executor_id : '',
							type: '',
							label: 'Исполнитель:',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},

						f_taskParticipantsIds: {
							value: props.taskData && props.taskData.participants ? props.taskData.participants : [],
							type: '',
							label: 'Другие участники:',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},
						
						f_standardTime: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.standardTime ? props.taskData.taskData.standardTime : 0,
							type: 'text',
							label: 'Норма',
							errorMessage: 'Укажите количество часов',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},		

						f_planTime: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.planTime ? props.taskData.taskData.planTime : 0,
							type: 'text',
							label: 'План',
							errorMessage: 'Укажите количество часов',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},	

						f_usedTime: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.usedTime ? props.taskData.taskData.usedTime : '-',
							type: 'text',
							label: 'Факт',
							errorMessage: 'Укажите количество часов',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},	
									
						f_descText: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.comment ? props.taskData.taskData.comment : '',
							type: 'text',
							label: 'Описание',
							errorMessage: 'Укажите описание к подзадаче',
							placeholder: 'Укажите описание к подзадаче',
							valid: true,
							touched: false,				
							validation: {
								required: false					
							}
						},
						
						f_taskResult: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.taskResult ? props.taskData.taskData.taskResult : '',
							type: 'text',
							label: 'Ссылка на результат',
							errorMessage: 'Укажите ссылку на результат (https://...)',
							placeholder: 'Ссылка на результат (https://...)',
							valid: true,
							touched: false,				
							validation: {
								required: false					
							}
						},
						f_comment: {
							value: '',
							type: 'text',
							label: 'Комментарий',
							errorMessage: 'Укажите комментарий',
							valid: true,
							touched: false,
							validation: {
								required: false					
							}
						},						

						f_deadLine: {
							value: props.taskData && props.taskData.taskData && props.taskData.taskData.deadLine ? state.processDateTime(props.taskData.taskData.deadLine) : new Date(),
							//value: props.taskData && props.taskData.taskData && props.taskData.taskData.deadLine ? props.taskData.taskData.deadLine : new Date(),
							type: 'text',
							label: 'Дедлайн',
							placeholder: 'гггг-мм-дд чч:мм:сс',
							errorMessage: 'Укажите срок для задачи',
							valid: true,
							touched: false,
							validation: {
								required: false					
							}
						}
						
					}
				}

			} else {
				//console.log('333333333 TASK getDerivedStateFromProps - не меняем STATE');
				return null
			}

		} else {
			//console.log('+++ 3');
			//console.log('44444 getDerivedStateFromProps - Не меняем значения STATE');		
		}

        return null; // No change to state
    }
	
	submitHandler = event => {
		event.preventDefault()
	}

	onChangeHandlerList = (val, controlName, client = null) => {

		//console.log('*** onChangeHandlerList FIRED value: ', client);
		//console.log('*** onChangeHandlerList FIRED controlName: ', controlName);	

		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }

		//console.log('*** onChangeHandlerList FIRED control: ', control);	

		if (controlName === 'f_clientId') {
			
			//console.log('> f_clientId - val: ', val);
			//console.log('> f_clientId - controlName: ', controlName);

			const { userId } = this.props;

  			const userListData = {	
				userId, 
				clientId: val,     
				isNaked: 1,
				nc_ctpl: 193
			}		

			const params = (Object.entries(userListData)
				.reduce((acc,e,i) => 
					`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
				'')
			)   
						
			axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
			//.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						...this.state,
						isLoaded: true,
						updateGoalList: true,
						clientGoals: result.data.error ? this.state.clientGoals : result.data,
						oldClientId: val
					});					
				},
				// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
				// чтобы не перехватывать исключения из ошибок в самих компонентах.
				(error) => {
					this.setState({
						...this.state,
						isLoaded: true,
						error,
						updateGoalList: true,
						clientGoals: this.state.clientGoals,
						oldClientId: val
					});					
				}
			)

			//console.log('onChangeHandler DATE: ', controlDate);
		} else {
			//console.log('NOT f_clientId');
		}	
		
		control.value = val
		control.touched = true
		control.valid = true //this.validateControl(control.value, control.validation)

		formControls[controlName] = control

		//console.log('control: ', control);
		
		let isFormValid = true		

		this.setState({
			...this.state,
		 	formControls, 
			isFormValid,
			updateGoalList: false, 
			oldClientId: val
		})
			
	}

	onChangeHandler = (event, controlName, controlDate) => {
		//console.log('onChangeHandler FIRED event: ', event);
		//console.log('onChangeHandler FIRED event.target.value: ', event.target.value);
		//console.log('onChangeHandler FIRED controlName: ', controlName);

		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }	

		if (controlName === 'f_deadLine' || controlName === 'f_clientId') {
			control.value = event
			//console.log('f_clientId - onChangeHandler event: ', event);
			//console.log('f_clientId - onChangeHandler controlName: ', controlName);

			//console.log('onChangeHandler DATE: ', controlDate);
		} else {

			if (event.target.selectedOptions) {
				let value = Array.from(
					event.target.selectedOptions,
					(option) => option.value
				);
				control.value = value  
			} else {
				if (controlName==='f_isRepeat' || controlName==='f_isFix') {
					//console.log('GET CHECKBOX VALUES, controlName: ', controlName);
					//console.log('GET CHECKBOX VALUES, event.target.checked: ', +event.target.checked);
					control.value = +event.target.checked
				} else {
					control.value = event.target.value
				}
			}	
		}	

		control.touched = true
		control.valid = true //this.validateControl(control.value, control.validation)

		formControls[controlName] = control
		
		let isFormValid = true
		
		//console.log('onChangeHandler CONTROL: ', control);	

		this.setState({
			...this.state,
		 	formControls, 
			isFormValid
		})	

		if (controlName==='f_taskTitle' || controlName==='f_descText' || controlName==='f_comment') {	
			//console.log('onChangeHandler event: ', event);
       		//console.log('onChangeHandler event.target: ', event.target);

			const element = event.target;
			   
			var offset = element.offsetHeight - element.clientHeight;

			//element.addEventListener('input', function (event) {
				console.log(' HERE IS A HEIGHT Changed');
				event.target.style.height = 0;
				event.target.style.height = event.target.scrollHeight + offset + 'px';
				console.log(' HERE IS A HEIGHT Changed');
				//console.log('event.target.scrollHeight: '+event.target.scrollHeight+' offset: '+offset);

			//});

		}
	}

	closeAddPlanTaskPanel = () => {
		let panel = document.getElementById('addTaskSidePanel');
		if (panel) panel.classList.remove('active');
		//console.log('Close addPlanTask Clicked');
	}

	archiveTask = async (taskId) => {

		let userId = this.props.userId

		let response = null

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/?cardId=${taskId}&isNaked=1&nc_ctpl=183&userId=${userId}`)
			const data = response.data
			
			console.log('task 4', response.data)

			if (data.error) {
				//console.log('task - 4.1');
				//dispatch(saveFail())
				console.log('==== archiveTask ERROR 1');	
			} else {
				//console.log('task 41.2');
				//dispatch(saveSuccess(data))

				
				console.log('==== archiveTask SUCCESS, close popup');					
				console.log('close popup');

				await this.props.updateTasksData(this.props.userHash, 
					{
					  'amIManager': this.props.amIManager,
					  'amIExecutor': this.props.amIExecutor,
					  'amIInvolved': this.props.amIInvolved,
					  'taskClient': this.props.taskClient
					}
				)

				this.closeAddPlanTaskPanel()							
					
			}

		} catch (e) {
			//console.log('task 4.3', e)
			//dispatch(saveFail())
			console.log('==== archiveTask ERROR 2');
		}

		

		console.log('TASK ID: ', taskId);
	}

	copyTask = async (taskId) => {

		let userId = this.props.userId

		let response = null

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/?cardId=${taskId}&isNaked=1&nc_ctpl=210&userId=${userId}`)
			const data = response.data
			
			console.log('copy task response.data', response.data)

			if (data.error) {
				//console.log('task - 4.1');
				//dispatch(saveFail())
				console.log('==== copyTask ERROR 1');	
			} else {
				//console.log('task 41.2');
				//dispatch(saveSuccess(data))

				console.log('copy task response.data', response.data)
				console.log('==== copyTask SUCCESS, close popup');					
				console.log('close popup');
				/*
				await this.props.updateTasksData(this.props.userHash, 
					{
					  'amIManager': this.props.amIManager,
					  'amIExecutor': this.props.amIExecutor,
					  'amIInvolved': this.props.amIInvolved,
					  'taskClient': this.props.taskClient
					}
				)
				*/
				this.closeAddPlanTaskPanel()							
					
			}

		} catch (e) {
			//console.log('task 4.3', e)
			//dispatch(saveFail())
			console.log('==== copyTask ERROR 2');
		}		

		console.log('COPY - OLD TASK ID: ', taskId);
	}

	saveTaskData = async (keepPopupOpened = false) => {

		console.log('saveTaskData PROPS: ', this.props);
		console.log('saveTaskData STATE: ', this.state);

		//check important fields

		const sd_formControls = { ...this.state.formControls }
		let error_counter = 0;			

		const control_taskTitle = { ...sd_formControls['f_taskTitle'] }
		if (!control_taskTitle.value ||  control_taskTitle.value=='') {
			control_taskTitle.valid = false
			sd_formControls['f_taskTitle'] = control_taskTitle	
			error_counter++		
		} 	
		const control_clientId = { ...sd_formControls['f_clientId'] }
		if (!control_clientId.value || control_clientId.value=='') {
			control_clientId.valid = false
			sd_formControls['f_clientId'] = control_clientId
			error_counter++				
		} 
		const control_taskExecutorId = { ...sd_formControls['f_taskExecutorId'] }
		if (!control_taskExecutorId.value || control_taskExecutorId.value=='') {
			control_taskExecutorId.valid = false
			sd_formControls['f_taskExecutorId'] = control_taskExecutorId
			error_counter++				
		} 
		const control_planTime = { ...sd_formControls['f_planTime'] }
		if (!control_planTime.value || control_planTime.value=='') {
			control_planTime.valid = false
			sd_formControls['f_planTime'] = control_planTime	
			error_counter++			
		}

		//let isFormValid = true		
		if (error_counter > 0) {
			this.setState({
				...this.state,
				formControls: sd_formControls			
			})
		}

		// this.setState({
		// 	...this.state, 
		// 	isFormValid: true,
		// 	isEditGoal: false,
		// 	oldEditGoalId: 0
		// })		

		let filterData = {
			'amIManager': this.props.amIManager,
			'amIExecutor': this.props.amIExecutor,
			'amIInvolved': this.props.amIInvolved,
			'taskClient': this.props.taskClient
		}
		
		if (error_counter == 0) {

			await this.props.saveTaskData(
				(this.props.taskData && this.props.taskData._id ? this.props.taskData._id : this.props.taskId),
				this.props.name,
				this.props.data,
				this.state.formControls,
				this.props.userId,
				this.props.taskData.listId
			)

			//this.props.fireUpdateTasksList()

			const formControls = { ...this.state.formControls }

			Object.values(formControls).map((formControl) => {
				formControl.value = ''				
			})

			formControls['f_deadLine'].value = new Date()
				
			this.setState({
				...this.state,
				formControls
			},
			async function() {

				console.log('==== keepPopupOpened: ' + keepPopupOpened);
				console.log('filterdata: ', filterData);
				console.log('saveTaskData PROPS 2: ', this.props);			

				if (!keepPopupOpened) {
					console.log('close popup');
					await this.props.updateTasksData(this.props.userHash, filterData)
					this.closeAddPlanTaskPanel()	
				} else {

					console.log('==== keepPopupOpened WAY 2-000', filterData);	

					await this.props.updateTasksData(this.props.userHash, filterData, true)

					console.log('keep popup opened');
				}	
			})	 

			//Object.values(document.querySelectorAll('.spbbClientData')).map(item => item.innerHTML = '<span class="clientName">1 Выбрать...</span>')

			//.at.console.log(document.querySelectorAll('.cpfSelected'))			
			
			// this.setState({
			// 	...this.state,
			// 	formControls, 
			// 	isFormValid: false,
			// 	isEditGoal: false,
			// 	oldEditGoalId: 0,
			// 	justUpdated: true
			// })

			//this.props.cancelEditGoal()
			//await this.forceUpdate()

			//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state: ', this.state);		
			//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ 2, текущий state: ', this.state);
			//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state.isEditGoal: ', this.state.isEditGoal);
			//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state.oldEditGoalId: ', this.state.oldEditGoalId);
		}	

	}

	componentDidMount() {
		const { userId } = this.props;	
	}
	
	componentDidUpdate() {
		//console.log('-04- HERE UPDATES, this.props: ', this.props);
		//this.forceUpdate()		
	}

	render() {

		//const [startDate, setStartDate] = new Date();
		//let startDate  = this.state.deadLine;
		//const setStartDate  = new Date();

		const { error, isLoaded, task } = this.state;

		let currentTaskId = false;

		if (this.props.taskData && this.props.taskData._id) {
			currentTaskId = this.props.taskData._id;
		} else {
			if (this.props.taskId) {
				currentTaskId = this.props.taskId;
			}			
		}	
		
		
		//console.log('this.props.taskData.manager.manager_id: ', this.props.taskData.manager.manager_id)

		//console.log('-02- TASK PANEL RENDER, state: ', this.state);
		//console.log('-02- TASK PANEL RENDER, props: ', this.props);

		// console.log('-020: '+this.state.formControls['f_deadLine']);
		// console.log('-021: '+this.state.formControls['f_deadLine'].value);

		// if (this.state.formControls['f_deadLine'] && this.state.formControls['f_deadLine'].value && this.state.formControls['f_deadLine'].value.typeof == 'string') {
		// 	const formControls = { ...this.state.formControls }
		// 	formControls['f_deadLine'].value = new Date()				
		// 	this.setState({
		// 		...this.state,
		// 		formControls
		// 	})
		// }
		//} ? this.state.formControls['f_deadLine'].value : new Date())
		//if (this.state.formControls['f_clientId'].value && this.state.formControls['f_clientGoalId'].value && this.state.clientGoals.length === 0) {
		//	console.log('получить задачи');
		//	this.onChangeHandlerList(this.state.formControls['f_clientId'].value, 'f_clientId');
		//}
		/*if (error) {
			return <div className="addGoalPanelWrapper" key="0">Ошибка загрузки данных задачи. (1)</div>;
		} else if (!isLoaded) {
			return <div className="addGoalPanelWrapper" key="1">Загружаем данные формы добавления задач... (2)</div>;
		} else {*/
			return (
				/*goal.map((goalData, index) => (*/
					<div className="addGoalPanelWrapper" key="2">

						<div className="tmspHeader">
							<div className="tmTrash">{currentTaskId && ((this.props.taskData.manager.manager_id == this.props.taskData.executor.executor_id) || this.props.userRole != 2) ? <button data-value="#trashGoal" onClick={() => this.archiveTask(currentTaskId)} title="Удалить задачу"></button> : null}</div>
							<div className="tmCopy"><button data-value="#copyGoal" onClick={() => this.copyTask(currentTaskId)} title="Скопировать задачу"></button></div>
							<div className="tmClose"><button data-value="#closeGoal" onClick={this.closeAddPlanTaskPanel} title="Закрыть панель"></button></div>	
						</div>
						<div className="tmspData">							

								
							<div className="tmspdRow">	
								<div className="goalTitle">									

									<Textarea 
										key="1"													
										value={this.state.formControls['f_taskTitle'].value}		
										placeholder="Укажите название подзадачи"													
										label="Подзадача"
										onChange={event => this.onChangeHandler(event, 'f_taskTitle')}	
										isValid={this.state.formControls['f_taskTitle'].valid}	
										name="f_taskTitle"	
									/>

								</div>
							</div>
								

							<div className="tmspdRow flexStart">
								<div className="spBlock w142">

									<ClientList 
										key="2"
										isSimple="true"
										name="f_clientId"
										type="hidden" 
										value={this.state.formControls['f_clientId'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										isValid={this.state.formControls['f_clientId'].valid}										
									/>

								</div>
								<div className="spBlock w100percent">
									
									<ClientGoalsList
										key="3"
										name="f_clientGoalId"
										type="hidden" 
										value={this.state.formControls['f_clientGoalId'].value} 
										value_name={this.state.formControls['f_clientGoalId'].value_name}
										client={this.state.formControls['f_clientGoalId'].client} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										clientGoals={this.state.clientGoals}
										clientId={this.state.formControls['f_clientId'].value} 
									/>
												
								</div>
							</div>
							<div className="tmspdRow">
								<div className="spBlock w100percent">

									<UserList
										key="4"
										name="f_taskManagerId"
										type="hidden" 
										label="Постановщик:"
										value={this.state.formControls['f_taskManagerId'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										userId={this.props.userId}
										userPhoto={this.props.userPhoto}
										userName={this.props.userName}
										users={this.props.users}
									/>

								</div>
								<div className="spBlock w100percent">

									<UserList
										key="5"
										name="f_taskExecutorId"
										type="hidden" 
										label="Исполнитель:"
										value={this.state.formControls['f_taskExecutorId'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										users={this.props.users}
										isValid={this.state.formControls['f_taskExecutorId'].valid}											
									/>

								</div>

								<div className="spBlock w100percent">

									<UserList
										key="6"
										name="f_taskParticipantsIds"
										type="hidden" 
										label="Другие участники:"
										value={this.state.formControls['f_taskParticipantsIds'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										users={this.props.users}
										taskId={this.props.taskData._id}
										manager={this.state.formControls['f_taskManagerId'].value}
										executor={this.state.formControls['f_taskExecutorId'].value}
									/>

								</div>

							</div>
							<div className="tmspdRow">
								
								<div className="spBlock w100percent shortInput">									
									<Input 
										key="7"
										type="text"
										value={this.state.formControls['f_standardTime'].value}			
										placeholder={this.state.formControls['f_standardTime'].placeholder}										
										label="Норма"										
										onChange={event => this.onChangeHandler(event, 'f_standardTime')}			
									/>
								</div>

								<div className="spBlock w100percent shortInput">									
									<Input 
										key="8"
										type="text"
										value={this.state.formControls['f_planTime'].value}			
										placeholder={this.state.formControls['f_planTime'].placeholder}										
										label="План"										
										onChange={event => this.onChangeHandler(event, 'f_planTime')}
										isValid={this.state.formControls['f_planTime'].valid}	
										name="f_planTime"				
									/>
								</div>

								<div className="spBlock w100percent shortInput">									
									<Input 
										key="9"
										type="text"
										value={this.state.formControls['f_usedTime'].value}			
										placeholder={this.state.formControls['f_usedTime'].placeholder}	
										disabled="disabled"									
										label="Факт"										
										onChange={event => this.onChangeHandler(event, 'f_usedTime')}			
									/>
								</div>

							</div>

							<div className="tmspdRow">								
								<div className="spBlock w142">									
									<div className="spbHeader"><label htmlFor="f_deadLine">Дедлайн</label></div>
									<div className="spbBody">
										<DatePicker 
											key="2"
											id="f_deadLine" 
											selected={this.state.formControls['f_deadLine'].value} 
											onChange={(event, date) => this.onChangeHandler(event, 'f_deadLine', date)}
											locale="ru"
											dateFormat="dd.MM.yyyy HH:mm"
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={30}
											timeCaption="время"
										/>		
									</div>								
								</div>
							</div>

							<div className="tmspdRow">								

								<Textarea 
									key="10"													
									value={this.state.formControls['f_descText'].value}		
									placeholder="Укажите текст описания"													
									label="Описание"
									onChange={event => this.onChangeHandler(event, 'f_descText')}
									className="nomargin"			
								/>

							</div>

							<div className="tmspdRow">								

								<Textarea 
									key="11"													
									value={this.state.formControls['f_taskResult'].value}		
									placeholder="Ссылка на результат (https://...)"													
									label="Ссылка на результат"
									onChange={event => this.onChangeHandler(event, 'f_taskResult')}
									className="nomargin"			
								/>

							</div>

							<div className="tmspdRow noborder flexStart">									

									<Textarea 
										key="12"													
										value={this.state.formControls['f_comment'].value}		
										placeholder="Комментарий..."													
										label="Активность"
										onChange={event => this.onChangeHandler(event, 'f_comment')}
										className="nomargin"	
										userId={this.props.userId}
										userPhoto={this.props.userPhoto}
										users={this.props.users}
										manager={this.state.formControls['f_taskManagerId'].value}
										executor={this.state.formControls['f_taskExecutorId'].value}
										name="f_comment"
										onBtnClick={this.saveTaskData}
									/>								

							</div>

							<div className="tmspdRow noborder flexStart pt3">
								<div className="taskComments">
								{
									this.props.taskData && this.props.taskData.comments
									?
									this.props.taskData.comments.map( (comment, index) => (
										<div className="oneComment" key={'comment_'+index}>
											<div 
												className={
													'currentUserComment' 
													+ (this.state.formControls['f_taskManagerId'] && comment.user_id === this.state.formControls['f_taskManagerId'].value ? ' taskManagerSign' : '' ) 
													+ (this.state.formControls['f_taskExecutorId'] && comment.user_id === this.state.formControls['f_taskExecutorId'].value ? ' taskExecutorSign' : '' ) 
												} 
												style={{backgroundImage: "url(" + comment.photo + ")"}}
											></div>
											<div className="commentWrapper">
												<div className="commentWrapperData"><div className="commentWrapperDataName">{comment.name}</div><div className="commentWrapperDataDate">{comment.comment_created}</div></div>	
												<div className="commentWrapperText">{comment.comment_text}</div>	
											</div>	
										</div>
									))
									:
									null
								}

								{
									this.props.taskData && this.props.taskData.status
									?
									this.props.taskData.status.map( (status, index) => (
										<div className="oneComment" key={'status_'+index}>
											<div 
												className={
													'currentUserComment' 
													+ (this.state.formControls['f_taskManagerId'] && status.user_id === this.state.formControls['f_taskManagerId'].value ? ' taskManagerSign' : '' ) 
													+ (this.state.formControls['f_taskExecutorId'] && status.user_id === this.state.formControls['f_taskExecutorId'].value ? ' taskExecutorSign' : '' ) 
												} 
												style={{backgroundImage: "url(" + status.photo + ")"}}
											></div>
											<div className="commentWrapper">
												<div className="commentWrapperData flexColumn">
													<div className="commentWrapperDataName">{status.name} <span>переместил карточку в столбец</span> <strong>{status.status_name}</strong></div>
													<div className="commentWrapperDataDate">{status.status_created}</div>
												</div>													
											</div>	
										</div>
									))
									:
									null
								}

								{
									this.props.taskData && this.props.taskData.manager && this.props.taskData.executor
									?
									<div className="oneComment" key="setExecutor">
										<div className="currentUserComment taskManagerSign" style={{backgroundImage: "url(" + this.props.taskData.manager.photo + ")"}}></div>
										<div className="commentWrapper">
											<div className="commentWrapperData flexColumn">
												<div className="commentWrapperDataName">{this.props.taskData.manager.name} <span>назначил ответственного</span> <strong>{this.props.taskData.executor.name}</strong></div>
												<div className="commentWrapperDataDate">{this.props.taskData.taskData.created}</div>
											</div>													
										</div>	
									</div>	
									:
									null
								}	

								{
									this.props.taskData && this.props.taskData.manager
									?
									<div className="oneComment" key="createdComment">
										<div className="currentUserComment taskManagerSign" style={{backgroundImage: "url(" + this.props.taskData.manager.photo + ")"}}></div>
										<div className="commentWrapper">
											<div className="commentWrapperData flexColumn">
												<div className="commentWrapperDataName">{this.props.taskData.manager.name} <span>создал карточку</span></div>
												<div className="commentWrapperDataDate">{this.props.taskData.taskData.created}</div>
											</div>													
										</div>	
									</div>	
									:
									null
								}	

								</div>							
							</div>

							<div className="tmspdFooter"><button data-value="#createTask" onClick={this.saveTaskData}>{this.state.isEditGoal ? 'Сохранить' : 'Создать'}</button></div>
						</div>

					</div>
				/*))			*/
			);
		/*}			*/
		
	}

}

function mapDispatchToProps(dispatch) {
	return {
		/*hideModal: () => {
			dispatch(hideModal())
		},*/

		saveTaskData: (taskId, cardText, formData, fields, userId, listId) => dispatch(saveTaskData(taskId, cardText, formData, fields, userId, listId)),

		updateTasksData: (userHash, filterData, keepPopupOpened) => dispatch(updateTasksData(userHash, filterData, keepPopupOpened))		

	}
}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	userRole: state.auth.userRole,
 	userPhoto: state.auth.userPhoto,
 	userName: state.auth.userName,
	userHash: state.auth.token,

	amIManager: state.app.amIManager,
    amIExecutor: state.app.amIExecutor,
    amIInvolved: state.app.amIInvolved,
	taskClient: state.app.taskClient

	//taskData: state.task.data
});  

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskPanelWrapper);