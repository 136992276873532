import "../styles/GoalList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import DatePicker from "react-datepicker";
import Checkbox from "./UI/Checkbox/Checkbox";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import store from "../store.js"

registerLocale('ru', ru)

const fullMonthArray = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']		

class SpecList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  goals: [],
		  clientId: this.props.clientId,
		  isHourlyRate: this.props.isHourlyRate,
          isFix: this.props.isFix,
          isRepeat: this.props.isRepeat,            
          goalMonthFilter: this.props.goalMonthFilter,
		  localMonth: false,
		  localYear: false,
		};
		this.unsubscribe = null;
	}

	processDate = (date) => {
		if (date.includes('-')) {
			const date_array = date.split('-')
			return new Date(date_array[0], date_array[1]-1, date_array[2])
		}
		return date
	}

	numberFormat = str => {		
		return str.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
	}

	onChangeDeadLineHandler = (goalId, date) => {
		const { userId } = this.props;	
		
		const currentMonth = date.getMonth()+1;
		let currentMonthString = currentMonth.toString();
		if (currentMonth<10) {
			currentMonthString = '0'+ currentMonth.toString();
		}

		const currentDate = date.getDate();
		let currentDateString = currentDate.toString();
		if (currentDate<10) {
			currentDateString = '0'+ currentDate.toString();
		}

		const taskData = {	
			userId, 
			goalId,     
			isNaked: 1,
			nc_ctpl: 191,
			deadline: date.getFullYear()+'-'+currentMonthString+'-'+currentDateString
		}
		
		if (this.state.clientId !==0) {
			taskData.clientId = this.state.clientId
		}

		if (this.props.isHourlyRate) {
			taskData.isHourlyRate = +this.props.isHourlyRate
		}

		if (this.props.isFix) {
			taskData.isFix = +this.props.isFix
		}

		if (this.props.isRepeat) {
			taskData.isRepeat = +this.props.isRepeat
		}

		if (this.props.goalMonthFilter) {
			taskData.goalMonthFilter = this.props.goalMonthFilter.getFullYear() + '-' + (this.props.goalMonthFilter.getMonth()<10 ? '0'+this.props.goalMonthFilter.getMonth() : this.props.goalMonthFilter.getMonth()) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate())
		}

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					//goals: result.data.error ? this.state.goals : result.data
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()

			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					//goals: this.state.goals
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()
			}
		)
	}

	dateFormat = dateString => {
		const dateObject = new Date(dateString);
		const dateObjectDay = dateObject.getDate();
		const monthArray = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
		const dateObjectMonth = monthArray[dateObject.getMonth()];
		const dateObjectYear = dateObject.getFullYear();
		return dateObjectDay + ' ' + dateObjectMonth + ' ' + dateObjectYear
	}

	updateGoalList = (clientId, locMonth,  locYear) => {
		const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 197
		}		
		
		if (this.state.clientId !==0) {
			taskData.clientId = this.state.clientId
		}

		if (this.props.isHourlyRate) {
			taskData.isHourlyRate = +this.props.isHourlyRate
		}

		if (this.props.isFix) {
			taskData.isFix = +this.props.isFix
		}

		if (this.props.isRepeat) {
			taskData.isRepeat = +this.props.isRepeat
		}

		if (this.props.goalMonthFilter) {
			taskData.goalMonthFilter = this.props.goalMonthFilter.getFullYear() + '-' + ((this.props.goalMonthFilter.getMonth()+1)<10 ? '0'+(this.props.goalMonthFilter.getMonth()+1) : (this.props.goalMonthFilter.getMonth()+1)) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate())
		}

		if (locMonth && locYear) {
			taskData.goalMonthFilter = locYear + '-' + ( (+locMonth + 1)<10 ? '0'+(+locMonth + 1) : (+locMonth + 1) ) + '-01'
			//console.log('-- another taskData.goalMonthFilter: ', taskData.goalMonthFilter);
		}

		//console.log('updateGoalList, taskData: ', taskData);
		//console.log('this.state: ', this.state);

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					goals: result.data.error ? this.state.goals : result.data,
					clientId: clientId ? clientId : this.state.clientId,
					isHourlyRate: +this.props.isHourlyRate,
					isFix: +this.props.isFix,
					isRepeat: +this.props.isRepeat,
					goalMonthFilter: this.props.goalMonthFilter,
					localMonth: locMonth ? locMonth : false,
					localYear: locYear ? locYear : false,
				});

				this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					goals: this.state.goals,
					clientId: clientId ? clientId : this.state.clientId,
					isHourlyRate: +this.props.isHourlyRate,
					isFix: +this.props.isFix,
					isRepeat: +this.props.isRepeat,
					goalMonthFilter: this.props.goalMonthFilter
				});

				this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			}
		)
		
	}

	deleteGoal = (event, goalId ) => {

		event.stopPropagation()

		const { userId } = this.props;		

		const taskData = {	
			userId, 
			goalId,     
			isNaked: 1,
			nc_ctpl: 190
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					//goals: result.data.error ? this.state.goals : result.data
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()

			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					//goals: this.state.goals
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()
			}
		)

	}

	editGoal = (event, goal) => {

		//console.log('GOAL LIST FIRE PARENT FUNCTION, DATA: ', goal);
		//console.log('event.target: ', event.target);

		if (!event.target.closest('.checkboxBodyWrapper')) {	
			//console.log('1');
			this.props.fireEditGoal(goal)
		} else {
			//console.log('2');
			event.stopPropagation()
		}

	}

	resetGoalCorrections = (event, category, goal, id) => {
		console.log('state: ', this.state)
		console.log('category: ', category)
		console.log('goal: ', goal)

		
		let taskId = this.state.goals[0].categories[category].goals[goal].sumCorrected
		let goalObject = this.state.goals

		goalObject[0].categories[category].goals[goal].sumCorrected = null
		

		const { userId } = this.props;

		const taskData = {	
			userId, 
			goalId: id,			 
			isNaked: 1,
			nc_ctpl: 200
		}	

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				// this.setState({
				// 	isLoaded: true,
				// 	goals: result.data.error ? this.state.goals : result.data,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				// this.setState({
				// 	isLoaded: true,
				// 	error,
				// 	goals: this.state.goals,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			}
		)		

		this.setState({
			...this.state,
			goals: goalObject
		},
		function() {

			// console.log('TASK CHANGED 2');
			// console.log('+ 3 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill)
		    // console.log('1 category: ', category)
		    // console.log('1 goal: ', goal)
			// console.log('2 goals: ', this.state.goals)
			//this.updateGoalList()
		})
		

		event.stopPropagation()
	}

	componentDidMount() {
		//this.updateGoalList()
		this.unsubscribe = store.subscribe(() => {
			//console.info('SPEC 115 STORE::::: ', store.getState())
			let localStore = store.getState();						
			this.updateGoalList(null, localStore.app.currentMonth, localStore.app.currentYear)			
		})
	}

	componentWillUnmount() {
		this.unsubscribe && this.unsubscribe();
	}

	taskSet = (event, category, goal, task) => {

		const { userId } = this.props;	
		
		//console.log('1 goals: ', this.state.goals)
		//console.log('+ 1 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', Boolean(+this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill))

		let taskIsInBill = !Boolean(+this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill)

		//console.log('+ 2 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', taskIsInBill)

		let taskId = this.state.goals[0].categories[category].goals[goal].tasks[task].taskId
		let goalObject = this.state.goals

		goalObject[0].categories[category].goals[goal].tasks[task].isInBill = taskIsInBill			

		const taskData = {	
			userId, 
			taskId,
			taskIsInBill: Number(taskIsInBill),     
			isNaked: 1,
			nc_ctpl: 198
		}	

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				// this.setState({
				// 	isLoaded: true,
				// 	goals: result.data.error ? this.state.goals : result.data,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				// this.setState({
				// 	isLoaded: true,
				// 	error,
				// 	goals: this.state.goals,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			}
		)


		this.setState({
			...this.state,
			goals: goalObject
		},
		function() {

			// console.log('TASK CHANGED 2');
			// console.log('+ 3 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill)
		    // console.log('1 category: ', category)
		    // console.log('1 goal: ', goal)
			// console.log('2 goals: ', this.state.goals)
			//this.updateGoalList()
		})

		event.stopPropagation()

	}

	goalSet = (event, category, goal) => {

		event.stopPropagation()

		console.log('event.target: ', event.target)
		// console.log('category: ', category)
		// console.log('goal: ', goal)

		const { userId } = this.props;	

		//let stateGoal = this.state.goals[0].filter( oneCat => (oneCat.categoryId === goal.categoryId))[0].goals.map(oneGoal => oneGoal.filter( item => (item.id === goal.id))[0]
		let goalIsInBill = !Boolean(+this.state.goals[0].categories[category].goals[goal].isInBill)

		let goalId = this.state.goals[0].categories[category].goals[goal].id

		let goalObject = this.state.goals

		goalObject[0].categories[category].goals[goal].isInBill = goalIsInBill
		if (goalObject[0].categories[category].goals[goal].tasks.length > 0) {
			goalObject[0].categories[category].goals[goal].tasks.map( oneTask => (oneTask.isInBill = goalIsInBill))
		}

		const taskData = {	
			userId, 
			goalId,
			goalIsInBill: Number(goalIsInBill),     
			isNaked: 1,
			nc_ctpl: 199
		}	

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				// this.setState({
				// 	isLoaded: true,
				// 	goals: result.data.error ? this.state.goals : result.data,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				// this.setState({
				// 	isLoaded: true,
				// 	error,
				// 	goals: this.state.goals,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			}
		)

		this.setState({
			...this.state,
			goals: goalObject
		},
		function() {
			//this.updateGoalList()
		})

		
	}
	
	componentDidUpdate() {
		// if (this.props.clientId != this.state.clientId) {
		// 	this.setState({
		// 		...this.state,
		// 		clientId: this.props.clientId
		// 	})
		// }
		//console.log('111: componentDidUpdate - this.props.isNeedUpdateGoalsList: ', this.props.isNeedUpdateGoalsList);
//
		if (this.props.isNeedUpdateGoalsList || this.state.isHourlyRate != this.props.isHourlyRate || this.state.isFix != this.props.isFix || this.state.isRepeat != this.props.isRepeat || this.state.goalMonthFilter.toString() != this.props.goalMonthFilter.toString()) {
			//console.log('222 this.props.isNeedUpdateGoalsList FIRED ', this.props.fireUpdateGoalsList);
			if (this.props.clientId != this.state.clientId) {
				this.updateGoalList(this.props.clientId)
			} else {	
				this.updateGoalList()
			}
			//this.props.fireUpdateBudget()
			//this.props.stopUpdateGoalsList()			
		}
		//return null
	}

	render() {	

		const { error, isLoaded, goals } = this.state;

		let renderMonth = this.props.currentMonth ? this.props.currentMonth : new Date().getMonth()
		let renderYear = this.props.currentYear ? this.props.currentYear : new Date().getFullYear()

		console.log('goals: ', goals);

		let noBillArr = []

		if (this.state.goals.length > 0) {	
			this.state.goals[0].categories.map( (oneCat, catIndex) => {
				oneCat.goals.map( oneGoal => {
					if (oneGoal.tasks.length > 0) {
						oneGoal.tasks.map( (oneTask, goalIndex) => {
							if (!!Number(oneTask.isInBill) === false) {								
								if (!noBillArr.includes(oneGoal)) {
									oneGoal.goalIndex = goalIndex
									oneGoal.catIndex = catIndex
									noBillArr.push(oneGoal) 
								}	
							} 
							return null
						})	
					} 
					return null
				})	
				return null						
			})			
		}

		//console.log('noBillArr: ', noBillArr);

		if (error) {
			return <li key='0'>Ошибка загрузки данных по задачам. (1)</li>;
		} else if (!isLoaded) {
			return <li key='0'>Загружаем данные о задачах... (2)</li>;
		} else {
			return (
				goals.length > 0
				? 
				goals.map((goal, index) => (
					<div className="goalListWrapper" key={index}>
						<div className="clientDataHeader">
							<div className="cdhLeft">
								<div className="cdhlLogo"><img src={goal.clientLogo} alt="" /></div>
								<div className="cdhlName">{goal.clientName}</div>								
								<div className="cdhlMonth">{this.props.goalMonthFilter ? fullMonthArray[renderMonth]+' ‘'+renderYear.toString().substr(-2) : ''}</div>
							</div>
							{/*<div className="cdhRight"><button data-value="#addGoal" onClick={this.addPlanTask} title="Показать панель добавления">Добавить задачу</button></div>*/}
						</div>
						<div className="clientGoals specGoals">
							{ /* +(category.categoryId==6 ? ' greyBg' : '') */
								goal.categories.map((category, categoryIndex) => (
									<div className={'cgGoalCategory'} key={categoryIndex}>
										{
										categoryIndex===0
										?	
										<div className="cgGoalCategoryName" key={'cggcName_0'}>
											<div className="cgGCNName">{/*category.categoryName*/}Задача</div>
											<div className="cgGCNPlan">План,&nbsp;сумма</div>
											<div className="cgGCNPlan">План,&nbsp;часы</div>
											<div className="cgGCNExecutor">Исполнители</div>
											<div className="cgGCNUsedTime">Факт,&nbsp;сумма</div>
											<div className="cgGCNUsedTime">Факт,&nbsp;часы</div>											
											<div className="cgGCNCorrection">Корр.</div>
											<div className="cgGCNStatus">Статус</div>
											<div className="cgGCNResult">&nbsp;</div>
											<div className="cgGCNSet">Выставлять</div>
										</div>
										:
										<></>
										}
										<div className="cgGoalCategoryList" key={'catList_' + categoryIndex}>
											{ /*  data-value={'#addComment_'+goalIndex} onClick={() => this.editGoal(categoryGoal)} */
												category.goals.map((categoryGoal, goalIndex) => (
													<div className="cggcl_row_tasks" key={goalIndex} >
														<div className="cggcl_row" key={'gi_'+goalIndex} onClick={(event) => this.editGoal(event, categoryGoal)}>
															<div className="cgGCNName">
																<div className="goalRowLeft">
																	<div className="goalRowMarks">{categoryGoal.isFix==1 ? <span className="fixedSum">F</span> : null}{categoryGoal.isRepeat==1 ? <span className="repeatGoal">R</span> : null}</div>
																	<div className="goalRowName">{categoryGoal.title}</div>
																</div>
															</div>

															<div className="cgGCNPlan boldText">{this.numberFormat(categoryGoal.sum)} р.</div>
															<div className="cgGCNPlan boldText">{this.numberFormat(categoryGoal.timePlan)} ч.</div>

															<div className="cgGCNExecutor">&nbsp;</div>
															<div className={'cgGCNUsedTime boldText' + (parseFloat(categoryGoal.goalUsedTime*this.props.isNeedUpdateCurrentClient.rate) > parseFloat(categoryGoal.sum) ? ' redText' : ' normalText')}>{this.numberFormat( parseFloat(categoryGoal.goalUsedTime)*this.props.isNeedUpdateCurrentClient.rate )} р.</div>
															<div className={'cgGCNUsedTime boldText' + (parseFloat(categoryGoal.goalUsedTime*this.props.isNeedUpdateCurrentClient.rate) > parseFloat(categoryGoal.sum) ? ' redText' : ' normalText')}>{this.numberFormat( parseFloat(categoryGoal.goalUsedTime) )} ч.</div>
															<div className="cgGCNCorrection">
																{
																!!categoryGoal.sumCorrected  && Number(categoryGoal.sumCorrected) > 0 
																? 
																<div className="scWrapper">
																	<span className="sumCorrText">{this.numberFormat(categoryGoal.sumCorrected)} р.</span>
																	<button className="setResetButton" onClick={event => this.resetGoalCorrections(event, categoryIndex, goalIndex, categoryGoal.id)}></button>
																</div> 
																: 
																null
																}
															</div>
															<div className="cgGCNStatus">&nbsp;</div>
															<div className="cgGCNResult">{categoryGoal.taskResult ? <a target="_blank" rel="noreferrer" title="Ссылка откроется в новой вкладке" href={categoryGoal.taskResult}>Результат</a> : ''}</div>
															<div className="cgGCNSet">
																<Checkbox 
																	key={goalIndex+'_'}
																	type="N"
																	value={+categoryGoal.isInBill}												
																	label="noLabel"										
																	onChange={event => this.goalSet(event, categoryIndex, goalIndex)}			
																/>
															</div>
														</div>
														{(
														categoryGoal.tasks.length > 0
														?
														categoryGoal.tasks.map(( task, taskIndex) => (
															<div className={'cggcl_row noBg' + (!!Number(task.isInBill) ? '' : ' noBill')} key={'ti_'+taskIndex}>
																<div className="cgGCNName">
																	<div className="goalRowLeft">
																		<div className="goalRowMarks w37"></div>
																		<div className="goalRowName">{task.title}</div>
																	</div>
																</div>

																<div className="cgGCNPlan">{this.props.isNeedUpdateCurrentClient && task ? this.numberFormat((parseFloat(this.props.isNeedUpdateCurrentClient.rate) * parseFloat(task.taskTimePlan))) : ''} р.</div>
																<div className="cgGCNPlan">{task.taskTimePlan ? task.taskTimePlan : ''} ч.</div>

																<div className="cgGCNExecutor">
																	<div className="cgGCNExecutorName">{task.executorName}</div>
																	{/*<div className="cgGCNExecutorUsedTime">{task.taskUsedTimeSum ? task.taskUsedTimeSum.replace('.',',') : 0}</div>*/}
																</div>
																<div className="cgGCNUsedTime greyText">{this.props.isNeedUpdateCurrentClient && task ? this.numberFormat(parseFloat(task.taskUsedTimeSum ? task.taskUsedTimeSum : 0)*this.props.isNeedUpdateCurrentClient.rate) : ''} р.</div>
																<div className="cgGCNUsedTime greyText">{task.taskUsedTimeSum ? task.taskUsedTimeSum.replace('.',',') : 0} ч.</div>
																<div className="cgGCNCorrection">&nbsp;</div>
																<div className="cgGCNStatus">{task.taskStatus ? task.taskStatus : ''}</div>
																<div className="cgGCNResult">{task.taskResult ? <a target="_blank" rel="noreferrer" title="Ссылка откроется в новой вкладке" href={task.taskResult}>Результат</a> : ''}</div>
																<div className="cgGCNSet">
																	<Checkbox 
																		key={goalIndex+'_'+taskIndex}
																		type="N"
																		value={+task.isInBill}												
																		label="noLabel"										
																		onChange={event => this.taskSet(event, categoryIndex, goalIndex, taskIndex)}			
																	/>
																</div>
															</div>	
														))														
														:
														<></>
														)}
													</div>
												))
											}											
										</div>
									</div>	
								))
							}							

							{
							noBillArr.length > 0
							?
								<div className={'cgGoalCategory'} key={'noBillSet_0'}>
									<div className="cgGoalCategoryName solidGrey" key={'noBillSetName_0'}>Не включать в счет</div>
									<div className="cgGoalCategoryList" key={'noBillSetList_0'}>									
									{
										noBillArr.map((categoryGoal, goalIndex) => (
											<div className="cggcl_row_tasks" key={'noBillGoal_'+goalIndex} >
												<div className="cggcl_row" key={'nobillrow_'+goalIndex} onClick={(event) => this.editGoal(event, categoryGoal)}>
													<div className="cgGCNName">
														<div className="goalRowLeft">
															<div className="goalRowMarks">{categoryGoal.isFix==1 ? <span className="fixedSum">F</span> : null}{categoryGoal.isRepeat==1 ? <span className="repeatGoal">R</span> : null}</div>
															<div className="goalRowName">{categoryGoal.title}</div>
														</div>
													</div>
													
													<div className="cgGCNPlan boldText">{this.numberFormat(categoryGoal.sum)} р.</div>
													<div className="cgGCNPlan boldText">{this.numberFormat(categoryGoal.timePlan)} ч.</div>

													<div className="cgGCNExecutor">&nbsp;</div>

													
													<div className={'cgGCNUsedTime boldText' + (parseFloat(categoryGoal.goalUsedTime*this.props.isNeedUpdateCurrentClient.rate) > parseFloat(categoryGoal.sum) ? ' redText' : ' normalText')}>{this.numberFormat( parseFloat(categoryGoal.goalUsedTime)*this.props.isNeedUpdateCurrentClient.rate )} р.</div>
													<div className={'cgGCNUsedTime boldText' + (parseFloat(categoryGoal.goalUsedTime*this.props.isNeedUpdateCurrentClient.rate) > parseFloat(categoryGoal.sum) ? ' redText' : ' normalText')}>{this.numberFormat( parseFloat(categoryGoal.goalUsedTime) )} ч.</div>



													<div className="cgGCNCorrection">
														{
														!!categoryGoal.sumCorrected && Number(categoryGoal.sumCorrected) > 0  
														? 
														<div className="scWrapper">
															<span className="sumCorrText">{this.numberFormat(categoryGoal.sumCorrected)} р.</span>
															<button className="setResetButton" onClick={event => this.resetGoalCorrections(event, categoryGoal.catIndex, categoryGoal.goalIndex, categoryGoal.id)}></button>
														</div> 
														: 
														null
														}
													</div>

													<div className="cgGCNStatus">&nbsp;</div>	

													<div className="cgGCNResult">{categoryGoal.taskResult ? <a target="_blank" rel="noreferrer" title="Ссылка откроется в новой вкладке" href={categoryGoal.taskResult}>Результат</a> : ''}</div>
													<div className="cgGCNSet">
														{/*
														<Checkbox 
															key={goalIndex+'_'}
															type="N"
															value={+categoryGoal.isInBill}												
															label="noLabel"										
															onChange={event => this.goalSet(event, categoryIndex, goalIndex)}			
														/>
														*/}
													</div>
												</div>


												{(
												categoryGoal.tasks.length > 0
												?
												categoryGoal.tasks.map(( task, taskIndex) => (
													!Number(task.isInBill)
													?
													<div className={'cggcl_row noBg noBillRow' + (!!Number(task.isInBill) ? '' : ' noBill')} key={'noBillTaskRow_'+taskIndex}>
														<div className="cgGCNName">
															<div className="goalRowLeft">
																<div className="goalRowMarks w37"></div>
																<div className="goalRowName">{task.title}</div>
															</div>
														</div>

														<div className="cgGCNPlan">{this.props.isNeedUpdateCurrentClient && task ? this.numberFormat((parseFloat(this.props.isNeedUpdateCurrentClient.rate) * parseFloat(task.taskTimePlan))) : ''} р.</div>
														<div className="cgGCNPlan">{task.taskTimePlan ? task.taskTimePlan : ''} ч.</div>

														<div className="cgGCNExecutor">
															<div className="cgGCNExecutorName">{task.executorName}</div>
															{/*<div className="cgGCNExecutorUsedTime">{task.taskUsedTimeSum ? task.taskUsedTimeSum.replace('.',',') : 0}</div>*/}
														</div>

														<div className="cgGCNUsedTime greyText">{this.props.isNeedUpdateCurrentClient && task ? this.numberFormat(parseFloat(task.taskUsedTimeSum ? task.taskUsedTimeSum : 0)*this.props.isNeedUpdateCurrentClient.rate) : ''} р.</div>
														<div className="cgGCNUsedTime greyText">{task.taskUsedTimeSum ? task.taskUsedTimeSum.replace('.',',') : 0} ч.</div>

														<div className="cgGCNCorrection">&nbsp;</div>

														<div className="cgGCNStatus">{task.taskStatus ? task.taskStatus : ''}</div>

														<div className="cgGCNResult">{task.taskResult ? <a target="_blank" rel="noreferrer" title="Ссылка откроется в новой вкладке" href={task.taskResult}>Результат</a> : ''}</div>
														<div className="cgGCNSet">
															{/*
															<Checkbox 
																key={goalIndex+'_'+taskIndex}
																type="N"
																value={+task.isInBill}												
																label="noLabel"										
																onChange={event => this.taskSet(event, categoryIndex, goalIndex, taskIndex)}			
															/>
															*/}
														</div>
													</div>
													:
													null
													
												))														
												:
												null
												)}


											</div>
										))

									}						
									</div>
								</div>
							:
							<></>
							}



						</div>
					</div>
				))
				:	
				
					(
					this.props.clientData	
					?
					<div className="goalListWrapper" key="glw_10">
						<div className="clientDataHeader">
							<div className="cdhLeft">
								<div className="cdhlLogo"><img src={this.props.clientData.logo} alt="" /></div>
								<div className="cdhlName">{this.props.clientData.name}</div>
								<div className="cdhlMonth">{this.props.goalMonthFilter ? fullMonthArray[renderMonth]+' ‘'+renderYear.toString().substr(-2) : ''}</div>
							</div>
							{/*<div className="cdhRight"><button data-value="#addGoal" onClick={this.addPlanTask} title="Показать панель добавления">Добавить задачу</button></div>*/}
						</div>
						<div className="clientGoals">		
							<i>Нет задач в плане.</i>
						</div>				
					</div>
					:
					<></>			
					)


					
			);
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	isNeedUpdateCurrentClient: state.goal.isNeedUpdateCurrentClient,
	currentMonth: state.app.currentMonth,
	currentYear: state.app.currentYear,
});  

export default connect(mapStateToProps)(SpecList);