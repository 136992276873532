
export function app(amIManager, amIExecutor, amIInvolved) {

	const data = {amIManager, amIExecutor, amIInvolved}

	return {
		type: 'UPDATE_APP_FILTER',
		data
	}
}

export function updateMonth(currentMonth) {

	const data = {currentMonth}

	return {
		type: 'UPDATE_APP_MONTH',
		data
	}
}

export function updateYear(currentYear) {

	const data = {currentYear}

	return {
		type: 'UPDATE_APP_YEAR',
		data
	}
}

export function updateWorkerId(currentWorkerId, currentWorkerHours = 0) {

	const data = {currentWorkerId, currentWorkerHours}

	return {
		type: 'UPDATE_APP_WORKER',
		data
	}
}