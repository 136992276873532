const initialState = {
	showModal: false,
    goalId: null,
	cardText: null,
	data: null,
	fields: null,
	userId: null,
	isNeedUpdateCurrentClient: false
}

export default function goalReducer(state = initialState, action) {

	//console.log('MODAL REDUCER: ', action);

	switch (action.type) {
		/*case 'SHOW_MODAL':

			//console.log('MODAL REDUCER show-model-set state, action: ', action);

			return {
				...state, 
				showModal: true,
				cardId: action.data.cardId,
				cardText: action.data.cardText,
				data: action.data.formData
			}

		case 'HIDE_MODAL':

			//console.log('MODAL REDUCER hide-model-set state, action: ', action);

			return {
				...state, 
				showModal: false,
				cardId: null,
				cardText: null,
				data: null
			}
		*/	
		case 'SAVE_GOAL_DATA':

			//console.log('SAVE_MODAL_DATA REDUCER, action: ', action);

			return {
				...state, 
				showModal: true,
				goalId: action.modal.goalId,
				cardText: action.modal.cardText,
				data: action.modal.formData,
				fields: action.modal.fields,
				userId: action.modal.userId
			}	

		case 'SAVE_SUCCESS':

			//console.log('MODAL REDUCER -- SAVE_SUCCESS action: ', action);

			return {
				...state, 
				showModal: false,
				goalId: null,
				cardText: null,
				data: null
			}
			
		case 'SAVE_FAIL':

			//console.log('MODAL REDUCER -- SAVE_FAIL action: ', action);

			return {
				...state, 
				showModal: false,
				goalId: null,
				cardText: null,
				data: null
			}

		case 'NEED_UPDATE_CURRENT_CLIENT':

			//console.log('1 REDUCER: NEED_UPDATE_CURRENT_CLIENT', state.isNeedUpdateCurrentClient);

			return {
				...state, 
				isNeedUpdateCurrentClient: action.payload.isNeedUpdateCurrentClient
			}	
			
			
		case 'STOP_UPDATE_CURRENT_CLIENT':

		//console.log('2 REDUCER: STOP_UPDATE_CURRENT_CLIENT', state.isNeedUpdateCurrentClient);

			return {
				...state, 
				isNeedUpdateCurrentClient: false
			}	
			
		default:
			return state
	}
}