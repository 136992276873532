import React from 'react'
import classes from './Input.module.css'

function isInvalid({valid, touched, shouldValidate}) {
	return !valid && shouldValidate && touched
}

const Input = props => {

	const inputType = props.type || 'text'
	const cls = [classes.Input]
	const htmlFor = `${inputType}-${Math.random()}`

	if (isInvalid(props)) {
		cls.push(classes.invalid)
	}

	return (
		<div className={cls.join(' ')}>

			<div className="spbHeader"><label htmlFor={htmlFor}>{props.label}</label></div>
			<div className="spbBody">
				<input 
					type={inputType}
					id={htmlFor}
					value={(props.value ? props.value : '')}
					disabled={props.disabled}
					onChange={props.onChange}
					placeholder={props.placeholder}
					className={( (props.name=='f_planTime' || props.name=='f_sum') && !props.isValid ? 'error' : '')}					
				/>
				{
				isInvalid(props) 
				? <span>{props.errorMessage || `Введите верное значение`}</span> 
				: null
				}
			</div>

		</div>
	)
}

export default Input