
const initialState = {
	token: null,
	userId: null,
	userRole: null,
	userPhoto: null,
	userName: null,
	authFail: false,
	listsById: null,
	cardsById: null
}

export default function authReducer(state = initialState, action) {

	//console.log('action: ', action);

	switch (action.type) {
		case 'AUTH_SUCCESS':

			//console.log('AUTH_SUCCESS: ', action.data);		

			return {
				...state, 
				token: action.data.token,
				userId: action.data.userId,
				userRole: action.data.userRole,
				userPhoto: action.data.photo,
				userName: action.data.name,
				authFail: false, 
				listsById: action.data.listsById,
				cardsById: action.data.cardsById
			}
		case 'AUTH_CLEAR_NOTICE':
			return {
				...state, 
				authFail: false, 
				listsById: null,
				cardsById: null
			}	
		case 'AUTH_FAIL':
			return {
				...state, 
				authFail: true, 
				listsById: null,
				cardsById: null
			}
		case 'AUTH_LOGOUT':
				return {
				  ...state, 
				  token: null, 
				  userId: null, 
				  userRole: null,
				  userPhoto: null, 
				  userName: null,
				  authFail: false, 
				  listsById: null,
				  cardsById: null
				}		
		default:
			return state
	}
}