//import "../styles/ClientGoalsList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class ClientGoalsList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  categories: []
		};
		//this.inputRef = React.createRef();
	}

	componentDidMount() {

		document.addEventListener("mousedown", this.handleClick, false);

		const { userId } = this.props;	
		
		const clientId = this.props.clientId;

		const taskData = {	
			userId,      
			clientId,
			isNaked: 1,
			nc_ctpl: 187
		}
		
		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
			this.setState({
				isLoaded: true,
				categories: result.data
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				isLoaded: true,
				error
			});
			}
		)	
		
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};

	setValue = (event, target) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-category'))

		let control_val = event.target.getAttribute('data-client') ? event.target.getAttribute('data-client') : event.target.parentElement.getAttribute('data-client');
		let control_text = event.target.getAttribute('data-name') ? event.target.getAttribute('data-name') : event.target.parentElement.getAttribute('data-name');

		// console.log('control_val: ', control_val);
		// console.log('control_text: ', control_text);
		// console.log('1 - event.target.closest(.cpFilter).querySelector(.clientName).innerText', event.target.closest('.cpFilter').querySelector('.clientName').innerText)
		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.closest('.cpFilter').querySelector('.clientName').innerText = control_text;

		//console.log('2 - event.target.closest(.cpFilter).querySelector(.clientName).innerText', event.target.closest('.cpFilter').querySelector('.clientName').innerText)

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		this.props.onChangeHandlerList(control_val, 'f_clientGoalId')

		this.hideDropDown()

		event.preventDefault()
	}

	toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	render() {

		const { error, isLoaded } = this.state;
		const { clientGoals } = this.props;	
		
		// console.log('ClientGoalsList state: ', this.state);
		console.log('ClientGoalsList props: ', this.props);

		let showValue = '- Без привязки к задаче -';		

		if (this.props.isEditGoal || this.props.client) { 

			//console.log('есть идентификатор клиента: ',this.props.client);

			clientGoals.map((clientGoal, index) => {
				//console.log('clientGoal.id - this.props.value: '+ clientGoal.id+' - '+this.props.value);
				if (clientGoal.id===this.props.value) {
					showValue = clientGoal.title
				}
			})
		}

		if (error) {
			return <ul><li key='0'>Ошибка загрузки данных по задачам клиента. (1)</li></ul>;
		} else if (!isLoaded) {
			return <ul><li key='0'>Загружаем данные о задачах... (2)</li></ul>;
		} else {

			return (
				<>
				<div className="spbHeader">Задача (из плана клиента)</div>
				<div className="spbBody">
					<div className="cpFilter">
						<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />	
						<div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}><div className="clientName">{showValue}</div></div>
						<div className="cpfMenuShell">
							<div className="cpfMenu">
								<ul>{
									clientGoals.length > 0	
									?
									clientGoals.map((clientGoal, index) => (
										<li key={index}>						
											<a href={'#clientGoalId_'+index} className={this.props.isEditGoal && clientGoal.id===this.props.value ? 'checkedClass' : ''} data-client={clientGoal.id} data-name={clientGoal.title} onClick={ event => this.setValue(event) }><span className="nowrap">{clientGoal.title}</span></a>						
										</li>
									))
									:
									<> Нет задач в плане клиента </>
								}</ul>
							</div>
						</div>
					</div>
				</div>
				</>
			);
			
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId
});  

export default connect(mapStateToProps)(ClientGoalsList);