import "../styles/Plan.css";

import React, { Component } from "react";
import { connect } from "react-redux";

import ClientList from "./ClientList";
import GoalList from "./GoalList";
import BugdetCalculation from "./BugdetCalculation";
import AddGoalPanelWrapper from "./AddGoalPanelWrapper";

class Plan extends Component {

	constructor(props) {
		super(props);

		/*this.state = {
		  error: null,
		  isLoaded: false,
		  goal: []
		};*/

		this.state = {
			updateClientList: false,
			isNeedUpdateGoalsList: false,
			isNeedUpdateBudget: false,
			goal: {},
			clientId: 0
		}

		this.fireEditGoal = this.fireEditGoal.bind(this);

	}	

	fireUpdateClientList = () => {
		this.setState({
			...this.state, updateClientList: true
		})
	}

	fireUpdateGoalsList = () => {

		//console.log('Plan - fireUpdateGoalsList');

		this.setState({
			...this.state, 
			isNeedUpdateGoalsList: true,
			isNeedUpdateBudget: true
		})
	}

	fireUpdateBudget = () => {
		//console.log('>>>>>>> Plan - fireUpdateBudget !!!!');

		this.setState({
			...this.state, 			
			isNeedUpdateBudget: true
		})
	}

	stopUpdateGoalsList = () => {

		//console.log('Plan - stopUpdateGoalsList');

		this.setState({
			...this.state, isNeedUpdateGoalsList: false
		})
	}

	stopUpdateBudget = () => {

		//console.log('Plan - stopUpdateBudget');

		this.setState({
			...this.state, isNeedUpdateBudget: false
		})
	}

	fireEditGoal = (goal) => {

		//console.log('PLAN (PARENT) FIRE CHILD FUNCTION, DATA: ', goal);

		let panel = document.getElementById('addTaskSidePanel');

		this.setState({
			...this.state, goal
		}, () => {

			let elements = document.querySelectorAll('.addGoalPanelWrapper textarea')

			let timer = 1;

			for (let i = 0; i < elements.length; i++) {
				let item = elements[i];
			 	item.style.height = 0
			}

			if (window.getComputedStyle(panel, null).width == '0px') {
				//console.log('WAY 1: ', window.getComputedStyle(panel, null).width);
				timer = 320;
			} else {
				//console.log('WAY 2');
			}

			// 	for (let i = 0; i < elements.length; i++) {
			// 		let item = elements[i];
			// 		let offset = item.offsetHeight - item.clientHeight;
			// 		item.style.height = item.scrollHeight + offset + 'px';
			// 	}	
					

			// } else {
				//console.log('WAY 2');

				setTimeout(function() {
					
					//console.log('WAY 2-1');
					//console.log('elem: ', elements)

					for (let i = 0; i < elements.length; i++) {
						let item = elements[i];
						let offset = item.offsetHeight - item.clientHeight;

						//console.info(item);
						//console.log('innerHTML: ',  item.innerHTML);
						

						//console.log('height: ',  window.getComputedStyle(item, null).height);	
						//console.log('offsetHeight: ',  item.offsetHeight);	
						//console.log('clientHeight: ',  item.clientHeight);	
						//console.log('scrollHeight: ',  item.scrollHeight);	

						//item.style.height = 0;
						//item.style.height = 'auto'

						//item.focus();
						//item.select();

						//console.log('height2: ',  item.height);	
						//console.log('offsetHeight2: ',  item.offsetHeight);	
						//console.log('clientHeight2: ',  item.clientHeight);	
						//console.log('scrollHeight2: ',  item.scrollHeight);	

						item.style.height = item.scrollHeight + offset + 'px';
					}

				}, timer);

			//}
			

			// elements.map(elem => {
			// 	let offset = elem.offsetHeight - elem.clientHeight;
			// 	elem.style.height = 0;
			// 	elem.style.height = elem.scrollHeight + offset + 'px';
			// 	return false	
			// })
		})

		//let panel = document.getElementById('addTaskSidePanel');

		if (!panel.classList.contains('active')) this.addPlanTask()
	}

	cancelEditGoal = () => {
		this.setState({
			...this.state, 
			isNeedUpdateGoalsList: true,
			isNeedUpdateBudget: true,
			goal: {}
		})
	}

	//state = {
	  //addingList: false,
	  //showModal: false,
	  
	  //isAuth: false
	//};

	addPlanTask = (newGoal) => {
		let panel = document.getElementById('addTaskSidePanel');
		if (!panel.classList.contains('active')) panel.classList.toggle('active');
		if (newGoal) this.cancelEditGoal()
		//console.log('addPlanTask Clicked, newGoal: ', newGoal);
		//console.log('state.goal: ', this.state.goal);

	}

	closeAddPlanTaskPanel = () => {
		let panel = document.getElementById('addTaskSidePanel');
		panel.classList.toggle('active');
		//console.log('Close addPlanTask Clicked');
	}

	changeHandler = () => {	

		this.setState({
			//...this.state, isAuthNotice: true
		})	

		/*this.props.auth(
			this.state.formControls.email.value,
			this.state.formControls.password.value,
			true
		)*/	
	}	

	componentDidMount() {
		window.addEventListener('scroll', event => this.handleScroll(event), true);

		//console.log('+ 5 planClients: ', this.props.planClients)

		if (this.props.planClients && this.props.planClients[0] && this.props.planClients[0].id) {

			//console.log('++ 6 planClients: ', this.props.planClients[0].id)

			this.setState({
				...this.state, 
				clientId: this.props.planClients[0].id,
				isNeedUpdateGoalsList: true
			})
		}

	}

	componentWillUnmount() {
		window.removeEventListener('scroll', event => this.handleScroll(event));
	}

	static getDerivedStateFromProps(props, state) {
		if ((props.planClients && props.planClients.length > 0) && state.clientId === 0) {

			//console.log('7: ', props.planClients);

			const { dispatch } = props;

			dispatch({
				type: "NEED_UPDATE_CURRENT_CLIENT",
				payload: {
					isNeedUpdateCurrentClient: props.planClients[0]
				}
			});

			return {
				clientId: props.planClients[0].id,
				isNeedUpdateGoalsList: true,
				isNeedUpdateBudget: true
			}
		  }
	  
		  return null;
	}

	handleScroll = event => {
		let panel = document.getElementById('addTaskSidePanel');
		
		//console.log('scroll: ', window.scrollY + '-' + document.body.scrollTop);		
		if (window.scrollY > 58){
			if (!panel.classList.contains('fixed')) panel.classList.add('fixed');
		} else {
			panel.classList.remove('fixed');
		}
	}

	getClientGoals = (client) => {
		//console.log('+++ getClientGoals, clientId: ', client.id)

		const { dispatch } = this.props;

		this.setState({
			...this.state, 
			clientId: client.id,
			isNeedUpdateGoalsList: true
		})

		dispatch({
			type: "NEED_UPDATE_CURRENT_CLIENT",
			payload: {
				isNeedUpdateCurrentClient: client
			}
		});
	}

	render() {
		const { plan } = this.props;

		//const { addingList } = this.state;
		//console.log('PLAN PROPS: ',  this.props);
		//console.log('++ 7 PLAN STATE: ', this.state);
		//console.log('++ 7 PLAN PROPS: ', this.props);
		//console.log('++ 7 PLAN STATE: ', this.state);	   
		//console.log('this.props.showModal: ', this.props.showModal);
		//console.log('this.state.showModal: ', this.state.showModal);  
	
		return (
	
		  <div className="Plan">		
	
			<div className="tmContent">

				<div className="tmcWrapper">

					<div className="planCell">

						<div className="tmLeft">
							<div className="tmPlanClients">
								<div className="tmpcHeader">Клиент</div>
								<div className="tmpcBody">
									<ClientList 
										getClientGoals={this.getClientGoals}
										clientId={this.state.clientId}
										id="1"
									/>														
								</div>
							</div>
						</div>

						<div className="tmCenter tmCenterPlan">
							<div className="goalAddButton"><button data-value="#addGoal" onClick={() => this.addPlanTask(true)} title="Показать панель добавления">Добавить задачу</button></div>
							<GoalList
								isNeedUpdateGoalsList={this.state.isNeedUpdateGoalsList}
								stopUpdateGoalsList={this.stopUpdateGoalsList}
								fireUpdateBudget={this.fireUpdateBudget}
								fireEditGoal={this.fireEditGoal}
								clientId={this.state.clientId}	
								clientData={this.props.planClients.filter(client => (client.id==this.state.clientId))[0]}
								isHourlyRate={this.props.isHourlyRate}
								isFix={this.props.isFix} 
								isRepeat={this.props.isRepeat}             
								goalMonthFilter={this.props.goalMonthFilter}
							/>
						</div>

						<div className="tmRight">
							<BugdetCalculation
								isNeedUpdateBudget={this.state.isNeedUpdateBudget}
								stopUpdateBudget={this.stopUpdateBudget}
								clientId={this.state.clientId}
								goalMonthFilter={this.props.goalMonthFilter}
							/>					
						</div>

					</div>

					<div className="tmSidePanel" id="addTaskSidePanel">

						<AddGoalPanelWrapper 
							fireUpdateGoalsList={this.fireUpdateGoalsList}						
							goalData={this.state.goal}
							cancelEditGoal={this.cancelEditGoal}	
							clientId={this.state.clientId}
						/>

					</div>

				</div>


				
			</div>	
			
					

			{/*}						
			<Modal
			  show={this.props.showModal}
			  onHide={this.hideModal}
			  name={this.props.cardText}
			  data={this.props.formData}
			  userId={this.props.userId}
			  userRole={this.props.userRole}
			  userPhoto={this.props.userPhoto}
			  userName={this.props.userName}
			  cardId={this.props.cardId}
			/>
			*/}
	
		  </div>
	
		);
	}
} 

const mapStateToProps = (state, ownProps) => ({ 
	//board: state.board,
	//lists: state.listsById,
	userId: state.auth.userId,
	userRole: state.auth.userRole,
	userPhoto: state.auth.userPhoto,
	userName: state.auth.userName,
    currentMonth: state.app.currentMonth,
    currentYear: state.app.currentYear,
	//showModal: state.modal.showModal,
	//cardId: state.modal.cardId,
	//cardText: state.modal.cardText,
	//formData: state.modal.data
  });
  
  export default connect(mapStateToProps)(Plan);