
const initialState = {
	amIManager: false, 
	amIExecutor: false,
	amIInvolved: false,
	taskClient: 0,
	currentMonth: new Date().getMonth(),
	currentYear: new Date().getFullYear(),
	currentWorkerId: 0,
	currentWorkerHours: 0,
	currentClientId: 0
}

export default function appReducer(state = initialState, action) {

	//console.log('UPDATE_APP_FILTER action: ', action);

	switch (action.type) {
		case 'UPDATE_APP_FILTER':

			//console.log('UPDATE_APP_FILTER: ', action.data);		

			return {
				...state, 
				amIManager: action.data.amIManager, 
				amIExecutor: action.data.amIExecutor,
				amIInvolved: action.data.amIInvolved,
				taskClient: action.data.taskClient,
				currentClientId: action.data.taskClient
			}

		case 'UPDATE_APP_MONTH':

			//console.log('UPDATE_APP_FILTER: ', action.data);		

			return {
				...state, 				
				currentMonth: action.data.currentMonth
			}
			
		case 'UPDATE_APP_YEAR':

			//console.log('UPDATE_APP_FILTER: ', action.data);		

			return {
				...state, 				
				currentYear: action.data.currentYear
			}
			
		case 'UPDATE_APP_WORKER':

			console.log('UPDATE_APP_WORKER: ', action.data);		

			return {
				...state, 				
				currentWorkerId: action.data.currentWorkerId,
				currentWorkerHours: action.data.currentWorkerHours
			}	
		
		default:
			return state
	}
}