import "../styles/MonthListNew.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { updateYear } from '../store/actions/app'

//const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

class YearListNew extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  currentYear: new Date().getFullYear(),
		  yearsArray: []
		};
	}

	componentDidMount() {

		const { userId } = this.props;	
		
		document.addEventListener("mousedown", this.handleClick, false);
		
		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 209
		}	
		
		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {

				//console.log('YEARS: ', result);

				this.setState({
					isLoaded: true,
					yearsArray: result.data.years
				});
				},
				// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
				// чтобы не перехватывать исключения из ошибок в самих компонентах.
				(error) => {
				this.setState({
					isLoaded: true,
					error
				});
			}
		)
		

	}
	
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	toggleDropDown = event => {
		event.stopPropagation()
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	
	setValue = (event, target) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-month'))

		//let control_val = event.target.getAttribute('data-year')
		let control_text = event.target.innerText

		//console.log('setValue, year: ', control_val);
		//console.log('control_text: ', control_text);

		this.setState({
			...this.state,
			currentYear: +control_text      
		},
		() => {
			this.props.updateYear(control_text)
		})

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfSelected').innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		//this.props.onChangeHandlerList(control_val, 'f_planMonth')
		this.hideDropDown();
		event.preventDefault();	
	}

	setMonthValueChange = (event, target) => {
		//console.log('setMonthValueChange === EVENT: ', event.target);
	}
		

	render() {

		const { error, isLoaded, currentYear } = this.state;	
		
		//const cls = []

		//let showValue = 'Выбрать год';

		//console.log('currentMonth - PROPS: ', this.props);

		let thisYear = this.props.currentYear ? this.props.currentYear : (this.state.currentYear ? this.state.currentYear : new Date().getFullYear())
		let realYear = new Date().getFullYear()	
		
		
		
		//console.log('currentYear - STATE: ', this.state);
		/*
		if (this.state.realYear && this.state.yearsArray) {
			this.state.yearsArray.map((year) => {

				//console.log('this.state.currentMonth: ', this.state.currentMonth);
				//console.log('index: ', index);

				if (+this.state.currentYear===year) {
					//console.log('match: ', index);
					showValue = year
				}
			})
		}
		*/
		

		//if (error) {
		//	return <div className={cls.join(' ')} key='0'>Ошибка загрузки данных по месяцам. (1)</div>;
		//} else if (!isLoaded) {
		//	return <div className={cls.join(' ')} key='0'>Загружаем данные по месяцам... (2)</div>;
		//} else {

		/*
<div className={cls.join(' ')}>
					<div className="spbHeader">Выбрать месяц</div>
					<div className="spbBody">
</div>
				</div>
		*/

			return (
				
				<div className="cpFilter newFilter">
					<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />
					<div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>{thisYear}</div>
					<div className="cpfMenuShell">
						<div className="cpfMenu">
							<ul>{
								this.state.yearsArray.map((year, index) => (
									<li key={index}>						
										<a href={'#yearId_'+index} className={realYear && +realYear===+year ? 'checkedClass' : ''} onClick={this.setValue}>{year}</a>						
									</li>
								))
							}</ul>
						</div>
					</div>
				</div>
					
			);
			
		//}			
		
	}

}

function mapDispatchToProps(dispatch) {
	return {
		updateYear: (currentYear) => dispatch(updateYear(currentYear)),
   		dispatch: dispatch
	}
}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	currentYear: state.app.currentYear,
});  

export default connect(mapStateToProps, mapDispatchToProps)(YearListNew);