import "../styles/BudgetCalculation.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class BudgetCalculation extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  budget: [],
		  clientId: 0,
		  month: false
		};
	}

	numberFormatBudget = str => {
		//console.log('STR: ', str);

		str = str.toString();

		if (str) {		
			return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
		} else {
			return str
		}
	}

	updateBudget = () => {

		const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 186
		}
		
		if (this.state.clientId !== 0) {
			taskData.clientId = this.state.clientId
		}

		//console.log('1 STATE: ', this.state);

		if (!!this.state.month) {
			
			taskData.month = this.state.month.getFullYear() + '-' + ((this.state.month.getMonth()+1)<10 ? '0'+(this.state.month.getMonth()+1) : (this.state.month.getMonth()+1)) + '-' + (this.state.month.getDate()<10 ? '0'+this.state.month.getDate() : this.state.month.getDate())
		
		}

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/clients/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					...this.state,
					isLoaded: true,
					budget: result.data
				});

				this.props.stopUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					...this.state,
					isLoaded: true,
					error
				});

				this.props.stopUpdateBudget()
			}
		)
	}	

	componentDidMount() {

		this.updateBudget()

		this.setState({
			...this.state,
			clientId: this.props.clientId
		});

	}	

	componentDidUpdate() {

		//console.log('componentDidUpdate - this.props.isNeedUpdateBudget: ', this.props.isNeedUpdateBudget);

		if (this.props.isNeedUpdateBudget) {
			//console.log('this.props.isNeedUpdateBudget FIRED ', this.props.isNeedUpdateBudget);
			this.updateBudget()
			//this.props.stopUpdateGoalsList()
			
		}
		//return null
	}

	static getDerivedStateFromProps(props, state) {
		if (props.clientId !== state.clientId) {

			//this.updateBudget()

			return {
				clientId: props.clientId,
				month: props.month,
				isNeedUpdateBudget: true
			}
		}

		if (props.goalMonthFilter && props.goalMonthFilter !== state.month) {

			//this.updateBudget()

			return {				
				month: props.goalMonthFilter,
				isNeedUpdateBudget: true
			}
		}

		//console.log('BUDGET STATE, ', state);
		//console.log('BUDGET PROPS, ', props);
	  
		return null;
	}

	render() {

		//console.log('BUDGET_CALC STATE, ', this.state);
		//console.log('BUDGET_CALC PROPS, ', this.props);

		const { error, isLoaded, budget } = this.state;

		if (error) {
			return <div className="tmbbRow" key="4">Ошибка загрузки данных по бюджету. (1)</div>;
		} else if (!isLoaded) {
			return <div className="tmbbRow" key="5">Загружаем данные о бюджете... (2)</div>;
		} else {
			return (
				!!this.props.isSpec
				?
				budget.map((budgetData, index) => (
					<div className="tmBudgetBox" key="tmbb6">
						<div className="tmbbRow strong" key="0">
							<div className="tmbbrName">Бюджет макс.</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.commonClientBudget)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow strong" key="1">
							<div className="tmbbrName">План</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.commonPlanSum)} р.</div>
							<div className={'tmbbrDifference' + (budgetData.commonClientBudget-budgetData.commonPlanSum > 0 ? ' redText' : '')}>{(budgetData.commonClientBudget-budgetData.commonPlanSum > 0 ? '+' : '') + this.numberFormatBudget(budgetData.commonClientBudget-budgetData.commonPlanSum)} р.</div>
						</div>
						<div className="tmbbRow strong" key="2">
							<div className="tmbbrName">Факт</div>
							<div className="tmbbrSum">{this.numberFormatBudget((budgetData.usedTimeFixed + budgetData.usedTimeProjected)*2000)} р.</div>
							<div className={'tmbbrDifference' + ((budgetData.usedTimeFixed + budgetData.usedTimeProjected)*2000-budgetData.commonPlanSum > 0 ? ' redText' : '')}>{((budgetData.usedTimeFixed + budgetData.usedTimeProjected)*2000-budgetData.commonPlanSum > 0 ? '+' : '') + this.numberFormatBudget((budgetData.usedTimeFixed + budgetData.usedTimeProjected)*2000-budgetData.commonPlanSum)} р.</div>
						</div>
						<div className="tmbbRow" key="3">
							<div className="tmbbrName">Факт фикс</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.usedTimeFixed*2000)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow" key="4">
							<div className="tmbbrName">Факт проект</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.usedTimeProjected*2000)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow strong" key="5">
							<div className="tmbbrName">Факт (корр.)</div>
							<div className="tmbbrSum">{this.numberFormatBudget((budgetData.usedTimeCorrected))} р.</div>
							<div className={'tmbbrDifference' + (budgetData.usedTimeCorrected-budgetData.commonClientBudget > 0 ? ' redText' : '')}>{(budgetData.usedTimeCorrected-budgetData.commonClientBudget > 0 ? '+' : '') + this.numberFormatBudget((budgetData.usedTimeCorrected)-budgetData.commonClientBudget)} р.</div>
						</div>
						<div className="tmbbRow strong" key="6">
							<div className="tmbbrName">Аванс оплачен</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.advancePayment)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
					</div>	
				))
				:
				budget.map((budgetData, index) => (
					<div className="tmBudgetBox" key="tmbb6">
						<div className="tmbbRow strong" key="0">
							<div className="tmbbrName">Бюджет макс.</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.commonClientBudget)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow strong" key="1">
							<div className="tmbbrName">План</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.commonPlanSum)} р.</div>
							<div className={'tmbbrDifference' + (budgetData.commonClientBudget-budgetData.commonPlanSum > 0 ? ' redText' : '')}>{(budgetData.commonClientBudget-budgetData.commonPlanSum > 0 ? '+' : '') + this.numberFormatBudget(budgetData.commonClientBudget-budgetData.commonPlanSum)} р.</div>
						</div>
						<div className="tmbbRow" key="2">
							<div className="tmbbrName">План фикс</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.planFixed)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow" key="3">
							<div className="tmbbrName">План проект</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.planProjected)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
						<div className="tmbbRow strong" key="4">
							<div className="tmbbrName">Аванс оплачен</div>
							<div className="tmbbrSum">{this.numberFormatBudget(budgetData.advancePayment)} р.</div>
							<div className="tmbbrDifference"></div>
						</div>
					</div>	
				))			
			);
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId
});  

export default connect(mapStateToProps)(BudgetCalculation);