import "../styles/App.css";

import React, { Component } from "react";
import Board from "./Board";
import Plan from "./Plan";
import Spec from "./Spec";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Auth from "./Auth";
import { connect } from 'react-redux'
import axios from "axios";

import { app } from '../store/actions/app'
import { updateTasksData } from "../store/actions/task";
import TaskTable from "./TaskTable";
import MonthListNew from "./MonthListNew";
import YearListNew from "./YearListNew";
import WorkerListNew from "./WorkerListNew";

import { updateWorkerId } from '../store/actions/app'


//let isAuth = false;

class App extends Component { 
  
  constructor(props) {
		super(props);

		this.state = {	
      users: [],	
      
      isHourlyRate: false, 
      isFix: false, 
      isRepeat: false,

      goalMonthFilter: false,

		  viewId: 1,
      amIManager: false,
      amIExecutor: false,
      amIInvolved: false,

      taskClient: 0,
      clients: [],
      showRows: false,
      switchView: this.switchView,
      currentUserRole: null,

      currentWorkerId: 0,
      currentWorkerHours: 0
		  /*
        viewId:
        0 - board (User tasks) DEFAULT
        1 - plan (Admin goals)
        2 - spec (Admin plan-fact view)
      */
		}

  } 

  /*
  componentDidMount() {      
    const { isAuth } = this.state;
    if (!isAuth) {
      window.location.href = '/auth'
    }
  }*/

  screenSwitcher = () => {

    // if (!this.props.isAuthenticated) {
    //   return <Auth />
    // } else {

      /*
      userRole:
      1 - Admin (views 0-2)
      2 - User (view 0)
      */

      //console.log('++ 4 screenSwitcher: ', this.props.userRole);

      if (this.props.userRole === 2) {
        return <Board />
      } else {
        switch (this.state.viewId) {
          case 0:
            return <Board
                    // amIManager={this.state.amIManager}
                    // amIExecutor={this.state.amIExecutor}
                    // amIInvolved={this.state.amIInvolved}
                   />
            //break;
          case 1:
            return <Plan 
                      isHourlyRate={this.state.isHourlyRate}
                      isFix={this.state.isFix} 
                      isRepeat={this.state.isRepeat}             
                      goalMonthFilter={this.state.goalMonthFilter}
                      planClients={this.state.clients}
                   />
            //break;
          case 2:
            return <Spec
                      isHourlyRate={this.state.isHourlyRate}
                      isFix={this.state.isFix} 
                      isRepeat={this.state.isRepeat}             
                      goalMonthFilter={this.state.goalMonthFilter}
                      planClients={this.state.clients}
                    />

          //   break;
          default:
            return <Plan />
        }
        
      }
    // }
  
  }

  screenSwitcherRouter = () => {    
      /*
      if (this.props.userRole === 2) {
        return <Board />
      } else {
        switch (this.state.viewId) {
          case 0:
            return <Board  />            
          case 1:
            return <Plan 
                      isHourlyRate={this.state.isHourlyRate}
                      isFix={this.state.isFix} 
                      isRepeat={this.state.isRepeat}             
                      goalMonthFilter={this.state.goalMonthFilter}
                      planClients={this.state.clients}
                   />           
          case 2:
            return <Spec
                      isHourlyRate={this.state.isHourlyRate}
                      isFix={this.state.isFix} 
                      isRepeat={this.state.isRepeat}             
                      goalMonthFilter={this.state.goalMonthFilter}
                      planClients={this.state.clients}
                    />         
          default:
            return <Plan />
        }
        
      }
      */

      <BrowserRouter>
        <Routes>
          <Route path="/plan" component={<Plan 
                        isHourlyRate={this.state.isHourlyRate}
                        isFix={this.state.isFix} 
                        isRepeat={this.state.isRepeat}             
                        goalMonthFilter={this.state.goalMonthFilter}
                        planClients={this.state.clients}
                    />} />
          <Route path="/spec" component={<Spec
                        isHourlyRate={this.state.isHourlyRate}
                        isFix={this.state.isFix} 
                        isRepeat={this.state.isRepeat}             
                        goalMonthFilter={this.state.goalMonthFilter}
                        planClients={this.state.clients}
                      />} />
          <Route path="/board" component={<Board  />} />
        </Routes>
      </BrowserRouter>  
  }

  switchView = (event, viewId) => {
    if (event) {
      event.preventDefault()
    }  
    this.setState({
			viewId
		})
  }

  toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

  filterMonth = (month) => {
    //console.log('month: ', month);
    //console.log('this.state.goalMonthFilter: ', this.state.goalMonthFilter);

    if (this.state.goalMonthFilter.toString() === month.toString()) {

      this.setState({
        ...this.state,
        goalMonthFilter: false,
        isNeedUpdateBudget: true      
      })

    } else {
    
      this.setState({
        ...this.state,
        goalMonthFilter: month,
        isNeedUpdateBudget: true      
      })

    }

  }

  HourlyRateToggle = () => {

    //console.log('!this.state.isHourlyRate: ', !this.state.isHourlyRate);

    let hourlyRateValue = !this.state.isHourlyRate
    
    this.setState({
      ...this.state,
      isHourlyRate: hourlyRateValue      
    })
  }

  FixToggle = () => {

    //console.log('!this.state.isFix: ', !this.state.isFix);

    let fixValue = !this.state.isFix
    
    this.setState({
      ...this.state,
      isFix: fixValue      
    })
  }

  RepeatToggle = () => {

    //console.log('!this.state.isRepeat: ', !this.state.isRepeat);

    let repeatValue = !this.state.isRepeat
    
    this.setState({
      ...this.state,
      isRepeat: repeatValue      
    })
  }

  /*
  componentDidMount() {

    const { userId } = this.props;

    const userListData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 192
		}		

		const params = (Object.entries(userListData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/users/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					users: result.data.error ? this.state.users : result.data
				});

				
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					users: this.state.users
				});				
			}
		)
    
  }
*/
  toggleManagerDropDown = () => {
		let amIManagerValue = !this.state.amIManager

    this.setCurrentWorkerId(0)
    
    this.setState({
      ...this.state,
      amIManager: amIManagerValue,
      amIExecutor: false,
      amIInvolved: false     
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        this.state.taskClient
        ),
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': this.state.taskClient,            
            'currentWorkerId': 0,
            'currentWorkerHours' : 0
          }
        ),
        this.props.updateWorkerId(0)
      )     
    )
    this.hideDropDown()
	}

  toggleExecutorDropDown = () => {
		let amIExecutorValue = !this.state.amIExecutor

    this.setCurrentWorkerId(0)
    
    this.setState({
      ...this.state,
      amIManager: false,
      amIExecutor: amIExecutorValue,
      amIInvolved: false     
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        this.state.taskClient
        ),
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': this.state.taskClient,            
            'currentWorkerId': 0,
            'currentWorkerHours' : 0
          }
        ),
        this.props.updateWorkerId(0)
      )     
    )
    this.hideDropDown()
	}

  toggleInvolvedDropDown = () => {
		let amIInvolvedValue = !this.state.amIInvolved

    this.setCurrentWorkerId(0)
    
    this.setState({
      ...this.state,
      amIManager: false,
      amIExecutor: false,
      amIInvolved: amIInvolvedValue           
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        this.state.taskClient
        ),        
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': this.state.taskClient,            
            'currentWorkerId': 0,
            'currentWorkerHours' : 0
          }
        ),
        this.props.updateWorkerId(0)
      )     
    )
    this.hideDropDown()
	}

  switchUsersOff = () => {

    this.setCurrentWorkerId(0)

    this.setState({
      ...this.state,
      amIManager: false,
      amIExecutor: false,
      amIInvolved: false           
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        this.state.taskClient
        ),        
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': this.state.taskClient,            
            'currentWorkerId': 0,
            'currentWorkerHours': 0
          }
        ),
        this.props.updateWorkerId(0)
      )     
    )
    this.hideDropDown()
  }

  componentDidMount() {

    const { userId } = this.props;	
    
    //switchView = (event, viewId)

    //console.log('cDM props: ', this.props);

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 184
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/clients/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {

        //console.log('++ 1 GET CLIENTS BY QUERY')

			this.setState({
        ...this.state,
				isLoaded: true,
				clients: result.data
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				isLoaded: true,
				error
			});
			}
		)

		document.addEventListener("mousedown", this.handleClick, false);

      //console.log('++ 2 APP componentDidMount');

  } 
  
  static getDerivedStateFromProps(props, state) {
    //console.log("APP -> getDerivedStateFromProps(), props: ", props);

    

    return null;
  }  

  componentDidUpdate() {


    //console.log('APP-> componentDidUpdate,  this.props.userRole: ', this.props.userRole);

    if (this.props.userRole && !this.state.currentUserRole) {

      if (this.props.userRole==2) {
        this.setState({
          viewId: 0,
          currentUserRole: this.props.userRole
        })
      }

      if (this.props.userRole==1 || this.props.userRole==3) {
        this.setState({
          viewId: 1,
          currentUserRole: this.props.userRole
        })
      }

      //if (props.userRole==2) {
        
      //}
    }
  }


  componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

  handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	};

  setClient = (id) => {
    console.log('THIS CLIENT ID: ', id)
    console.log('this.props.currentWorkerId: ',this.props.currentWorkerId);

    // if (id === this.state.taskClient) {
    //   id = 0
    // }

    this.setState({
      ...this.state,
      taskClient: id           
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        id
        ),        
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': id,            
            'currentWorkerId': this.props.currentWorkerId,
            'currentWorkerHours': this.props.currentWorkerHours
          }
        )
      )     
    )
    this.hideDropDown()

  }

  setCurrentWorkerId = (currentWorkerId, currentWorkerHours) => {
    this.setState({
      ...this.state,
      amIManager: false,
      amIExecutor: false,
      amIInvolved: false,
      currentWorkerId: currentWorkerId,
      currentWorkerHours: currentWorkerHours           
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        this.state.taskClient
        ),        
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': this.state.taskClient,
            'currentWorkerId': currentWorkerId,
            'currentWorkerHours': currentWorkerHours
          }
        )
      )     
    )
    this.hideDropDown()


  }

  switchClientsOff = () => {
    this.setState({
      ...this.state,
      taskClient: 0           
    },
    () => (
        this.props.app(
        this.state.amIManager,
        this.state.amIExecutor,
        this.state.amIInvolved,
        0
        ),        
        this.props.updateTasksData(
          this.props.userHash, 
          {
            'amIManager': this.state.amIManager,
            'amIExecutor': this.state.amIExecutor,
            'amIInvolved': this.state.amIInvolved,
            'taskClient': 0,            
            'currentWorkerId': this.props.currentWorkerId,
            'currentWorkerHours': this.props.currentWorkerHours
          }
        )
      )     
    )
    this.hideDropDown()
  } 
  
  


  setCurrentMonth = () => {
    //console.log('month: ', month);
    //console.log('this.state.goalMonthFilter: ', this.state.goalMonthFilter);
    
      this.setState({
        ...this.state,
        goalMonthFilter: false,
        isNeedUpdateBudget: true      
      })   

  }







  render() { 
    
    //console.log('++ 3 APP, state: ', this.state);

    const viewId = this.state.viewId
    const { clients } = this.state;

   // const userId =  this.props
    //const { users } = this.state;
    //const participants = []

    let viewName

    switch (viewId) {
      case 0: viewName = 'Задачи'
        break;
      case 1: viewName = 'План заказчика'
        break;
      case 2: viewName = 'Спецификация'
        break;  
      default: viewName = ''  
    }

    let filterMonths = []
    let current_month = new Date().getMonth()
    let current_year = new Date().getFullYear()

    let months_array = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    for (let i=-1; i<6; i++) {
      let current_date = new Date(current_year, current_month-i, 1)
      let local_month = months_array[current_date.getMonth()]
      let local_year = current_date.getFullYear().toString().substr(-2)      
      filterMonths.push({name: local_month+'‘'+local_year, value: new Date(current_date.getFullYear(), current_date.getMonth(), 1)})
    }

    if (!this.state.goalMonthFilter) this.filterMonth(filterMonths[1].value)
    
    if (this.props.isAuthenticated) {

      return (

        <div className={"App"+(viewId==0 ? ' greenApp' : '')}>

          <div className="tmHeader">

            <div className="tmhWrapper">

                <div className="tmLeft">
                  <div className="menuContainer">
                    <div className="mcSelected">{viewName}</div>
                    <div className="mcMenu">
                    <ul>
                      {
                      (this.props.userRole && (this.props.userRole==1 || this.props.userRole==3))
                      ?
                      <>
                      <li key="tmh_1"><a href="#tasks" className={viewId==0 ? 'active' : null} onClick={event => this.switchView(event, 0)}>Задачи</a></li>
                      <li key="tmh_2"><a href="#plan" className={viewId==1 ? 'active' : null} onClick={event => this.switchView(event, 1)}>План заказчика</a></li>
                      <li key="tmh_3"><a href="#specification" className={viewId==2 ? 'active' : null} onClick={event => this.switchView(event, 2)}>Спецификация</a></li>
                      </>
                      :
                      null
                      }
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="tmCenter">
                  <div className="contentPanels">

                    {
                    viewId===0
                    ?
                    <div className="cpFilter">

                      <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Клиент{(this.state.taskClient > 0 ? ': '+clients.filter(oneClient => (oneClient.id===this.state.taskClient))[0].name : '')}</div>

                      <div className="cpfMenuShell">
                        <div className="cpfMenu">					  
                          <div className="cpfmHeader canHover" onClick={this.switchClientsOff}>Все клиенты</div>
                          <div className="cpfmBody">                     
                            
                            <div className="cpfmBodyBlock">                        
                              <div className="cpfmBodyBlockRow nopadding">
                                <ul className="flexClass">
                                {
                                clients.map((client, index) => (
                                  <li key={index}>						
                                    <a href={'#clientId_'+index} className={client.id===this.state.taskClient ? 'checkedClass' : ''} onClick={() => this.setClient(client.id)}>
                                      <span className="clientLogo"><img src={client.logo} alt="" /></span>
                                      <span className="clientName">{client.name}</span>
                                    </a>						
                                  </li>
                                ))
                                }
                                </ul>                            
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>             
                    </div>
                    :
                    <></>
                    }

                            {
                            viewId===0
                            ?                        
                            <>  
                              <div className="cpFilter">
                                <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)} >Все задачи{(this.state.amIManager ? ' | Я постановщик' : '')}{(this.state.amIExecutor ? ' | Я исполнитель' : '')}{(this.state.amIInvolved ? ' | Я участник' : '')}</div>
                                <div className="cpfMenuShell">
                                  <div className="cpfMenu">					  
                                    <div className="cpfmHeader canHover" onClick={this.switchUsersOff}>Все задачи</div>
                                    <div className="cpfmBody">
                                      <div className="cpfmBodyBlock">                        
                                        <div className="cpfmBodyBlockRow">
                                          <ul className="boardMenuList">
                                            <li key="viewId_0_0" className={this.state.amIManager ? 'active' : ''} onClick={this.toggleManagerDropDown}>Я постановщик</li>
                                            <li key="viewId_0_1" className={this.state.amIExecutor ? 'active' : ''} onClick={this.toggleExecutorDropDown}>Я исполнитель</li>
                                            <li key="viewId_0_2" className={this.state.amIInvolved ? 'active' : ''} onClick={this.toggleInvolvedDropDown}>Я участник</li>
                                          </ul>                            
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>  

                              {
                                this.props.userRole && this.props.userRole != 2 
                                ? 
                                <>
                                <span className="condition_or">||</span>
                                <WorkerListNew 
                                  setCurrentWorkerId={this.setCurrentWorkerId}
                                />
                                </>
                                :
                                <></>
                              }    

                            </>
                            :
                            <></>
                            }

                            {
                            viewId===1
                            ?

                            <div className="cpFilter">
                              <div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>Все задачи</div>
                              <div className="cpfMenuShell">
                                <div className="cpfMenu">					  
                                  <div className="cpfmHeader canHover" onClick={this.switchUsersOff}>Все задачи</div>
                                  <div className="cpfmBody">
                                    <div className="cpfmBodyBlock">                        
                                      <div className="cpfmBodyBlockRow">
                                        <div className="menuCheckbox">
                                          <input type="checkbox" name="isHourlyRate" value={+this.state.isHourlyRate} id="menuCheckbox_1" onChange={this.HourlyRateToggle} />
                                          <label htmlFor="menuCheckbox_1">											
                                            <span className="menuCheckboxLabelText">С оплатой по часам</span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="cpfmBodyBlockRow">
                                        <div className="menuCheckbox">
                                          <input type="checkbox" name="isFix" value={+this.state.isFix} id="menuCheckbox_2" onChange={this.FixToggle} />
                                          <label htmlFor="menuCheckbox_2">
                                            <span className="menuCheckboxLabelMark fixedSum">F</span>
                                            <span className="menuCheckboxLabelText">Фикс</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="cpfmBodyBlock">
                                      <div className="cpfmBodyBlockRow">
                                        <div className="menuCheckbox">
                                          <input type="checkbox" name="isRepeat" value={+this.state.isRepeat} id="menuCheckbox_3" onChange={this.RepeatToggle} />
                                          <label htmlFor="menuCheckbox_3">
                                            <span className="menuCheckboxLabelMark repeatGoal">R</span>
                                            <span className="menuCheckboxLabelText">Периодические</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                            :
                            <></>
                            }

                    {
                      this.state.viewId===1 || this.state.viewId===2
                      ?
                      <>                      
                      <YearListNew />
                      <MonthListNew />
                      </>
                      :
                      null
                    }

                    </div>

                </div>

                <div className="tmRight">
                    <div className="userBlock">
                      <div className="ubName">{this.props.userName}</div>
                      <div className="ubImg" style={{backgroundImage: "url(" + this.props.userPhoto + ")"}}></div>
                    </div>
                </div>

              </div>  

            </div>            

          {
            /*
          }
          <nav className="nav">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/auth">Авторизация</a>
              </li>
            </ul>
          </nav>
          */
        }

          {/*<div className="Header">Apriori Task Manager, userRole: {this.props.userRole ? this.props.userRole : 'not authenticated'}</div>*/}     
            

          {
          /*(this.props.isAuthenticated ? <Board /> : <Auth />)*/
          }

          {this.screenSwitcher()}
          {/*this.screenSwitcherRouter()*/}      


          {
          this.state.viewId===0
          ?               
          <TaskTable 
            setCurrentWorkerId={this.setCurrentWorkerId}
            clients={clients}
            setClient={this.setClient}
            toggleDropDown={this.toggleDropDown}
            taskClient={this.state.taskClient}
            switchClientsOff={this.switchClientsOff}
            currentYear={this.props.currentYear}
            currentMonth={this.props.currentMonth}
          />
          :
          <></>  
          }

          {
          /*
            <Routes>          
              <Route path="/" element ={<Board />} />
              <Route path="/auth" element ={<Auth />} />
            </Routes>
        */
        }                

            { /* <Board /> */ }
        </div>
      );

    } else {
      return <div className="App"><Auth /></div>
    }
   
  }

}

function mapDispatchToProps(dispatch) {
	return {
		app: (amIManager, amIExecutor, amIInvolved, taskClient) => dispatch(app(amIManager, amIExecutor, amIInvolved, taskClient)),   
    updateTasksData: (userHash, filterData) => dispatch(updateTasksData(userHash, filterData)),	 
    updateWorkerId: (currentWorkerId, currentWorkerHours) => dispatch(updateWorkerId(currentWorkerId, currentWorkerHours)),
    dispatch: dispatch
	}
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token,
    userRole: state.auth.userRole,
    userId: state.auth.userId,	
	  userPhoto: state.auth.userPhoto,
	  userName: state.auth.userName,
    userHash: state.auth.token,
    currentWorkerId: state.app.currentWorkerId,
    currentWorkerHours: state.app.currentWorkerHours,
    currentClientId: state.app.currentClientId,
    currentYear: state.app.currentYear,
    currentMonth: state.app.currentMonth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
