//import "../styles/MonthList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

class MonthList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  months: []
		};
	}

	componentDidMount() {

		const { userId } = this.props;		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 188
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
			this.setState({
				isLoaded: true,
				months: result.data
			});
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
			this.setState({
				isLoaded: true,
				error
			});
			}
		)

	}	

	setValue = (event, target) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-month'))

		let control_val = event.target.getAttribute('data-month');
		let control_text = event.target.innerText

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfSelected').innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		this.props.onChangeHandlerList(control_val, 'f_planMonth')

		event.preventDefault();	
	}

	setMonthValueChange = (event, target) => {
		//console.log('setMonthValueChange === EVENT: ', event.target);
	}

	

	render() {

		const { error, isLoaded, months } = this.state;	
		
		const cls = []

		let showValue = 'Выбрать...';		

		if (this.props.isEditGoal) {
			months.map((month, index) => {

				//console.log('month.month: ', month.month);
				//console.log('this.props.value: ', this.props.value);

				if (month.month===this.props.value) {
					showValue = month.name
				}
			})
		}

		

		if (error) {
			return <div className={cls.join(' ')} key='0'>Ошибка загрузки данных по месяцам. (1)</div>;
		} else if (!isLoaded) {
			return <div className={cls.join(' ')} key='0'>Загружаем данные по месяцам... (2)</div>;
		} else {

			return (
				<div className={cls.join(' ')}>
					<div className="spbHeader">Месяц</div>
					<div className="spbBody">
						<div className="cpFilter">
							<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />
							<div className="cpfSelected">{showValue}</div>
							<div className="cpfMenuShell">
								<div className="cpfMenu">
									<ul>{
										months.map((month, index) => (
											<li key={index}>						
												<a href={'#monthId_'+index} className={this.props.isEditGoal && month.month===this.props.value ? 'checkedClass' : ''} data-month={month.month} onClick={this.setValue}>{month.name}</a>						
											</li>
										))
									}</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
			
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId
});  

export default connect(mapStateToProps)(MonthList);