import "../styles/GoalList.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import store from "../store.js"

registerLocale('ru', ru)

const fullMonthArray = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']		

class GoalList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  goals: [],
		  clientId: this.props.clientId,
		  isHourlyRate: this.props.isHourlyRate,
          isFix: this.props.isFix,
          isRepeat: this.props.isRepeat,            
          goalMonthFilter: this.props.goalMonthFilter,
		  localMonth: false,
		  localYear: false,
		};
		this.unsubscribe = null;
	}

	processDate = (date) => {
		if (date.includes('-')) {
			const date_array = date.split('-')
			return new Date(date_array[0], date_array[1]-1, date_array[2])
		}
		return date
	}

	numberFormat = str => {		
		return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
	}

	onChangeDeadLineHandler = (goalId, date) => {

		if ((!this.state.clientId || this.state.clientId==0)) {
			console.log('RETURN NULL - onChangeDeadLineHandler');
			return null;	
		}

		const { userId } = this.props;	
		
		const currentMonth = date.getMonth()+1;
		let currentMonthString = currentMonth.toString();
		if (currentMonth<10) {
			currentMonthString = '0'+ currentMonth.toString();
		}

		const currentDate = date.getDate();
		let currentDateString = currentDate.toString();
		if (currentDate<10) {
			currentDateString = '0'+ currentDate.toString();
		}

		const taskData = {	
			userId, 
			goalId,     
			isNaked: 1,
			nc_ctpl: 191,
			deadline: date.getFullYear()+'-'+currentMonthString+'-'+currentDateString
		}
		
		if (this.state.clientId !==0) {
			taskData.clientId = this.state.clientId
		}

		if (this.props.isHourlyRate) {
			taskData.isHourlyRate = +this.props.isHourlyRate
		}

		if (this.props.isFix) {
			taskData.isFix = +this.props.isFix
		}

		if (this.props.isRepeat) {
			taskData.isRepeat = +this.props.isRepeat
		}

		if (this.props.goalMonthFilter) {
			taskData.goalMonthFilter = this.props.goalMonthFilter.getFullYear() + '-' + (this.props.goalMonthFilter.getMonth()<10 ? '0'+this.props.goalMonthFilter.getMonth() : this.props.goalMonthFilter.getMonth()) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate())
		}

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					//goals: result.data.error ? this.state.goals : result.data
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()

			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					//goals: this.state.goals
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()
			}
		)
	}

	dateFormat = dateString => {
		const dateObject = new Date(dateString);
		const dateObjectDay = dateObject.getDate();
		const monthArray = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
		const dateObjectMonth = monthArray[dateObject.getMonth()];
		const dateObjectYear = dateObject.getFullYear();
		return dateObjectDay + ' ' + dateObjectMonth + ' ' + dateObjectYear
	}

	updateGoalList = (clientId, locMonth,  locYear) => {

		//console.log('updateGoalList PROPS: ', this.props)
		//console.log('-----this.state.clientId: ', this.state.clientId)

		if ((clientId==0 || clientId==undefined || !clientId) && (!this.state.clientId || this.state.clientId==0)) {
			//console.log('RETURN NULL');
			return null;	
		}

		//console.log('NOT NULLED');

		const { userId } = this.props;		
		

		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 185
		}		
		
		if (this.state.clientId !==0) {
			taskData.clientId = this.state.clientId
		}

		if (this.props.isHourlyRate) {
			taskData.isHourlyRate = +this.props.isHourlyRate
		}

		if (this.props.isFix) {
			taskData.isFix = +this.props.isFix
		}

		if (this.props.isRepeat) {
			taskData.isRepeat = +this.props.isRepeat
		}

		if (this.props.goalMonthFilter) {
			//taskData.goalMonthFilter = this.props.goalMonthFilter.getFullYear() + '-' + ( (+this.props.goalMonthFilter.getMonth() + 1)<10 ? '0'+(+this.props.goalMonthFilter.getMonth() + 1) : (+this.props.goalMonthFilter.getMonth() + 1) ) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate())

			taskData.goalMonthFilter = this.props.currentYear + '-' + ( (+this.props.currentMonth + 1)<10 ? '0'+(+this.props.currentMonth + 1) : (+this.props.currentMonth + 1) ) + '-01'
			//console.log('++taskData.goalMonthFilter: ', taskData.goalMonthFilter);
		}

		if (locMonth && locYear) {
			taskData.goalMonthFilter = locYear + '-' + ( (+locMonth + 1)<10 ? '0'+(+locMonth + 1) : (+locMonth + 1) ) + '-01'
			//console.log('-- another taskData.goalMonthFilter: ', taskData.goalMonthFilter);
		}

		//console.log('updateGoalList, taskData: ', taskData);
		//console.log('this.state: ', this.state);

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					goals: result.data.error ? this.state.goals : result.data,
					clientId: clientId ? clientId : this.state.clientId,
					isHourlyRate: +this.props.isHourlyRate,
					isFix: +this.props.isFix,
					isRepeat: +this.props.isRepeat,
					goalMonthFilter: this.props.goalMonthFilter,
					localMonth: locMonth ? locMonth : false,
					localYear: locYear ? locYear : false,
				});

				this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					goals: this.state.goals,
					clientId: clientId ? clientId : this.state.clientId,
					isHourlyRate: +this.props.isHourlyRate,
					isFix: +this.props.isFix,
					isRepeat: +this.props.isRepeat,
					goalMonthFilter: this.props.goalMonthFilter
				});

				this.props.stopUpdateGoalsList()
				this.props.fireUpdateBudget()
			}
		)
		
	}

	deleteGoal = (event, goalId ) => {

		event.stopPropagation()

		const { userId } = this.props;		

		const taskData = {	
			userId, 
			goalId,     
			isNaked: 1,
			nc_ctpl: 190
		}		

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					//goals: result.data.error ? this.state.goals : result.data
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()

			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					//goals: this.state.goals
				});

				this.updateGoalList()
				//this.props.fireUpdateBudget()
			}
		)

	}

	editGoal = (goal) => {

		console.log('GOAL LIST FIRE PARENT FUNCTION, DATA: ', goal);

		this.props.fireEditGoal(goal)


		//console.log('ADJUST TA!');
		//this.adjustTextareas();

	}

	adjustTextareas = () => {
		/*
		let textareas_list = document.querySelectorAll('.addGoalPanelWrapper textarea');

		textareas_list.forEach((one_textarea) => {
			console.log('000:', one_textarea);
			one_textarea.removeAttribute('style');
			console.log('001:', one_textarea);
			one_textarea.style.removeProperty("height");
			console.log('002:', one_textarea);
		});

		let textareas = [...document.querySelectorAll('.addGoalPanelWrapper textarea')];

		if (textareas) {
			textareas.map((one_textarea) => {	
				
				console.log('0:', one_textarea);				
				
				var offset = +one_textarea.offsetHeight - +one_textarea.clientHeight;			
				one_textarea.style.height = 0;

				let realHeight = +one_textarea.scrollHeight + offset

				console.log('realHeight: ',  realHeight);
				one_textarea.style.height = realHeight + 'px';

				console.log('= TEXTAREA HEIGHT: ', (one_textarea.scrollHeight + offset));
				
			})
		}
		*/
	}	

	componentDidMount() {

		//this.updateGoalList()
		/*
		if ((clientId==0 || clientId==undefined || !clientId) && (!this.state.clientId || this.state.clientId==0)) {
			//console.log('RETURN NULL');
			return null;	
		}
		*/
		

		this.unsubscribe = store.subscribe(() => {
			//console.info('114 STORE::::: ', store.getState())
			let localStore = store.getState();
						
			this.updateGoalList(null, localStore.app.currentMonth, localStore.app.currentYear)				
			
		})

	}

	componentWillUnmount() {
		this.unsubscribe && this.unsubscribe();
	}
	
	componentDidUpdate() {

		// if (this.props.clientId != this.state.clientId) {
		// 	this.setState({
		// 		...this.state,
		// 		clientId: this.props.clientId
		// 	})
		// }

		//console.log('111: componentDidUpdate - this.props.isNeedUpdateGoalsList: ', this.props.isNeedUpdateGoalsList);
//
		if (this.props.isNeedUpdateGoalsList || this.state.isHourlyRate != this.props.isHourlyRate || this.state.isFix != this.props.isFix || this.state.isRepeat != this.props.isRepeat || this.state.goalMonthFilter.toString() != this.props.goalMonthFilter.toString()) {
			//console.log('222 this.props.isNeedUpdateGoalsList FIRED ', this.props.fireUpdateGoalsList);
			if (this.props.clientId != this.state.clientId) {
				this.updateGoalList(this.props.clientId)
			} else {	
				this.updateGoalList()
			}

			//this.props.fireUpdateBudget()
			//this.props.stopUpdateGoalsList()
			
		}

		

		//return null
	}

	render() {	

		const { error, isLoaded, goals } = this.state;

		//console.log('...goalList PROPS: ', this.props)
		//console.log('...goalList STATE: ', this.state)

		//console.log('currentMonth: ', currentMonth), currentMonth, currentYear

		let renderMonth = this.props.currentMonth ? this.props.currentMonth : new Date().getMonth()
		let renderYear = this.props.currentYear ? this.props.currentYear : new Date().getFullYear()

		if (!this.state.clientId || this.state.clientId==0 || this.props.clientId==0 || this.props.clientId==undefined || !this.props.clientId) {
			return <li key='0'>Загружаем данные о задачах... (3)</li>;
		}

		if (error) {
			return <li key='0'>Ошибка загрузки данных по задачам. (1)</li>;
		} else if (!isLoaded) {
			return <li key='0'>Загружаем данные о задачах... (2)</li>;
		} else {
			return (
				goals.length > 0
				? 
				goals.map((goal, index) => (
					<div className="goalListWrapper" key={index}>
						<div className="clientDataHeader">
							<div className="cdhLeft">
								<div className="cdhlLogo"><img src={goal.clientLogo} alt="" /></div>
								<div className="cdhlName">{goal.clientName}</div>
								<div className="cdhlMonth">{this.props.goalMonthFilter ? fullMonthArray[renderMonth]+' ‘'+renderYear.toString().substr(-2) : ''}</div>
							</div>
							{/*<div className="cdhRight"><button data-value="#addGoal" onClick={this.addPlanTask} title="Показать панель добавления">Добавить задачу</button></div>*/}
						</div>
						<div className="clientGoals">
							{
								goal.categories.map((category, categoryIndex) => (
									<div className={'cgGoalCategory'+(category.categoryId==6 ? ' greyBg' : '')} key={categoryIndex}>
										<div className="cgGoalCategoryName">{category.categoryName}</div>
										<div className="cgGoalCategoryList">
											{
												category.goals.map((categoryGoal, goalIndex) => (
													<div className="cggcl_row" key={goalIndex} data-value={'#addComment_'+goalIndex} onClick={() => this.editGoal(categoryGoal)}>
														<div className="goalRowLeft">
															<div className="goalRowMarks">{categoryGoal.isFix==1 ? <span className="fixedSum">F</span> : null}{categoryGoal.isRepeat==1 ? <span className="repeatGoal">R</span> : null}</div>
															<div className="goalRowName">{categoryGoal.title}</div>
														</div>
														<div className="goalRowComment">{categoryGoal.comment ? <span></span> : null}</div>
														<div className="goalRowSum">{this.numberFormat(categoryGoal.sum)} р.</div>
														<div className="goalRowDeadline" onClick={event => event.stopPropagation()}>
															<DatePicker 
																key={goalIndex}
																id={goalIndex + '_f_deadLine'}
																selected={this.processDate(categoryGoal.deadline)} 
																onChange={date => this.onChangeDeadLineHandler(categoryGoal.id, date)}
																locale="ru"
																dateFormat="dd MMM yyyy"
															/>
														</div>
														<div className="goalRowTrash"><button onClick={(event) => this.deleteGoal(event, categoryGoal.id)}></button></div>
													</div>
												))
											}											
										</div>
									</div>	
								))
							}
						</div>
					</div>
				))
				:
				(
				this.props.clientData	
				?
				<div className="goalListWrapper" key="glw_1">
					<div className="clientDataHeader">
						<div className="cdhLeft">
							<div className="cdhlLogo"><img src={this.props.clientData.logo} alt="" /></div>
							<div className="cdhlName">{this.props.clientData.name}</div>
							<div className="cdhlMonth">{this.props.goalMonthFilter ? fullMonthArray[renderMonth]+' ‘'+renderYear.toString().substr(-2) : ''}</div>
						</div>
						{/*<div className="cdhRight"><button data-value="#addGoal" onClick={this.addPlanTask} title="Показать панель добавления">Добавить задачу</button></div>*/}
					</div>
					<div className="clientGoals">		
						<i>Нет задач в плане.</i>
					</div>				
				</div>
				:
				<></>			
				)	
			);
		}			
		
	}

}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	currentMonth: state.app.currentMonth,
	currentYear: state.app.currentYear,
});  

export default connect(mapStateToProps)(GoalList);