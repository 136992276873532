import "../styles/Board.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import List from "./List";
//import AddList from "./AddList";

import axios from "axios";

import Modal from "./UI/Modal/Modal";
import AddTaskPanelWrapper from "./AddTaskPanelWrapper";

import { updateTasksData } from "../store/actions/task";

//import { updateTasksTable } from "../store/actions/task";

let eventSource

/*= new EventSource('https://task.apriori-marketing.ru/sse2.php');	

eventSource.addEventListener('tasks_update', function(e) {
  console.log(e.data);


}, false); 

eventSource.onerror = function (error) {
  console.error("⛔ EventSource failed: ", error);
};*/

//console.log('eventSource', eventSource);

class Board extends Component {

  state = {
    addingList: false,
    showModal: false,
    showTaskPanel: '2A',
    task: {},
    taskData: {},
    isNeedUpdateTasksList: false,
    users: [],
    eventSourceId: 0
    //isAuth: false
  };

  /*
  constructor(props) {
    super(props);
    this.state = { isAuth: 2 };
  }
  */

  getModal = data => {
    this.setState({ showModal: true, dataModal: data });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    //this.props.showModal = false;
  };  

  toggleAddingList = () =>
    this.setState({ addingList: !this.state.addingList });

  handleDragEnd = ({ source, destination, type }) => {
    // dropped outside the allowed zones
    if (!destination) return;

    const { dispatch, lists, userId, userRole } = this.props;

    // Move list
    if (type === "COLUMN") {
      // Prevent update if nothing has changed
      if (source.index !== destination.index) {
        dispatch({
          type: "MOVE_LIST",
          payload: {
            oldListIndex: source.index,
            newListIndex: destination.index
          }
        });
      }
      return;
    }

    // Move card
    if (
      source.index !== destination.index ||
      source.droppableId !== destination.droppableId
    ) {

      // console.log('PROPS: ', this.props);
      // console.log('provided: ', this.provided);
      // console.log('source: ', source);
      // console.log('destination: ', destination);

      let removedCard
          
      // Move within the same list
      if (source.droppableId === destination.droppableId) {
        const newCards = Array.from(lists[+source.droppableId].cards);
        removedCard = newCards.splice(source.index, 1);        
      }
      // Move card from one list to another
      // console.log('lists: ', lists);
      // console.log('source.droppableId: ', source.droppableId);

      const sourceCards = Array.from(lists[+source.droppableId].cards);
      removedCard = sourceCards.splice(source.index, 1);

      //console.log('source: ', source);
      //console.log('destination: ', destination);
      //console.log('+1 destination cards: ', Array.from(lists[+destination.droppableId].cards));
           
      let newCards = Array.from(lists[+destination.droppableId].cards)
      if (source.droppableId === destination.droppableId) {        
        const removedCard2 = newCards.splice(source.index, 1);
        newCards.splice(destination.index, 0, removedCard2[0]);            
      } else {        
        newCards.splice(destination.index, 0, removedCard[0]);
      }

      //console.log('++2 newCards: ', newCards);

      console.log('3 Handle dragEnd, this.props.currentWorkerId: ', this.props.currentWorkerId);
      console.log('3 Handle dragEnd, userId: ', userId);

      const taskData = {
        cardId: removedCard,
        listId: destination.droppableId,
        userId: this.props.currentWorkerId ? this.props.currentWorkerId : userId,
        userRole,
        priorityId: destination.index,
        isNaked: 1,
        nc_ctpl: 173,
        newCards
      }
  
      let response = null
      const params = (Object.entries(taskData)
        .reduce((acc,e,i) => 
          `${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
        '')
        )    
  
      try {			
        response = axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
        const data = response.data
        
        //console.log('update task title data: ', response.data)
  
        if (data.error) {
          console.log('12.1');				
        } else {				
          //cardId = data.taskId;
        }
  
      } catch (e) {
        
      }   

      dispatch({
        type: "MOVE_CARD",
        payload: {
          sourceListId: source.droppableId,
          destListId: destination.droppableId,
          oldCardIndex: source.index,
          newCardIndex: destination.index
        }
      });

      //console.log('dispatch: ', dispatch);
      //console.log('2 destination cards: ', Array.from(lists[+destination.droppableId].cards));

      dispatch({
        type: "UPDATE_TASKS_TABLE"
      })

    }
  };

  fireEditTask = (task) => {
		//console.log('PLAN (PARENT) FIRE CHILD FUNCTION, DATA: ', goal);
		this.setState({
			...this.state, task
		})

		let panel = document.getElementById('addTaskSidePanel');

		if (!panel.classList.contains('active')) this.addPlanTask()
	}  

	closeAddPlanTaskPanel = () => {
		let panel = document.getElementById('addTaskSidePanel');
		panel.classList.toggle('active');
		//console.log('Close addPlanTask Clicked');
	}

  cancelEditGoal = () => {
		this.setState({
			...this.state, 
			isNeedUpdateTasksList: true,			
			task: {}
		})
	}

  fireUpdateTasksList = () => {
		console.log('Board - fireUpdateTasksList');

		this.setState({
			...this.state, 
			isNeedUpdateTasksList: true
		})
	}

  getTaskData = data => {
    this.setState({ showTaskPanel: true, taskData: data });
  };

  hideTaskPanel = () => {
    this.setState({ showTaskPanel: false });
    //this.props.showModal = false;
  };

  componentDidMount() {

    const { dispatch, userId, userHash, amIManager, amIExecutor, amIInvolved, taskClient, currentWorkerId } = this.props;
    
    //const { lists, userId, userRole } = this.props;

    const userListData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 192
		}		

		const params = (Object.entries(userListData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/users/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					users: result.data.error ? this.state.users : result.data
				});

				
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					isLoaded: true,
					error,
					users: this.state.users
				});				
			}
		)

    // UPDATE BEFORE USING BOARD  
    let filterData = {
      'amIManager': amIManager,
      'amIExecutor': amIExecutor,
      'amIInvolved': amIInvolved,
      'taskClient': taskClient,
      'currentWorkerId': currentWorkerId
    }
    let keepPopupOpened = false;


    console.log('1 COMPONENT DID MOUNT - currentWorkerId: ', currentWorkerId);

    dispatch(updateTasksData(userHash, filterData, keepPopupOpened))	 


    eventSource = new EventSource('https://task.apriori-marketing.ru/sse2.php');	

    //let that = this  

    eventSource.addEventListener('tasks_update', (e) => {
      console.log('!!! !!! !!! UPDATE TASKS, SSE FIRED: ', e.data);

      console.log('--- currentWorkerId: ', currentWorkerId);
      console.log('--- this.props.currentWorkerId: ', this.props.currentWorkerId);

      //console.log('Board - fireUpdateTasksList');
      /*
      this.setState({
        ...this.state, 
        isNeedUpdateTasksList: true
      },
      () => {
        console.log('isNeedUpdateTasksList IN STATE: ', this.state.isNeedUpdateTasksList);
      }      
      )
      

      dispatch({
        type: "UPDATE_CARDS"
      })
      */
      //async () => {
        /*await this.props.updateTasksData(userHash, 
          {
            'amIManager': amIManager,
            'amIExecutor': amIExecutor,
            'amIInvolved': amIInvolved,
            'taskClient': taskClient
          }
        ) */ 

        let filterData = {
          'amIManager': amIManager,
          'amIExecutor': amIExecutor,
          'amIInvolved': amIInvolved,
          'taskClient': taskClient,
          'currentWorkerId': this.props.currentWorkerId
        }

        console.log('2 eventSource eventListener TASKUPDATE - filterData: ', filterData);

        let keepPopupOpened = false;

        dispatch(updateTasksData(userHash, filterData, keepPopupOpened))		  

      //}  


    }, false); 

    eventSource.onerror = function (error) {
      console.error("⛔ EventSource failed: ", error);
    }; 
    


  }

  render() {
    const { board } = this.props;

    //console.log('BOARD-PROPS: ', this.props);

    //console.log('IS WHERE SOME DATA: ', this.props.showTaskPanel);
    //console.log('IS SHOWN: ', this.props.isShown);
    //const { addingList } = this.state;
     //console.log('BOARD: ', board);
    //console.log('BOARD PROPS: ', this.props);
    //console.log('BOARD STATE: ', this.state);
   
    // console.log('this.props.showModal: ', this.props.showModal);
    // console.log('this.state.showModal: ', this.state.showModal); 
    
    let commonPlannedTimeMonth = 0;

    //console.log('BOARD PROPS LISTS: ', this.props.lists);

    for (let list in this.props.lists) {
      //console.log('BOARD PROPS LISTS: list: ', this.props.lists[list]);
      //console.log('BOARD PROPS LISTS: list.cards: ', this.props.lists[list].cards);

      if (this.props.lists[list]._id==2) {

        this.props.lists[list].cards.map(card => {
          commonPlannedTimeMonth += this.props.card && this.props.card[card] && this.props.card[card].taskData && this.props.card[card].taskData.planTime ? +this.props.card[card].taskData.planTime : 0
        });

      }
    }

    //console.log('commonPlannedTimeMonth: ', commonPlannedTimeMonth);

    return (

      <div className="tmContent">  

        <div className="tmcWrapper">     

            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="board" direction="horizontal" type="COLUMN">
                {(provided, _snapshot) => (
                  <div className="Board" ref={provided.innerRef}>
                    {board.lists.map((listId, index) => {
                      return <List 
                        listId={listId} 
                        key={listId} 
                        index={index}
                        commonPlannedTimeMonth={commonPlannedTimeMonth}                        
                        />;
                    })}

                    {provided.placeholder}

                    { 
                    /*
                    <div className="Add-List">
                      {addingList ? (
                        <AddList toggleAddingList={this.toggleAddingList} />
                      ) : (
                        <div
                          onClick={this.toggleAddingList}
                          className="Add-List-Button"
                        >
                          <ion-icon name="add" /> Add a list
                        </div>
                      )}
                    </div>
                    */
                    }    

                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Modal
              show={this.props.showModal}
              onHide={this.hideModal}
              name={this.props.cardText}
              data={this.props.formData}
              userId={this.props.userId}
              userRole={this.props.userRole}
              userPhoto={this.props.userPhoto}
              userName={this.props.userName}
              cardId={this.props.cardId}
            />

            <div className="tmSidePanel w640" id="addTaskSidePanel">
              {
              this.props.isShown    
              ?  
              <AddTaskPanelWrapper             
                taskData={this.props.taskData}
                userId={this.props.userId}
                users={this.state.users}            
              />
              :
              null}
                
            </div>

        </div>     
  
      </div>          

    );
  }
}

const mapStateToProps = (state, ownProps) => ({ 
  board: state.board,
  lists: state.listsById,
  userId: state.auth.userId,
  userRole: state.auth.userRole,
  userPhoto: state.auth.userPhoto,
  userName: state.auth.userName,
  userHash: state.auth.token,

	amIManager: state.app.amIManager,
  amIExecutor: state.app.amIExecutor,
  amIInvolved: state.app.amIInvolved,

	taskClient: state.app.taskClient,

  showModal: state.modal.showModal,
  cardId: state.modal.cardId,
  cardText: state.modal.cardText,
  formData: state.modal.data,
  taskData: state.task.taskData,
  showTaskPanel: state.task.showTaskPanel,
  isShown: state.task.isShown, 
  card: state.cardsById,
  currentWorkerId: state.app.currentWorkerId, 
  currentWorkerHours: state.app.currentWorkerHours,
  //currentMonth: state.app.currentMonth,
  //currentYear: state.app.currentYear,
});

// function mapDispatchToProps(dispatch) {
// 	return {
// 		updateTasksTable: () => dispatch(updateTasksTable())		
// 	}
// }

export default connect(mapStateToProps)(Board);