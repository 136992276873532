import React from 'react'
import classes from './Select.module.css'

function isInvalid({valid, touched, shouldValidate}) {
	return !valid && shouldValidate && touched
}

const Select = props => {
	
	const cls = [classes.Select]
	const htmlFor = `${Math.random()}`

	if (isInvalid(props)) {
		cls.push(classes.invalid)
	}

	//console.log('SELECT PROP VALUE: ', props.value);
	//console.log('SELECT PROP options: ', props.options);

	// if (props.options) {
	// 	props.options.map((item, index) => {
	// 		console.log('item: ',item);
	// 		console.log('props.type: ', props.type);
	// 		console.log(props.value.includes(item.value));

			
	// 	})
	// }	

	return (
		<div className={cls.join(' ')}>

			<label htmlFor={htmlFor}>{props.label}</label>
			<select 				
				id={htmlFor}
				value={(props.value ? (props.type && !Array.isArray(props.value) ? [props.value] : props.value) : (props.type ? [] : 0))}
				onChange={props.onChange}
				multiple={props.type}
			>	
			
			{!props.options && <option/>}
			{ 
				props.options && props.options.map((item, index) => (
					<option key={index} value={item.value}>
						{ item.label }
					</option>
				)) 
			}	

			</select>	

			{
				isInvalid(props) 
				? <span>{props.errorMessage || `Введите верное значение`}</span> 
				: null
			}
			
		</div>
	)
}

export default Select