const initialState = {
	showModal: false,
	showTaskPanel: '44444',
    taskId: null,
	cardText: null,
	taskData: {},
	fields: null,
	userId: null,
	isShown: false,
	isNeedUpdateTaskTable: false,
	taskTableFilterClient: false
}

export default function taskReducer(state = initialState, action) {

	//console.log('MODAL REDUCER: ', action);

	switch (action.type) {

		case 'SET_TASKTABLE_FILTER_CLIENT':

			//console.log('UPDATE_TASKS_TABLE REDUCER');
			//console.log('+++====++++ TASK REDUCER: setTasksTableFilterClient', action);

			return {
				...state, 
				taskTableFilterClient: action.data
			}

		case 'UPDATE_TASKS_TABLE':

			//console.log('UPDATE_TASKS_TABLE REDUCER');
			//console.log('+++ REDUCER: updateTasksTable');
			return {
				...state, 
				isNeedUpdateTaskTable: true
			}

		case 'STOP_UPDATE_TASKS_TABLE':

			//console.log('UPDATE_TASKS_TABLE REDUCER');
			//console.log('+ REDUCER: STOP_UPDATE_TASKS_TABLE');
			return {
				...state, 
				isNeedUpdateTaskTable: false
			}	



		case 'SHOW_TASK_PANEL':

			//console.log('== TASK REDUCER show-model-set state, action 1: ', action);

			return {
				...state,
				isShown: true, 				
				taskData: action.data
			}

		case 'HIDE_TASK_PANEL':

				//console.log('== TASK REDUCER show-model-set state, action 2: ', action);
	
				return {
					...state,
					isShown: false, 				
					taskData: action.data
				}	

		/*case 'HIDE_MODAL':

			//console.log('MODAL REDUCER hide-model-set state, action: ', action);

			return {
				...state, 
				showModal: false,
				cardId: null,
				cardText: null,
				data: null
			}
		*/	
		case 'SAVE_TASK_DATA':

			//console.log('SAVE_MODAL_DATA REDUCER, action: ', action);

			return {
				...state, 
				showModal: true,
				taskId: action.modal.taskId,
				cardText: action.modal.cardText,
				data: action.modal.formData,
				fields: action.modal.fields,
				userId: action.modal.userId
			}	

		case 'SAVE_SUCCESS':

			//console.log('MODAL REDUCER -- SAVE_SUCCESS action: ', action);

			return {
				...state, 
				showModal: false,
				taskId: null,
				cardText: null,
				data: null
			}
			
		case 'SAVE_FAIL':

			//console.log('MODAL REDUCER -- SAVE_FAIL action: ', action);

			return {
				...state, 
				showModal: false,
				taskId: null,
				cardText: null,
				data: null
			}	
			
		default:
			return state
	}
}