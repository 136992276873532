import "../styles/Plan.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import ClientList from "./ClientList";
import SpecList from "./SpecList";
import BugdetCalculation from "./BugdetCalculation";
import AddGoalPanelWrapper from "./AddGoalPanelWrapper";

class Spec extends Component {

	constructor(props) {
		super(props);

		/*this.state = {
		  error: null,
		  isLoaded: false,
		  goal: []
		};*/

		this.state = {
			updateClientList: false,
			isNeedUpdateGoalsList: false,
			isNeedUpdateBudget: false,
			goal: {},
			clientId: 0,
			reportLink: false,
			month: false,
			isReportUpdating: false,
			reportLinkClientId: 0
		}

		this.fireEditGoal = this.fireEditGoal.bind(this);

	}	

	fireUpdateClientList = () => {
		this.setState({
			...this.state, updateClientList: true
		})
	}

	fireUpdateGoalsList = () => {

		//console.log('Plan - fireUpdateGoalsList');

		this.setState({
			...this.state, 
			isNeedUpdateGoalsList: true,
			isNeedUpdateBudget: true
		})
	}

	fireUpdateBudget = () => {
		//console.log('>>>>>>> Plan - fireUpdateBudget !!!!');

		this.setState({
			...this.state, 			
			isNeedUpdateBudget: true
		})
	}

	stopUpdateGoalsList = () => {

		//console.log('Plan - stopUpdateGoalsList');

		this.setState({
			...this.state, isNeedUpdateGoalsList: false
		})
	}

	stopUpdateBudget = () => {

		//console.log('Plan - stopUpdateBudget');

		this.setState({
			...this.state, isNeedUpdateBudget: false
		})
	}

	fireEditGoal = (goal) => {

		//console.log('PLAN (PARENT) FIRE CHILD FUNCTION, DATA: ', goal);

		this.setState({
			...this.state, goal
		}, function() {
			//console.log('33');
			//this.adjustTextareas()
		})

		let panel = document.getElementById('addTaskSidePanel');

		if (!panel.classList.contains('active')) this.addPlanTask()

		
	}

	cancelEditGoal = () => {
		this.setState({
			...this.state, 
			isNeedUpdateGoalsList: true,
			isNeedUpdateBudget: true,
			goal: {}
		})
	}

	

	//state = {
	  //addingList: false,
	  //showModal: false,
	  
	  //isAuth: false
	//};

	addPlanTask = (newGoal) => {
		let panel = document.getElementById('addTaskSidePanel');
		if (!panel.classList.contains('active')) panel.classList.toggle('active');
		if (newGoal) this.cancelEditGoal()
		//console.log('addPlanTask Clicked, newGoal: ', newGoal);
		//console.log('state.goal: ', this.state.goal);
	}

	closeAddPlanTaskPanel = () => {
		let panel = document.getElementById('addTaskSidePanel');
		panel.classList.toggle('active');
		//console.log('Close addPlanTask Clicked');
	}

	changeHandler = () => {	

		this.setState({
			//...this.state, isAuthNotice: true
		})	

		/*this.props.auth(
			this.state.formControls.email.value,
			this.state.formControls.password.value,
			true
		)*/	
	}	

	componentDidMount() {
		window.addEventListener('scroll', event => this.handleScroll(event), true);

		//console.log('+ 5 planClients: ', this.props.planClients)

		if (this.props.planClients && this.props.planClients[0] && this.props.planClients[0].id) {			

			this.setState({
				...this.state, 
				clientId: this.props.planClients[0].id,
				isNeedUpdateGoalsList: true
			})

			let thismonth = '';
			if (this.props.goalMonthFilter) {
				thismonth = this.props.goalMonthFilter.getFullYear() + '-' + ((this.props.goalMonthFilter.getMonth()+1)<10 ? '0'+(this.props.goalMonthFilter.getMonth()+1) : (this.props.goalMonthFilter.getMonth()+1)) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate());
			}	

			if (this.props.goalMonthFilter) {

				this.setState({
					...this.state, 
					month: this.props.goalMonthFilter
				})

				console.log('goalMonthFilter: ', this.props.goalMonthFilter);
			}	

			this.getReportLink(this.props.planClients[0].id, thismonth, 'check')
		}

		

	}

	componentDidUpdate(){
		
		console.log('componentDidUpdate - 0-0-9');

		

		if (this.props.goalMonthFilter && (this.state.month != this.props.goalMonthFilter)) {
			
			console.log('componentDidUpdate - 1 - BEFORE SET STATE');

			this.setState({
				...this.state, 
				month: this.props.goalMonthFilter
			},
			function() {
				console.log('componentDidUpdate - 2-0-0 - AFTER SET STATE');
				this.getReportLink(this.props.planClients[0].id, this.props.goalMonthFilter, 'check')
			})
			
		}


		if (this.state.clientId != this.state.reportLinkClientId && this.state.month) {

			let current_clientId = this.state.clientId;
			
			console.log('1. this.state.clientId != this.state.reportLinkClientId');
			console.log('1.1. this.state.clientId: ', this.state.clientId);
			console.log('1.2. this.state.reportLinkClientId: ', this.state.reportLinkClientId);

			console.log('1 STATE', this.state);


			this.setState((state) => {
				// Важно: используем `state` вместо `this.state` при обновлении.
				return {
					reportLinkClientId: current_clientId
				}
			},
			function() {
				console.log('2 STATE', this.state);
			});


			/*this.setState({
				...this.state, 
				reportLinkClientId: current_clientId
			},
			function() {
				console.log('2. componentDidUpdate ------ this.state.clientId != this.state.reportLinkClientId');
				console.log('2.1. this.state.clientId: ', this.state.clientId);
				console.log('2.2. this.state.reportLinkClientId: ', this.state.reportLinkClientId);
				this.getReportLink(this.state.clientId, this.state.month, 'check');
				console.log('2 STATE', this.state);
			})*/
			
		}

	}
  


	componentWillUnmount() {
		window.removeEventListener('scroll', event => this.handleScroll(event));
	}

	static getDerivedStateFromProps(props, state) {
		/*if (props.planClients.length > 0 && state.clientId === 0) {
			return {
				clientId: props.planClients[0].id,
				isNeedUpdateGoalsList: true,
				isNeedUpdateBudget: true
			}
		  }
	  
		  return null;*/

		  /*if (props.goalMonthFilter && state.month && state.month != props.goalMonthFilter) {
			if (props.planClients && props.planClients[0] && props.planClients[0].id) {
				getReportLink(props.planClients[0].id, props.goalMonthFilter, 'get')
			}
		  }*/

		  if ((props.planClients && props.planClients.length > 0) && state.clientId === 0) {

			console.log('SPEC getDerivedStateFromProps - props.planClients[0]: ', props.planClients[0]);

			const { dispatch } = props;

			dispatch({
				type: "NEED_UPDATE_CURRENT_CLIENT",
				payload: {
					isNeedUpdateCurrentClient: props.planClients[0]
				}
			});

			return {
				clientId: props.planClients[0].id,
				isNeedUpdateGoalsList: true,
				isNeedUpdateBudget: true
			}

		  }
	  
		  return null; 

	}

	handleScroll = event => {
		let panel = document.getElementById('addTaskSidePanel');
		
		//console.log('scroll: ', window.scrollY + '-' + document.body.scrollTop);		
		if (window.scrollY > 58){
			if (!panel.classList.contains('fixed')) panel.classList.add('fixed');
		} else {
			panel.classList.remove('fixed');
		}
	}

	getClientGoals = (client) => {
		/*console.log('+++ getClientGoals, clientId: ', clientId)

		this.setState({
			...this.state, 
			clientId: clientId,
			isNeedUpdateGoalsList: true
		})*/

		console.log('+++ SPEC:: getClientGoals, clientId: ', client.id)

		const { dispatch } = this.props;

		this.setState({
			...this.state, 
			clientId: client.id,
			isNeedUpdateGoalsList: true
		})

		dispatch({
			type: "NEED_UPDATE_CURRENT_CLIENT",
			payload: {
				isNeedUpdateCurrentClient: client
			}
		});

	}

	resetAllCorrections = (clientId, month) => {

		console.log('CLIENT ID: ', clientId);
		console.log('month: ', month);

		if (!clientId || !month ) {
			return false
		}

		const { userId } = this.props;

		const taskData = {	
			userId, 
			clientId,
			month: this.props.goalMonthFilter.getFullYear() + '-' + ((this.props.goalMonthFilter.getMonth()+1)<10 ? '0'+(this.props.goalMonthFilter.getMonth()+1) : (this.props.goalMonthFilter.getMonth()+1)) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate()),			 
			isNaked: 1,
			nc_ctpl: 201
		}	

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/goals/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					...this.state,
					isNeedUpdateGoalsList: true
				},
				function() {
		
					// console.log('TASK CHANGED 2');
					// console.log('+ 3 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill)
					// console.log('1 category: ', category)
					// console.log('1 goal: ', goal)
					// console.log('2 goals: ', this.state.goals)
					//this.updateGoalList()
				})
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				// this.setState({
				// 	isLoaded: true,
				// 	error,
				// 	goals: this.state.goals,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			}
		)

	}

	getReportLink = (clientId, month, action) => {

		console.log('CLIENT ID: ', clientId);
		console.log('month: ', month);

		if (!clientId || !action) {//!month  ||
			return false
		}

		const { userId } = this.props;

		const taskData = {	
			userId, 
			clientId,
			action,
			month: this.props.goalMonthFilter.getFullYear() + '-' + ((this.props.goalMonthFilter.getMonth()+1)<10 ? '0'+(this.props.goalMonthFilter.getMonth()+1) : (this.props.goalMonthFilter.getMonth()+1)) + '-' + (this.props.goalMonthFilter.getDate()<10 ? '0'+this.props.goalMonthFilter.getDate() : this.props.goalMonthFilter.getDate()),			 
			isNaked: 1,
			nc_ctpl: 206
		}	

		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		) 
		
		let data_result = false;

		this.setState({
			...this.state, 
			isReportUpdating: true
		});
					
		axios.get(`https://task.apriori-marketing.ru/reports/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {

				data_result = result.data.gsstable;

				console.log('GSS data_result: ', result.data);

				this.setState({
					...this.state,
					isNeedUpdateGoalsList: true,
					reportLink: result.data.gsstable,
					isReportUpdating: false
				},
				function() {
		
					 console.log('result.data.gsstable: ', this.state.reportLink);
					// console.log('+ 3 this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill: ', this.state.goals[0].categories[category].goals[goal].tasks[task].isInBill)
					// console.log('1 category: ', category)
					// console.log('1 goal: ', goal)
					// console.log('2 goals: ', this.state.goals)
					//this.updateGoalList()
				})
			},
			// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
			// чтобы не перехватывать исключения из ошибок в самих компонентах.
			(error) => {
				this.setState({
					...this.state, 
					isReportUpdating: false
				});
				// this.setState({
				// 	isLoaded: true,
				// 	error,
				// 	goals: this.state.goals,
				// 	clientId: clientId ? clientId : this.state.clientId,
				// 	isHourlyRate: +this.props.isHourlyRate,
				// 	isFix: +this.props.isFix,
				// 	isRepeat: +this.props.isRepeat,
				// 	goalMonthFilter: this.props.goalMonthFilter
				// });

				// this.props.stopUpdateGoalsList()
				// this.props.fireUpdateBudget()
			}
		)

		return data_result	

	}

	render() {
		const { plan } = this.props;

		//const { addingList } = this.state;
		//console.log('PLAN PROPS: ', plan);
		//console.log('SPEC STATE: ', this.state);
		//console.log('SPEC PROPS: ', this.props);	
		console.log('CLIENT: ',this.state.clientId); 
		console.log('MONTH: ',this.state.month);   
		//console.log('this.props.showModal: ', this.props.showModal);
		//console.log('this.state.month: ', this.state.month); 
		//console.log('this.props.goalMonthFilter: ', this.props.goalMonthFilter);  
	
		return (
	
		  <div className="Plan">	
	
			<div className="tmContent">

				<div className="tmcWrapper">

					<div className="planCell">

						<div className="tmLeft">
							<div className="tmPlanClients">
								<div className="tmpcHeader">Клиент</div>
								<div className="tmpcBody">
									<ClientList 
										getClientGoals={this.getClientGoals}
										clientId={this.state.clientId}
										id="3"
									/>														
								</div>
							</div>
						</div>

						<div className="tmCenter tmCenterPlan">
							<div className="goalAddButton">
								<button data-value="#addGoal" onClick={() => this.addPlanTask(true)} title="Показать панель добавления">Добавить задачу</button>
								<button className="refreshBtn" data-value="#resetFilters" onClick={() => this.resetAllCorrections(this.state.clientId, this.props.goalMonthFilter)} title="Сбросить изменения (коррекции для задач)">Сбросить изменения</button>
							</div>
							<SpecList
								isNeedUpdateGoalsList={this.state.isNeedUpdateGoalsList}
								stopUpdateGoalsList={this.stopUpdateGoalsList}
								fireUpdateBudget={this.fireUpdateBudget}
								fireEditGoal={this.fireEditGoal}
								clientId={this.state.clientId}	
								clientData={this.props.planClients.filter(client => (client.id==this.state.clientId))[0]}
								isHourlyRate={this.props.isHourlyRate}
								isFix={this.props.isFix} 
								isRepeat={this.props.isRepeat}             
								goalMonthFilter={this.props.goalMonthFilter}
							/>
						</div>

						<div className="tmRight">
							<BugdetCalculation
								isNeedUpdateBudget={this.state.isNeedUpdateBudget}
								stopUpdateBudget={this.stopUpdateBudget}
								clientId={this.state.clientId}
								goalMonthFilter={this.props.goalMonthFilter}
								isSpec="true"
							/>	

							<div className="specReportButton">
								<button onClick={() => this.getReportLink(this.state.clientId, '', 'get')}>{this.state.reportLink ? 'Обновить отчет' : 'Сформировать отчет'}</button>
							</div>

							{
								this.state.reportLink && !this.state.isReportUpdating

								?

								<div className="specReportLink">
									<div className="specReportLinkHeader">Ссылка на отчет:</div>
									<div className="specReportLinkLink">
										<a rel="noreferrer" target="_blank" href={this.state.reportLink}>{this.state.reportLink}</a>
									</div>	
								</div>

								:

								(this.state.isReportUpdating

								?

								<div className="specReportLink">Обработка данных...</div>

								:

								null)

							}	

						</div>

					</div>	

						<div className="tmSidePanel" id="addTaskSidePanel">

							<AddGoalPanelWrapper 
								fireUpdateGoalsList={this.fireUpdateGoalsList}						
								goalData={this.state.goal}
								cancelEditGoal={this.cancelEditGoal}	
								clientId={this.state.clientId}
								isShowCorrected="true"
							/>

						</div>

						
					
				</div>	

			</div>			

			{/*}						
			<Modal
			  show={this.props.showModal}
			  onHide={this.hideModal}
			  name={this.props.cardText}
			  data={this.props.formData}
			  userId={this.props.userId}
			  userRole={this.props.userRole}
			  userPhoto={this.props.userPhoto}
			  userName={this.props.userName}
			  cardId={this.props.cardId}
			/>
			*/}
	
		  </div>
	
		);
	}
} 

const mapStateToProps = (state, ownProps) => ({ 
	//board: state.board,
	//lists: state.listsById,
	userId: state.auth.userId,
	userRole: state.auth.userRole,
	userPhoto: state.auth.userPhoto,
	userName: state.auth.userName,
	//showModal: state.modal.showModal,
	//cardId: state.modal.cardId,
	//cardText: state.modal.cardText,
	//formData: state.modal.data
  });
  
  export default connect(mapStateToProps)(Spec);