import axios from 'axios'
//import store from '../../store'

export function auth(email, password, isLogin) {
	return async dispatch => {

		dispatch(authClearNotice())

		const authData = {
			email,	
			password,
			isNaked: 1,
			nc_ctpl: 167
		}

		let response = null
		const params = (Object.entries(authData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/check-auth/${params}`)
			const data = response.data
			//localStorage.setItem('token', data.token)
			console.log('1', response.data)

			if (data.error) {
				//console.log('1.1');
				dispatch(authFail())
			} else {
				//console.log('1.2');

				//console.log('store.getState(): ', store.getState())

				//store.getState().board.listsById.length

				//console.log('>>>>>> data.cardsById: ', ...data.cardsById);

				const data2 = {						
					listsById: data.listsById		
				}				

				//console.log('>>>>>> data.listsById: ', data2);

				const data3 = {						
					cardsById: data.cardsById
				}
				
				dispatch(updateLists(data2))

				dispatch(updateCards(data3))
				
				dispatch(authSuccess(data))

			}

		} catch (e) {
			//console.log('2', e)

			dispatch(authFail())
		}
		
	}
}

export function updateLists(data) {

	console.log('ACTION, updateLists', data);

	return {
		type: 'UPDATE_LISTS',
		data
	}
}

export function updateCards(data) {

	console.log('ACTION, updateCards', data);

	return {
		type: 'UPDATE_CARDS',
		data
	}
}

export function authSuccess(data) {
	return {
		type: 'AUTH_SUCCESS',
		data
	}
}

export function authFail() {
	return {
		type: 'AUTH_FAIL'
	}
}

export function authClearNotice() {
	return {
		type: 'AUTH_CLEAR_NOTICE'
	}
}