import "../styles/List.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";

import Card from "./Card";
import CardEditor from "./CardEditor";
import ListEditor from "./ListEditor";

//import shortid from "shortid";

import axios from "axios";
//import { app } from "../store/actions/app";

import { task } from "../store/actions/task";

class List extends Component {
  state = {
    editingTitle: false,
    title: this.props.list.title,
    addingCard: false
  };  

  toggleAddingCard = () =>
    this.setState({ addingCard: !this.state.addingCard });

  addCard = async cardText => {
    const { listId, dispatch, userId } = this.props;

    this.toggleAddingCard();

    if (cardText.trim() === '') {
      cardText = 'Нет названия задачи';
    }

    const taskData = {
			listId,
      userId: userId,
      cardTitle: cardText,
			isNaked: 1,
			nc_ctpl: 170
		}

		let response = null
		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )

    let cardId = null

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/${params}`)
			const data = response.data
			
			console.log('add list data: ', response.data)

			if (data.error) {
				console.log('11.1');				
			} else {				
        cardId = data.taskId;
			}

		} catch (e) {
			
		}    

    dispatch({
      type: "ADD_CARD",
      payload: { cardText, cardId, listId }
    });
  };

  toggleEditingTitle = () =>
    this.setState({ editingTitle: !this.state.editingTitle });

  handleChangeTitle = e => this.setState({ title: e.target.value });

  editListTitle = async () => {
    const { listId, dispatch } = this.props;
    const { title } = this.state;

    this.toggleEditingTitle();

    dispatch({
      type: "CHANGE_LIST_TITLE",
      payload: { listId, listTitle: title }
    });
  };

  deleteList = async () => {
    const { listId, list, dispatch } = this.props;

    if (window.confirm("Are you sure to delete this list?")) {
      dispatch({
        type: "DELETE_LIST",
        payload: {listId, cards: list.cards}
      });
    }
  };
  
  showPanel = (taskData) => {

    //const { dispatch } = this.props;

		//console.log('= TASK, DATA: ', taskData);
    //console.log('edit task - this.props: ', this.props);

    this.props.task(taskData)

    // dispatch({
    //   type: "SHOW_TASK_PANEL",
    //   payload: { task }
    // });

		//this.state.task.showTaskPanel(task)

	}

  render() {
    const { list } = this.props;

    //console.log('+++ LIST this.props.amIManager: ', this.props.amIManager);
    //console.log('+++ LIST STATE: ', this.state);
    //console.log('+++ amIManager: ', this.amIManager);

   // console.log('LIST this.state: ', this.state);
    //console.log('LIST this.props: ', this.props);

    //console.log('LIST this.props, CARD[63]: ', this.props.card[63]);

    // if (list.cards) {
    //   console.log('list.cards: ', list.cards);
    // } else {
    //   console.log('NO LIST CARDS');
    // }

    const { editingTitle, addingCard, title } = this.state;

    let commonPlannedTimeWeek = 0;

    //console.log('List.js - list: ', list);
        
    if (this.props.listId==4) {
      //console.log('list.cards 4: ', list.cards);

      list.cards.map(card => {
        let cardData = this.props.card[card]
        commonPlannedTimeWeek += cardData && cardData.taskData && cardData.taskData.planTime ? +cardData.taskData.planTime : 0
      })
    }

    //console.log('LIST RENDER currentWorkerId: ', this.props.currentWorkerId)

    //console.log('commonPlannedTime: ', commonPlannedTime);

    return (      
          <div className="List">
            {editingTitle ? (
              <ListEditor
                list={list}
                title={title}
                handleChangeTitle={this.handleChangeTitle}
                saveList={this.editListTitle}
                onClickOutside={this.editListTitle}
                deleteList={this.deleteList}
              />
            ) : (

              /* onClick={this.toggleEditingTitle} 
              (!this.props.currentWorkerId ? this.props.commonPlannedTimeMonth : null)
              */
              <>
              <div className="List-Title">
                {list.title}
              </div>
              {
              this.props.listId==2 || this.props.listId==4
              ?
              <div className="List-TimeCounter">
                {this.props.listId==4 && this.props.currentWorkerId ? <div className="ltc_normal">Норма<span>{this.props.currentWorkerHours}:00</span></div> : <div className="ltc_normal"></div>}
                <div className="ltc_actual">{this.props.listId==2 ? this.props.commonPlannedTimeMonth : (this.props.currentWorkerId && (+commonPlannedTimeWeek > +this.props.currentWorkerHours) ? <span style={{'color':'#F94848'}}>{commonPlannedTimeWeek}</span> : commonPlannedTimeWeek)}</div>
              </div>
              :
              null
              }
              </>
            )}

            <Droppable droppableId={list._id}>
              {(provided, _snapshot) => (
                <div ref={provided.innerRef} className={'Lists-Cards' +  (+list._id < 6 ? ' dummyBox' : '')}>

                  {
                  addingCard 
                  ? 
                  (
                    <CardEditor
                      onSave={this.addCard}
                      onCancel={this.toggleAddingCard}
                      adding
                    />
                  ) 
                  : 
                  (
                    +list._id < 6
                    ?
                    /* this.toggleAddingCard */
                    <div className="Toggle-Add-Card" onClick={() => this.showPanel({taskId: null, listId: list._id, userId: this.props.userId, cardTitle: null})}>
                    Добавить задачу
                    </div>
                    :
                    null
                  )
                  }

                  {list.cards &&
                    list.cards.map((cardId, index) => {
                      //console.log('listId: ',list._id);  
                      //console.log('cardId: ',cardId); 

                      return <Card
                        key={cardId}
                        cardId={cardId}
                        index={index}
                        listId={list._id}
                      />
                    }
                  )}

                  {provided.placeholder}

                  

                </div>
              )}
            </Droppable>
          </div>
        
    );
  }
}

function mapDispatchToProps(dispatch) {
	return {		
    task: (data) => dispatch(task(data)),
    dispatch: dispatch
	}
}

const mapStateToProps = (state, ownProps) => ({
  list: state.listsById[ownProps.listId],
  userId: state.auth.userId,
  card: state.cardsById, 
  currentWorkerId: state.app.currentWorkerId,
  currentWorkerHours: state.app.currentWorkerHours,
  //amIManager: state.app.amIManager,
  //amIExecutor: state.app.amIExecutor,
  //amIInvolved: state.app.amIInvolved
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
