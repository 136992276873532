import React, { Component } from "react";
import { connect } from 'react-redux'
import './Modal.css'

import Input from "../Input/Input";
import Select from "../Select/Select";
import Textarea from "../Textarea/Textarea";
import Button from "../Button/Button";

import { hideModal, saveModalData } from "../../../store/actions/modal";

class Modal extends Component {
	
	state = {
		isFormValid: false,
		formControls: {
			taskName: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskName : ''),
				type: 'text',
				label: 'Название',
				errorMessage: 'Укажите название задачи',
				valid: true,
				touched: false,
				validation: {
					required: true					
				}
			},
			taskGoalId: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskGoalId : ''),
				type: 'select',
				label: 'Задача',
				errorMessage: 'Выберите задачу',
				valid: false,
				touched: false,
				validation: {
					required: true
				}
			},
			taskClientId: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskClientId : ''),
				type: 'select',
				label: 'Клиент',
				errorMessage: 'Выберите клиента',
				valid: false,
				touched: false,
				validation: {
					required: true
				}
			},
			taskDescription: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskDescription : ''),
				type: 'text',
				label: 'Описание задачи',
				errorMessage: 'Укажите описание для задачи',
				valid: false,
				touched: false,
				validation: {
					required: true
				}
			},
			taskManagerId: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskManagerId : this.props.userId),
				type: 'select',				
				label: 'Постановщик',
				errorMessage: 'Укажите постановщика для задачи',
				valid: false,
				touched: false,
				disabled: true,
				validation: {
					required: true
				}
			},
			taskExecutorId: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskExecutorId : ''),	
				type: 'select',			
				label: 'Исполнитель',
				errorMessage: 'Укажите исполнителя для задачи',
				valid: false,
				touched: false,
				disabled: true,
				validation: {
					required: true
				}
			},
			taskParticipantsIds: {
				value: (this.props.data && this.props.data.taskData ? [this.props.data.taskData.taskParticipantsIds] : []),
				type: 'select',				
				label: 'Участники',
				errorMessage: 'Укажите участников для задачи',
				valid: false,
				touched: false,
				disabled: true,
				multiple: true,
				validation: {
					required: true
				}
			},
			taskStandardTime: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskStandardTime : ''),
				type: 'text',
				label: 'Норма, ч.',
				errorMessage: 'Укажите количество часов',
				valid: true,
				touched: false,
				validation: {
					required: true					
				}
			},			
			taskPlanTime: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskPlanTime : ''),
				type: 'text',
				label: 'План, ч.',
				errorMessage: 'Укажите количество часов',
				valid: true,
				touched: false,
				validation: {
					required: true					
				}
			},
			taskUsedTime: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskUsedTime : '0'),
				type: 'text',
				label: 'Затрачено, ч.',
				errorMessage: 'Укажите количество часов',
				valid: true,
				touched: false,
				disabled: true,
				validation: {
					required: true					
				}
			},			
			taskDeadLine: {
				value: (this.props.data && this.props.data.taskData ? this.props.data.taskData.taskDeadLine : ''),
				type: 'text',
				label: 'Срок сдачи (дедлайн) - формат [дд-мм-гггг чч:мм]',
				errorMessage: 'Укажите срок для задачи',
				valid: true,
				touched: false,
				validation: {
					required: true					
				}
			},			
			taskComment: {
				value: '',
				type: 'text',
				label: 'Комментарий',
				errorMessage: 'Укажите комментарий',
				valid: true,
				touched: false,
				validation: {
					required: true					
				}
			},
			setTime: {
				value: '',
				type: 'text',
				label: 'Добавление затраченных часов - укажите часы (целое или дробное число, например 7,5)',
				errorMessage: 'Укажите потраченное время, ч',
				valid: true,
				touched: false,				
				validation: {
					required: true					
				}
			}
		}
	}	

	saveCardData = () => {

		//console.log('===Modal props: ', this.props);

		this.setState({
			...this.state, isFormValid: true
		})	

		this.props.saveModalData(
			this.props.cardId,
			this.props.name,
			this.props.data,
			this.state.formControls,
			this.props.userId
		)	

	}

	hideModal = () => {
		//this.setState({ hover: false }); 	
		// const { card } = this.props;	
		// console.log('this.props.card.text: ', this.props.card.text);	
		// this.props.modal(card._id, this.props.card.text)	
		// dispatch({
		//   type: "SHOW_MODAL",
		//   payload: { cardId: card._id, userId }
		// });

		this.props.hideModal()
	
	}  
	
	submitHandler = event => {
		event.preventDefault()
	}

	onChangeHandler = (event, controlName) => {

		//console.log('event: ', event);
		
		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }		


		//console.log('event.target: ', event.target);

		if (event.target.selectedOptions) {
			let value = Array.from(
				event.target.selectedOptions,
				(option) => option.value
			);
			control.value = value  
		} else {
			control.value = event.target.value
		}

		


		control.touched = true
		control.valid = true //this.validateControl(control.value, control.validation)

		formControls[controlName] = control
		
		let isFormValid = true		

		this.setState({
		 	formControls, isFormValid
		})	
        
	}

	componentDidUpdate(prevProps, prevState) {

		//console.log('COMPONENT DID UPADTE 1');	

		if (this.props.data) {
			if (this.props.data !== prevProps.data) {

				//console.log('COMPONENT DID UPADTE 2');
				
				const formControls = { ...this.state.formControls }

				Object.keys(formControls).forEach(name => {
					formControls[name].value = this.props.data.taskData[name]
				})
		
				this.setState({
					formControls
				})	

				// ваш код с вызовом setState
			}
		}
		
	}

  render() {	

    //console.log('Modal.js props: ', this.props);
	//console.log('Modal.js state: ', this.state);	

	if (this.props.show) {
		document.getElementsByTagName('body')[0].classList.add('modal-open');		
	} else {
		document.getElementsByTagName('body')[0].classList.remove('modal-open');	
	}	

    return (
      <React.Fragment>
        {this.props.show && (
			<div className="modal fade">
				<div className="modal-dialog modal-fullscreen">
					<div className="modal-content">	
						<div className="modal-body">					
							<div className="container">						
								<div className="row mb_row">
									<div className="col-12 col-lg-10 offset-lg-1">
										<div className="row">
											<div className="modal_box_content col-12 col-lg-10 offset-lg-1">
												<button className="mb_close" onClick={this.hideModal}></button>										
												<div className="modal_form_title">{this.props.name}</div>																	
												<div className="modal_form_itself">

												<form onSubmit={this.submitHandler} className="modalForm">

													<Input 
														key="1"
														type="text"
														value={this.state.formControls['taskName'].value}												
														label="Название"
														onChange={event => this.onChangeHandler(event, 'taskName')}			
													/>

													<Select 
														key="2"													
														value={this.state.formControls['taskGoalId'].value}													
														label="Задача"
														onChange={event => this.onChangeHandler(event, 'taskGoalId')}
														options={this.props.data.goals}			
													/>

													<Select 
														key="3"													
														value={this.state.formControls['taskClientId'].value}														
														label="Клиент"
														onChange={event => this.onChangeHandler(event, 'taskClientId')}	
														options={this.props.data.clients}		
													/>

													<Textarea 
														key="4"													
														value={this.state.formControls['taskDescription'].value}		
														placeholder="Описание задачи"													
														label="Описание задачи"
														onChange={event => this.onChangeHandler(event, 'taskDescription')}			
													/>

													<Input 
														key="5"
														type="text"
														value={this.state.formControls['taskManagerId'].value}
														disabled={this.state.formControls['taskManagerId'].disabled}											
														label="Постановщик"
														onChange={event => this.onChangeHandler(event, 'taskManagerId')}			
													/>

													<Select 
														key="6"													
														value={this.state.formControls['taskExecutorId'].value}													
														label="Исполнитель"
														onChange={event => this.onChangeHandler(event, 'taskExecutorId')}	
														options={this.props.data.participants}		
													/>

													<Select 
														key="7"	
														type="multiple"												
														value={this.state.formControls['taskParticipantsIds'].value}												
														label="Участники"
														onChange={event => this.onChangeHandler(event, 'taskParticipantsIds')}
														options={this.props.data.participants}				
													/>

													<Input 
														key="8"
														type="text"
														value={this.state.formControls['taskStandardTime'].value}													
														label="Норма, ч."
														onChange={event => this.onChangeHandler(event, 'taskStandardTime')}			
													/>

													<Input 
														key="9"
														type="text"
														value={this.state.formControls['taskPlanTime'].value}													
														label="План, ч."
														onChange={event => this.onChangeHandler(event, 'taskPlanTime')}			
													/>

													<Input 
														key="10"
														type="text"
														value={this.state.formControls['taskUsedTime'].value}	
														disabled={this.state.formControls['taskManagerId'].disabled}												
														label="Всего времени затрачено, ч."
														onChange={event => this.onChangeHandler(event, 'taskUsedTime')}			
													/>

													<Input 
														key="11"
														type="text"
														value={this.state.formControls['taskDeadLine'].value}													
														label="Срок сдачи (дедлайн) - формат [дд-мм-гггг чч:мм]"
														onChange={event => this.onChangeHandler(event, 'taskDeadLine')}			
													/>

													<Input 
														key="12"
														type="text"
														value={this.state.formControls['setTime'].value}																								
														label="Добавление затраченных часов - укажите часы (целое или дробное число, например 7,5)"
														onChange={event => this.onChangeHandler(event, 'setTime')}			
													/>

													<Textarea 
														key="13"													
														value={this.state.formControls['taskComment'].value}		
														placeholder="Комментарий"													
														label="Комментарий"
														onChange={event => this.onChangeHandler(event, 'taskComment')}			
													/>

													<div className="commentDiv" dangerouslySetInnerHTML={(this.props.data.taskData.taskCommentLog ? {__html: this.props.data.taskData.taskCommentLog.replaceAll("\n", "<br />")} : null)} />

													<div className="statusDiv" dangerouslySetInnerHTML={(this.props.data.taskData.taskStatusLog ? {__html: this.props.data.taskData.taskStatusLog.replaceAll("\n", "<br />")} : null)} />

													<Button 
														type="save" 
														onClick={this.saveCardData}														
													>
														Сохранить
													</Button>

													<Button 
														type="cancel" 
														onClick={this.hideModal}												
													>
														Отмена
													</Button>

												</form>

												</div>	
											</div>	
										</div>	
									</div>						
								</div>						
							</div>		
						</div>	
					</div>
				</div>
			</div>	         
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
	return {
		hideModal: () => {
			dispatch(hideModal())
		},

		saveModalData: (cardId, cardText, formData, fields, userId) => dispatch(saveModalData(cardId, cardText, formData, fields, userId))		

	}
}

// function mapStateToProps(state) {	

// 	return {
// 		isAuthNotice: state.auth.authFail
// 	}

// }

export default connect(null, mapDispatchToProps)(Modal)