import axios from 'axios'

export function task(data) {

	//console.log('TASK -task- ACTION');

	return dispatch => {

		// const modal = {
		// 	cardId,				
		// 	cardText,
		// 	formData			
		// }

		//console.log('TASK ACTION 2, data: ', data);

		dispatch(showTaskPanel(data))
	}
}
/*
export function hModal() {

	console.log('HIDE MODAL ACTION');

	return dispatch => {

		const modal = {				
		}

		console.log('HIDEW MODAL ACTION 2, modal: ', modal);

		dispatch(hideModal(modal))
	}
}

export function showModal(data) {
	return {
		type: 'SHOW_MODAL',
		data
	}
}
*/

export function showTaskPanel(data) {

	//console.log('=== ACTIONS showTaskPanel: ', data);

	let panel = document.getElementById('addTaskSidePanel');
	if (!panel.classList.contains('active')) panel.classList.toggle('active');

	return {
		type: 'SHOW_TASK_PANEL',
		data
	}

	//if (data) this.cancelEditTask()
	//console.log('addPlanTask Clicked, newGoal: ', newGoal);
	//console.log('state.goal: ', this.state.goal);
}

export function hideTaskPanel() {

	//console.log('hideTaskPanel ACTION FIRED ');

	let panel = document.getElementById('addTaskSidePanel');
	panel.classList.remove('active');

	return {
		type: 'HIDE_TASK_PANEL'		
	}
}

export function authSuccess(data) {
	return {
		type: 'AUTH_SUCCESS',
		data
	}
}

export function authFail() {
	return {
		type: 'AUTH_FAIL'
	}
}

export function updateTasksData(userHash, filterData = false, keepPopupOpened = false) {

	//console.log('userHash: ', userHash)
	console.log('updateTasksData = filterData: ', filterData)
	//console.log('keepPopupOpened: ', keepPopupOpened)
	//console.log('updateTasksData');

	return async dispatch => {

		const authData = {
			userHash,
			isNaked: 1,
			nc_ctpl: 167
		}

		if (filterData && filterData.amIManager) {
			//console.log('updateTasksData -> filterData.amIManager: ', filterData.amIManager)

			authData.amIManager = 1
		}

		if (filterData && filterData.amIExecutor) {
			//console.log('updateTasksData -> filterData.amIExecutor: ', filterData.amIExecutor)

			authData.amIExecutor = 1
		}

		if (filterData && filterData.amIInvolved) {
			//console.log('updateTasksData -> filterData.amIInvolved: ', filterData.amIInvolved)

			authData.amIInvolved = 1
		}

		if (filterData && filterData.taskClient) {
			//console.log('updateTasksData -> filterData.taskClient: ', filterData.taskClient)

			authData.taskClient = filterData.taskClient
		}

		if (filterData && filterData.currentWorkerId) {
			//console.log('updateTasksData -> filterData.currentWorkerId: ', filterData.currentWorkerId)

			authData.currentWorkerId = filterData.currentWorkerId
		}

		let response = null
		const params = (Object.entries(authData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/check-auth/${params}`)
			const data = response.data
			//localStorage.setItem('token', data.token)
			//console.log('### updateTasksData 1', response.data)

			if (data.error) {
				//console.log('1.1');
				dispatch(authFail())
			} else {
				//console.log('1.2');
				//console.log('store.getState(): ', store.getState())
				//store.getState().board.listsById.length
				//console.log('>>>>>> data.cardsById: ', ...data.cardsById);

				const data2 = {						
					listsById: data.listsById		
				}				

				//console.log('>>>>>> data.listsById: ', data2);

				const data3 = {						
					cardsById: data.cardsById
				}
				
				dispatch(updateLists(data2))

				dispatch(updateCards(data3))	
				
				//if (!keepPopupOpened) {
				//	console.log('TASK, hide popup:  ', keepPopupOpened);

					dispatch(hideTaskPanel())
				//} else {
				//	console.log('TASK, keepPopupOpened:  ', keepPopupOpened);
				//}

				console.log('+ ACTION: updateTasksTable');
				dispatch(updateTasksTable())

			}

		} catch (e) {
			//console.log('2', e)

			dispatch(authFail())
		}

	}	
}

export function saveTaskData(taskId, cardText, formData, fields, userId, listId) {

	// console.log('ACTION saveModalData, cardId: ', cardId);	
	 //console.log('ACTION saveTaskData, formData: ', formData);	
	 //console.log('ACTION saveTaskData, fields: ', fields);
	// const modal = {
	// 	cardId,	
	// 	cardText,		
	// 	formData, 
	// 	fields			
	// }

	// return {
	// 	type: 'SAVE_MODAL_DATA',
	// 	modal
	// }

	return async dispatch => {	

		//console.log('1 fields: ', fields);

		let key_array = {}

		Object.keys(fields).map( key => {
			if (key==='f_taskParticipantsIds' && fields[key].value.length > 0) {
				//const pIds = fields[key].value
				const pIds = fields[key].value
				let pIds_array = []
				pIds_array = pIds.map(pId => (pId.participant_id))
				fields[key].value = pIds_array.join(',')
			}
			return null
		})

		Object.keys(fields).map( key => {
			if (key==='f_deadLine') {
				const date = fields[key].value
				fields[key].value = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+(date.getHours()<10 ? '0'+date.getHours() : date.getHours())+':'+(date.getMinutes()<10 ? '0'+date.getMinutes() : date.getMinutes())+':'+(date.getSeconds()<10 ? '0'+date.getSeconds() : date.getSeconds())
			}
			return null
		})

		// Object.keys(fields).map( key => {
		// 	console.log('-1: '+ fields[key].value)
		// 	console.log('-2: '+ fields[key].value.replace(/"/g, '\\"'))
		// })
		
		Object.keys(fields).map( key => {
				if (fields[key].value.length > 0) {
					console.log('FIELD 1: ', fields[key].value)
					console.log('FIELD 2: ', fields[key].value.replace(/\r/g, '\\r').replace(/\t/g, '\\t').replace(/\n/g, '\\n').replace(/"/g, '\\"'))	

					key_array[key] = encodeURIComponent(fields[key].value.replace(/\r/g, '\\r').replace(/\t/g, '\\t').replace(/\n/g, '\\n').replace(/"/g, '\\"'))	

					//key_array[key] = encodeURIComponent(fields[key].value.replace(/"/g, '\\"'))			
					//key_array[key] = encodeURIComponent(fields[key].value.replace(/[^a-zа-яё0-9\s]/gi, ' '))

					//key_array[key] = encodeURIComponent(fields[key].value.replace(/(["'\/])/g, "\\"+"$1"))

				} else {
					key_array[key] = encodeURIComponent(fields[key].value)
				}
				return null
			}			
		)		

		//console.log('key_array: ', key_array);
		// const authData = {			
		// 	payload: key_array			
		// }

		let response = null
		// const params = (Object.entries(authData)
		// 	.reduce((acc,e,i) => 
		// 		`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
		// 	'')
	    // )

		let param2 = JSON.stringify(key_array)

		//console.log('param2', param2)

		try {			
			response = await axios.get(`https://task.apriori-marketing.ru/tasks/?taskId=${taskId}&payload=${param2}&isNaked=1&nc_ctpl=177&userId=${userId}&listId=${listId}`)
			const data = response.data
			
			//console.log('task 4', response.data)

			if (data.error) {
				//console.log('task - 4.1');
				dispatch(saveFail())
			} else {
				//console.log('task 41.2');
				dispatch(saveSuccess(data))
			}

		} catch (e) {
			//console.log('task 4.3', e)

			dispatch(saveFail())
		}
		/*

		const updateData = {
			userId,
			isNaked: 1,
			nc_ctpl: 182
		}

		let updateResponse = null
		const updateParams = (Object.entries(updateData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
	    )

		try {			
			updateResponse = await axios.get(`https://task.apriori-marketing.ru/check-auth/${updateParams}`)
			const updateData = updateResponse.data
			//localStorage.setItem('token', data.token)
			console.log('1', updateResponse.data)

			if (updateData.error) {
				//console.log('1.1');
				//dispatch(authFail())
			} else {
				//console.log('1.2');
				//console.log('store.getState(): ', store.getState())
				//store.getState().board.listsById.length
				//console.log('>>>>>> data.cardsById: ', ...data.cardsById);

				const data2 = {						
					listsById: updateData.listsById		
				}				

				//console.log('>>>>>> data.listsById: ', data2);

				const data3 = {						
					cardsById: updateData.cardsById
				}
				
				dispatch(updateLists(data2))
				dispatch(updateCards(data3))				
				//dispatch(authSuccess(data))
			}

		} catch (e) {
			//console.log('2', e)

			//dispatch(authFail())
		}
		
		*/

	}
}

export function updateLists(data) {

	//console.log('ACTION, updateLists', data);

	return {
		type: 'UPDATE_LISTS',
		data
	}
}

export function updateCards(data) {

	//console.log('ACTION, updateCards', data);

	return {
		type: 'UPDATE_CARDS',
		data
	}
}

export function saveSuccess(data) {
	return {
		type: 'SAVE_SUCCESS',
		data
	}
}

export function saveFail() {
	return {
		type: 'SAVE_FAIL'
	}
}

export function updateTasksTable() {
	//console.log('++ACTION, updateTasksTable');

	return {
		type: 'UPDATE_TASKS_TABLE'
	}
}

export function stopUpdateTaskTable() {
	//console.log('ACTION, stopUpdateTaskTable');

	return {
		type: 'STOP_UPDATE_TASKS_TABLE'		
	}
}

export function setTaskTableFilterClient(data) {

	//console.log('=== task ACTION, setTaskTableFilterClient', data);

	return {
		type: 'SET_TASKTABLE_FILTER_CLIENT',
		data
	}
}