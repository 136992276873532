//import "../styles/MonthListNew.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { updateWorkerId } from '../store/actions/app'

//const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

class WorkerListNew extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  currentWorkerId: 0,
		  currentWorkerHours: 0,
		  workersArray: []
		};
	}

	componentDidMount() {

		const { userId } = this.props;	
		
		document.addEventListener("mousedown", this.handleClick, false);
		
		const taskData = {	
			userId,      
			isNaked: 1,
			nc_ctpl: 192
		}	
		
		const params = (Object.entries(taskData)
			.reduce((acc,e,i) => 
				`${acc}${i >0 ? '&' : '?' }${e[0]}=${encodeURIComponent(e[1])}`,
			'')
		)   
					
		axios.get(`https://task.apriori-marketing.ru/users/${params}`)
		//.then(res => res.json())
		.then(
			(result) => {

				console.log('WORKERS: ', result);

				this.setState({
					isLoaded: true,
					workersArray: result.data
				});
				},
				// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
				// чтобы не перехватывать исключения из ошибок в самих компонентах.
				(error) => {
				this.setState({
					isLoaded: true,
					error
				});
			}
		)		

	}
	
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		//console.log('this.inputRef.current: ', this.inputRef.current);
		//console.log('e.target: ', e.target);

		//if (this.inputRef.current === e.target) {
		if (e.target.closest('.cpfMenu')) {	
			//console.log('handleClick 1');
			return;
		}
		//console.log('handleClick 2');
		this.hideDropDown();
	}

	hideDropDown = ()  => {		
		Object.values(document.querySelectorAll('.cpFilter')).map( item => (item.classList.remove('menuDropDown')))
	}

	toggleDropDown = event => {
		event.target.closest('.cpFilter').classList.toggle('menuDropDown')		
	}

	
	setValue = (event, target) => {
		// console.log('EVENT: ', event)
		// console.log('EVENT TARGET: ', event.target)
		// console.log('Text: ', event.target.innerText)
		// console.log('Data: ', event.target.getAttribute('data-month'))

		let control_val = event.target.getAttribute('data-worker-id')
		let control_text = event.target.innerText

		console.log('setValue, control_val: ', control_val);
		console.log('control_text: ', control_text);


		let workerHours = 0;

		if (control_val && this.state.workersArray) {
			this.state.workersArray.map((worker) => {

				//console.log('this.state.currentMonth: ', this.state.currentMonth);
				//console.log('юзер: ', worker);
				//console.log('control_val: ', control_val);
				
				if (+control_val===+worker.id) {
					//console.log('match: ', index);
					//showValue = 'Исполнитель: ' + worker.name

					workerHours = worker.hours ? worker.hours : 0;
				}
				
			})
		}



		this.setState({
			...this.state,
			currentWorkerId: +control_val,
			currentWorkerHours: workerHours     
		},
		() => {
			this.props.updateWorkerId(control_val, workerHours)
		})



		




		this.props.setCurrentWorkerId(control_val, workerHours)

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfSelected').innerText = control_text;

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		//this.props.onChangeHandlerList(control_val, 'f_planMonth')
		this.hideDropDown();
		event.preventDefault();	
	}

	setMonthValueChange = (event, target) => {
		//console.log('setMonthValueChange === EVENT: ', event.target);
	}

	setDefaultValue = (event) => {
		this.setState({
			...this.state,
			currentWorkerId: 0      
		},
		() => {
			this.props.updateWorkerId(0)
			this.props.setCurrentWorkerId(0)
		})

		//let parent = 
		//event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField').value = control_val;
		event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfSelected').innerText = 'Все исполнители';

		//console.log('control_val: ', control_val)
		//console.log('Parent event: ', event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.cpfHiddenField'))

		//this.props.onChangeHandlerList(control_val, 'f_planMonth')
		this.hideDropDown();
		event.preventDefault();	
	}
		

	render() {

		const { error, isLoaded, workersArray } = this.state;	
		
		//const cls = []

		let showValue = 'Все исполнители';

		//console.log('currentMonth - PROPS: ', this.props);

		let thisWorkerId = this.props.currentWorkerId ? this.props.currentWorkerId : 0
		
		console.log('thisWorkerId: ', thisWorkerId);

		//let realYear = new Date().getFullYear()	
		
		
		
		//console.log('currentYear - STATE: ', this.state);
		
		if (this.props.currentWorkerId && this.state.workersArray) {
			this.state.workersArray.map((worker) => {

				//console.log('this.state.currentMonth: ', this.state.currentMonth);
				//console.log('index: ', index);
				
				if (+thisWorkerId===+worker.id) {
					//console.log('match: ', index);
					showValue = 'Исполнитель: ' + worker.name
				}
				
			})
		}
		
		

		//if (error) {
		//	return <div className={cls.join(' ')} key='0'>Ошибка загрузки данных по месяцам. (1)</div>;
		//} else if (!isLoaded) {
		//	return <div className={cls.join(' ')} key='0'>Загружаем данные по месяцам... (2)</div>;
		//} else {

		/*
<div className={cls.join(' ')}>
					<div className="spbHeader">Выбрать месяц</div>
					<div className="spbBody">
</div>
				</div>


				 newFilter

<div class="cpFilter">
	<div class="cpfSelected">Все задачи</div>
	<div class="cpfMenuShell">
		<div class="cpfMenu">
			<div class="cpfmHeader canHover">Все задачи</div>
			<div class="cpfmBody">
				<div class="cpfmBodyBlock">
					<div class="cpfmBodyBlockRow">
						<ul class="boardMenuList">
							<li class="">Я постановщик</li>
							<li class="">Я исполнитель</li>
							<li class="">Я участник</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
*/

			return (
				
				<div className="cpFilter newFilter">
					<input className="cpfHiddenField" type="hidden" name={this.props.name} value={this.props.value} />
					<div className="cpfSelected" onClick={(event) => this.toggleDropDown(event)}>{showValue}</div>
					<div className="cpfMenuShell">
						<div className="cpfMenu">
							<div className="cpfmHeader canHover" onClick={(event) => this.setDefaultValue(event)}>Все исполнители</div>
							<div className="cpfmBody">
								<div className="cpfmBodyBlock">
									<div className="cpfmBodyBlockRow">
										<ul className="boardMenuList">{
											this.state.workersArray.map((worker, index) => (
												<li key={index} data-worker-id={worker.id} className={+thisWorkerId===+worker.id ? 'active' : ''} onClick={this.setValue}>{worker.name}</li>
											))
										}</ul>
									</div>
								</div>
							</div>		
						</div>
					</div>
				</div>
					
			);
			
		//}			
		
	}

}

function mapDispatchToProps(dispatch) {
	return {
		updateWorkerId: (currentWorkerId, currentWorkerHours) => dispatch(updateWorkerId(currentWorkerId, currentWorkerHours)),
   		dispatch: dispatch
	}
}

const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	currentWorkerId: state.app.currentWorkerId,
	currentWorkerHours: state.app.currentWorkerHours
});  

export default connect(mapStateToProps, mapDispatchToProps)(WorkerListNew);