import "../styles/AddGoalPanelWrapper.css";

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ClientList from "./ClientList";
import JobCategoryList from "./JobCategoryList";
import MonthList from "./MonthList";

import Input from "./UI/Input/Input";
import Textarea from "./UI/Textarea/Textarea";
import Checkbox from "./UI/Checkbox/Checkbox";
/*import Select from "../UI/Select/Select";
import Textarea from "../UI/Textarea/Textarea";
import Button from "../UI/Button/Button";*/

import SimpleList from "./SimpleList";

import { saveGoalData } from "../store/actions/goal";

import { setTaskTableFilterClient } from "../store/actions/task"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)

class AddGoalPanelWrapper extends Component {

	constructor(props) {
		super(props);

		/*this.state = {
		  error: null,
		  isLoaded: false,
		  goal: []
		};*/

		//console.log('CONSTRUCTOR');

		this.state = {
			isFormValid: false,
			formControls: {
				f_goalTitle: {
					value: '',
					type: 'text',
					label: '',
					errorMessage: 'Укажите название задачи',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},				
				f_isFix: {
					value: '',
					type: 'F',
					label: 'Фикс',
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},
				f_isRepeat: {
					value: '',
					type: 'R',
					label: 'Повтор',
					errorMessage: 'Укажите значение',
					valid: true,
					touched: false,
					validation: {
						required: false
					}
				},								
				f_sum: {
					value: '',
					type: 'text',
					label: 'Сумма, руб.',
					errorMessage: 'Укажите сумму',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},
				f_sumCorrected: {
					value: props.goalData && props.goalData.sumCorrected ? props.goalData.sumCorrected : '',
					type: 'text',
					label: 'Скорректированная сумма, руб.',
					errorMessage: 'Укажите скорректированную сумму',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},							
				f_planTime: {
					value: '',
					type: 'text',
					label: 'План, ч.',
					errorMessage: 'Укажите количество часов',
					placeholder: '0',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},							
				f_deadLine: {
					value: this.getLastDayOfMonth(),
					type: 'text',
					label: 'Дедлайн',
					placeholder: 'гггг-мм-дд чч:мм:сс',
					errorMessage: 'Укажите срок для задачи',
					valid: true,
					touched: false,
					validation: {
						required: false					
					}
				},				
				f_goalRepeatInterval: {
					value: '',
					type: 'text',
					label: 'Интервал',
					errorMessage: 'Укажите интервал для периодической задачи (число дней)',
					placeholder: '0',
					valid: true,
					touched: false,				
					validation: {
						required: false					
					}
				},			
				f_goalComment: {
					value: '',
					type: 'text',
					label: 'Комментарий',
					errorMessage: 'Укажите комментарий',
					valid: true,
					touched: false,
					validation: {
						required: false					
					}
				},
				f_taskResult: {
					value: props.goalData && props.goalData.taskResult ? props.goalData.taskResult : '',
					type: 'text',
					label: 'Ссылка на результат',
					errorMessage: 'Укажите cсылку на результат (https://...)',
					valid: true,
					touched: false,
					validation: {
						required: false					
					}
				},
				f_planMonth: {
					value: new Date(),
					type: 'hidden',
					label: 'Месяц',
					errorMessage: 'Укажите месяц задачи',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},
				f_clientId: {
					value: '',
					type: 'hidden',
					label: 'Клиент',
					errorMessage: 'Укажите клиента',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				},
				f_goalCategoryId: {
					value: '',
					type: 'hidden',
					label: 'Категория работ',
					errorMessage: 'Укажите категорию',
					valid: true,
					touched: false,
					validation: {
						required: true					
					}
				}	
			},
			isEditGoal: false,
			oldEditGoalId: 0,			
			processDate: this.processDate,
			processDateTime: this.processDateTime,
			getLastDayOfMonth: this.getLastDayOfMonth,
			goalClientId: false
		}

		//this.onChangeHandler = this.onChangeHandler.bind(this)	

	}

	getLastDayOfMonth = (month = null, year = null) => {
		if (!month) {
			let currentDate = new Date()
			return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
		} else {
			return new Date(year, month + 1, 0);
		} 			
	}

	processDate = (date) => {
		if (date.includes('-')) {
			const date_array = date.split('-')
			return new Date(date_array[0], date_array[1]-1, date_array[2])
		}
		return date
	}

	processDateTime = (date) => {
		if (date.includes('-') && date.includes(':')) {
			const split_array = date.split(' ')
			const date_array = split_array[0].split('-')
			const time_array = split_array[1].split(':')
			return new Date(date_array[0], date_array[1]-1, date_array[2], time_array[0], time_array[1], time_array[2])
		}
		return date
	}

	static getDerivedStateFromProps(props, state) {

		//console.log('1111111111 getDerivedStateFromProps state: ', state);
		//console.log('1111111111 getDerivedStateFromProps props: ', props);


		//console.log('state.isEditGoal: ', state.isEditGoal);
		//console.log('state.oldEditGoalId: ', state.oldEditGoalId);
		//console.log('props.goalData.id: ', props.goalData.id);

		if (!props.goalData) return null;

		if (!state.isEditGoal || (state.oldEditGoalId != props.goalData.id)) {

			//console.log('getDerivedStateFromProps. UPDATE TA-S 2');
			//state.adjustTextareas();


			if ((props.goalData && props.goalData.id) || (!props.goalData.id && state.oldEditGoalId)){

				//const formControls = { ...state.formControls }

				//console.log('22222222 getDerivedStateFromProps - Значения ИЗ PROPS !!!!');

				//Change in props
				return {
					oldEditGoalId: props.goalData.id,
					isEditGoal: true,
					formControls: {
						f_goalTitle: {
							value: props.goalData && props.goalData.title ? props.goalData.title : '',
							type: 'text',
							label: '',
							errorMessage: 'Укажите название задачи',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},				
						f_isFix: {
							value: props.goalData && props.goalData.isFix ? props.goalData.isFix : '0',
							type: 'F',
							label: 'Фикс',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},
						f_isRepeat: {
							value: props.goalData && props.goalData.isRepeat ? props.goalData.isRepeat : '0',
							type: 'R',
							label: 'Повтор',
							errorMessage: 'Укажите значение',
							valid: true,
							touched: false,
							validation: {
								required: false
							}
						},								
						f_sum: {
							value: props.goalData && props.goalData.sum ? props.goalData.sum : '',
							type: 'text',
							label: 'Сумма, руб.',
							errorMessage: 'Укажите сумму',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},	
						f_sumCorrected: {
							value: props.goalData && props.goalData.sumCorrected ? props.goalData.sumCorrected : '',
							type: 'text',
							label: 'Скорректированная сумма, руб.',
							errorMessage: 'Укажите скорректированную сумму',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},							
						f_planTime: {
							value: props.goalData && props.goalData.timePlan ? props.goalData.timePlan : '',
							type: 'text',
							label: 'План, ч.',
							errorMessage: 'Укажите количество часов',
							placeholder: '0',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},							
						f_deadLine: {
							value: props.goalData && props.goalData.deadline ? state.processDate(props.goalData.deadline) : (props.goalData && props.goalData.planMonth ? state.getLastDayOfMonth(state.processDate(props.goalData.planMonth).getMonth(), state.processDate(props.goalData.planMonth).getFullYear()) : state.getLastDayOfMonth()),
							type: 'text',
							label: 'Дедлайн',
							placeholder: 'гггг-мм-дд чч:мм:сс',
							errorMessage: 'Укажите срок для задачи',
							valid: true,
							touched: false,
							validation: {
								required: false					
							}
						},				
						f_goalRepeatInterval: {
							value: props.goalData && props.goalData.repeatInterval ? props.goalData.repeatInterval : '',
							type: 'text',
							label: 'Интервал',
							errorMessage: 'Укажите интервал для периодической задачи (число дней)',
							placeholder: '0',
							valid: true,
							touched: false,				
							validation: {
								required: false					
							}
						},			
						f_goalComment: {
							value: props.goalData && props.goalData.comment ? props.goalData.comment : '',
							type: 'text',
							label: 'Комментарий',
							errorMessage: 'Укажите комментарий',
							valid: true,
							touched: false,
							validation: {
								required: false					
							}
						},
						f_taskResult: {
							value: props.goalData && props.goalData.taskResult ? props.goalData.taskResult : '',
							type: 'text',
							label: 'Ссылка на результат',
							errorMessage: 'Укажите cсылку на результат (https://...)',
							valid: true,
							touched: false,
							validation: {
								required: false					
							}
						},
						f_planMonth: {
							value: props.goalData && props.goalData.planMonth ? state.processDate(props.goalData.planMonth) : new Date(),
							type: 'hidden',
							label: 'Месяц',
							errorMessage: 'Укажите месяц задачи',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},
						f_clientId: {
							value: props.goalData && props.goalData.clientId ? props.goalData.clientId : '',
							type: 'hidden',
							label: 'Клиент',
							errorMessage: 'Укажите клиента',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						},
						f_goalCategoryId: {
							value: props.goalData && props.goalData.categoryId ? props.goalData.categoryId : '',
							type: 'hidden',
							label: 'Категория работ',
							errorMessage: 'Укажите категорию',
							valid: true,
							touched: false,
							validation: {
								required: true					
							}
						}	
					}
				}	
			} else {

				//console.log('333333333 getDerivedStateFromProps - не меняем STATE');

				return null

				// return {
				// 	oldEditGoalId: 0,
				// 	isEditGoal: false,
				// 	formControls: {
				// 		f_goalTitle: {
				// 			value: '',
				// 			type: 'text',
				// 			label: '',
				// 			errorMessage: 'Укажите название задачи',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		},				
				// 		f_isFix: {
				// 			value: '',
				// 			type: 'F',
				// 			label: 'Фикс',
				// 			errorMessage: 'Укажите значение',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: false
				// 			}
				// 		},
				// 		f_isRepeat: {
				// 			value: '',
				// 			type: 'R',
				// 			label: 'Повтор',
				// 			errorMessage: 'Укажите значение',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: false
				// 			}
				// 		},								
				// 		f_sum: {
				// 			value: '0',
				// 			type: 'text',
				// 			label: 'Сумма, руб.',
				// 			errorMessage: 'Укажите сумму',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		},							
				// 		f_planTime: {
				// 			value: '0',
				// 			type: 'text',
				// 			label: 'План, ч.',
				// 			errorMessage: 'Укажите количество часов',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		},							
				// 		f_deadLine: {
				// 			value: '',
				// 			type: 'text',
				// 			label: 'Дедлайн',
				// 			placeholder: 'гггг-мм-дд чч:мм:сс',
				// 			errorMessage: 'Укажите срок для задачи',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: false					
				// 			}
				// 		},				
				// 		f_goalRepeatInterval: {
				// 			value: '0',
				// 			type: 'text',
				// 			label: 'Интервал',
				// 			errorMessage: 'Укажите интервал для периодической задачи (число дней)',
				// 			valid: true,
				// 			touched: false,				
				// 			validation: {
				// 				required: false					
				// 			}
				// 		},			
				// 		f_goalComment: {
				// 			value: '',
				// 			type: 'text',
				// 			label: 'Комментарий',
				// 			errorMessage: 'Укажите комментарий',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: false					
				// 			}
				// 		},
				// 		f_planMonth: {
				// 			value: '',
				// 			type: 'hidden',
				// 			label: 'Месяц',
				// 			errorMessage: 'Укажите месяц задачи',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		},
				// 		f_clientId: {
				// 			value: '',
				// 			type: 'hidden',
				// 			label: 'Клиент',
				// 			errorMessage: 'Укажите клиента',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		},
				// 		f_goalCategoryId: {
				// 			value: '',
				// 			type: 'hidden',
				// 			label: 'Категория работ',
				// 			errorMessage: 'Укажите категорию',
				// 			valid: true,
				// 			touched: false,
				// 			validation: {
				// 				required: true					
				// 			}
				// 		}	
				// 	}
				// }



			}

		} else {
			//console.log('44444 getDerivedStateFromProps - Не меняем значения STATE');
		
		}
        return null; // No change to state
    }

	/*numberFormat = str => {		

		str = str.toString();

		if (str) {		
			return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
		} else {
			return str
		}
	}*/

	createGoal = () => {

	}

	submitHandler = event => {
		event.preventDefault()
	}

	onChangeHandlerList = (val, controlName, client=null) => {

		console.log('GOAL - onChangeHandlerList FIRED value: ', val);
		//console.log('onChangeHandlerList FIRED controlName: ', controlName);	


		if (controlName == 'f_clientId' && client) {
			const { dispatch } = this.props;

			//dispatch({type: "SET_TASKTABLE_FILTER_CLIENT", payload: {client: client}})

			console.log('---- onChangeHandlerList -----', client);

			dispatch(setTaskTableFilterClient(client))

			const formControls = { ...this.state.formControls }
			const control = { ...formControls[controlName] }

			
			
			control.value = client.id
			formControls['f_clientId'] = control			 

			this.setState({
				...this.state,
				formControls
		    })
			


		}


		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }
		
		control.value = val
		control.touched = true
		control.valid = true //this.validateControl(control.value, control.validation)

		formControls[controlName] = control
		
		let isFormValid = true		

		this.setState({
		 	formControls, isFormValid
		})
	}

	onChangeHandler = (event, controlName, controlDate) => {
		//console.log('onChangeHandler FIRED event: ', event);
		//console.log('onChangeHandler FIRED event.target.value: ', event.target.value);
		//console.log('onChangeHandler FIRED controlName: ', controlName);

		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }	

		if (controlName === 'f_deadLine' || controlName=== 'f_planMonth' || controlName=== 'f_goalRepeatInterval') {
			control.value = event
			//console.log('onChangeHandler event: ', event);
			//console.log('onChangeHandler controlName: ', controlName);
			//console.log('onChangeHandler DATE: ', controlDate);

			if (controlName=== 'f_planMonth') {

				//let sd_formControls = { ...this.state.formControls }
				let control_deadLine = { ...formControls['f_deadLine'] }
				let control_planMonth = { ...formControls['f_planMonth'] }

				//console.info('control_deadLine EVENT: ', event)
				//console.info('control_deadLine: ', control_deadLine);
				
				if (control_planMonth) {
					//console.info('control_deadLine EVENT 2: ', this.getLastDayOfMonth(event.getMonth(), event.getFullYear()))
					control_deadLine.value = this.getLastDayOfMonth(event.getMonth(), event.getFullYear())
					formControls['f_deadLine'] = control_deadLine

					//console.info('formControls[f_deadLine]: ', formControls['f_deadLine']);

					this.setState({
						...this.state,
						formControls			
					})	
				} 

								 	

			}

		} else {

			if (controlName==='f_sum' && this.props.isNeedUpdateCurrentClient) {
				let float_value = parseFloat(event.target.value)
				if (!isNaN(float_value)) {
					formControls['f_planTime'].value = parseFloat(float_value/this.props.isNeedUpdateCurrentClient.rate);
				}	
			}

			if (event.target.selectedOptions) {
				let value = Array.from(
					event.target.selectedOptions,
					(option) => option.value
				);
				control.value = value  
			} else {
				if (controlName==='f_isRepeat' || controlName==='f_isFix') {
					//console.log('GET CHECKBOX VALUES, controlName: ', controlName);
					//console.log('GET CHECKBOX VALUES, event.target.checked: ', +event.target.checked);
					control.value = +event.target.checked
				} else {
					control.value = event.target.value
				}
			}	
		}	

		control.touched = true
		control.valid = true //this.validateControl(control.value, control.validation)

		formControls[controlName] = control
		
		let isFormValid = true
		
		//console.log('onChangeHandler CONTROL: ', control);	

		this.setState({
			...this.state,
		 	formControls, 
			isFormValid
		})	

		if (controlName==='f_goalTitle' || controlName==='f_goalComment') {	
			//console.log('onChangeHandler event: ', event);
       		//console.log('onChangeHandler event.target: ', event.target);

			const element = event.target;
			   
			var offset = element.offsetHeight - element.clientHeight;

			//element.addEventListener('input', function (event) {
				//console.log('>> TEXTAREA HEIGHT');
				event.target.style.height = 0;
				event.target.style.height = event.target.scrollHeight + offset + 'px';
				//console.log('>> TEXTAREA HEIGHT: ', (event.target.scrollHeight + offset));
			//});

		}
	}

	closeAddPlanTaskPanel = () => {
		let panel = document.getElementById('addTaskSidePanel');
		panel.classList.toggle('active');
		//console.log('Close addPlanTask Clicked');
	}

	saveGoalData = () => {

		//console.log('===saveGoalData props: ', this.props);

		const sd_formControls = { ...this.state.formControls }
		let error_counter = 0;			

		const control_goalTitle = { ...sd_formControls['f_goalTitle'] }
		if (!control_goalTitle.value ||  control_goalTitle.value=='') {
			control_goalTitle.valid = false
			sd_formControls['f_goalTitle'] = control_goalTitle	
			error_counter++		
		} 	

		const control_clientId = { ...sd_formControls['f_clientId'] }
		if (!control_clientId.value || control_clientId.value=='') {
			control_clientId.valid = false
			sd_formControls['f_clientId'] = control_clientId
			error_counter++				
		} 
		
		const control_sum = { ...sd_formControls['f_sum'] }
		if (!control_sum.value || control_sum.value=='' || control_sum.value=='0') {
			control_sum.valid = false
			sd_formControls['f_sum'] = control_sum	
			error_counter++			
		}

		//let isFormValid = true		
		if (error_counter > 0) {
			this.setState({
				...this.state,
				formControls: sd_formControls			
			})
		}

		if (error_counter == 0) {

			this.setState({
				...this.state, 
				isFormValid: true,
				isEditGoal: false,
				oldEditGoalId: 0
			})	

			this.props.saveGoalData(
				(this.props.goalData && this.props.goalData.id ? this.props.goalData.id : this.props.goalId),
				this.props.name,
				this.props.data,
				this.state.formControls,
				this.props.userId
			)

			this.props.fireUpdateGoalsList()

			const formControls = { ...this.state.formControls }

			Object.values(formControls).map((formControl) => {
				formControl.value = ''
			})

			//Object.values(document.querySelectorAll('.spbbClientData')).map(item => item.innerHTML = '<span class="clientName">1 Выбрать...</span>')

			//.at.console.log(document.querySelectorAll('.cpfSelected'))

			this.setState({
				formControls, 
				isFormValid: false,
				isEditGoal: false,
				oldEditGoalId: 0
			})

			this.props.cancelEditGoal()

			this.closeAddPlanTaskPanel()

		}	
		
		//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state: ', this.state);
		//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state.isEditGoal: ', this.state.isEditGoal);
		//console.log('СОХРАНИЛИ ИНФОРМАЦИЮ, текущий state.oldEditGoalId: ', this.state.oldEditGoalId);
	}

	componentDidMount() {

		const { userId } = this.props;	

		
		

			//element.addEventListener('input', function (event) {
				

	}
	
	componentDidUpdate() {
		//console.log('componentDidUpdate. UPDATE TA-S');
		//this.adjustTextareas();
	}

	/*setStartDate = (date) => {
		console.log('date: ', date);
		this.setState({
			deadLine: date
		})	
	}*/

	adjustTextareas = () => {
		/*
		let textareas_list = document.querySelectorAll('.addGoalPanelWrapper textarea');

		textareas_list.forEach((one_textarea) => {
			console.log('000:', one_textarea);
			one_textarea.removeAttribute('style');
			console.log('001:', one_textarea);
			one_textarea.style.removeProperty("height");
			console.log('002:', one_textarea);
		});

		let textareas = [...document.querySelectorAll('.addGoalPanelWrapper textarea')];

		if (textareas) {

			textareas.map((one_textarea) => {	
				
				console.log('0:', one_textarea);				
				
				var offset = +one_textarea.offsetHeight - +one_textarea.clientHeight;			
				one_textarea.style.height = 0;

				let realHeight = +one_textarea.scrollHeight + offset

				console.log('realHeight: ',  realHeight);
				one_textarea.style.height = realHeight + 'px';

				console.log('= TEXTAREA HEIGHT: ', (one_textarea.scrollHeight + offset));
				
			})
		}
		*/
	}	

	render() {

		//console.log('PLAN 00011');
		//this.adjustTextareas();
		

		//console.log('-01- TASK PANEL RENDER, state: ', this.state);
		//console.log('-01- TASK PANEL RENDER, props: ', this.props);
		//console.log('-01- TASK PANEL RENDER, this.props.isNeedUpdateCurrentClient:', this.props.isNeedUpdateCurrentClient.id);

		//const [startDate, setStartDate] = new Date();

		//let startDate  = this.state.deadLine;

		//const setStartDate  = new Date();

		const { error, isLoaded, goal } = this.state;

		let rate = false

		if (this.props.isNeedUpdateCurrentClient) {
			
			rate = this.props.isNeedUpdateCurrentClient.rate				
			//console.log('RATE: ', rate) 
		}
		
		//console.log('ADDGOALPANEL RENDER, state: ', this.state);

		/*if (error) {
			return <div className="addGoalPanelWrapper" key="0">Ошибка загрузки данных задачи. (1)</div>;
		} else if (!isLoaded) {
			return <div className="addGoalPanelWrapper" key="1">Загружаем данные формы добавления задач... (2)</div>;
		} else {*/
			return (
				/*goal.map((goalData, index) => (*/
					<div className="addGoalPanelWrapper" key="2">

						<div className="tmspHeader">
							<div className="tmTrash"><button data-value="#trashGoal" title="Удалить задачу"></button></div>
							<div className="tmClose"><button data-value="#closeGoal" onClick={this.closeAddPlanTaskPanel} title="Закрыть панель"></button></div>	
						</div>
						<div className="tmspData">
							<div className="tmspdRow">	
								<div className="goalTitle">									

									<Textarea 
										key="1"													
										value={this.state.formControls['f_goalTitle'].value}		
										placeholder="Укажите название задачи"													
										label=""
										onChange={event => this.onChangeHandler(event, 'f_goalTitle')}
										isValid={this.state.formControls['f_goalTitle'].valid}	
										name="f_goalTitle"			
									/>

								</div>
							</div>
							<div className="tmspdRow flexStart">
								<div className="spBlock w142">

									<ClientList 
										isSimple="true"
										name="f_clientId"
										type="hidden" 
										value={this.state.formControls['f_clientId'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										isValid={this.state.formControls['f_clientId'].valid}
										id="2"
									/>

								</div>
								<div className="spBlock w142">
									
									<JobCategoryList
										name="f_goalCategoryId"
										type="hidden" 
										value={this.state.formControls['f_goalCategoryId'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
										clientId={this.state.formControls['f_clientId'].value ? this.state.formControls['f_clientId'].value : this.props.isNeedUpdateCurrentClient.id} 
									/>
												
								</div>
							</div>
							<div className="tmspdRow">
								<div className="spBlock w120">
									{/*}
									<MonthList 
										name="f_planMonth"
										type="hidden" 
										value={this.state.formControls['f_planMonth'].value} 
										onChangeHandlerList={this.onChangeHandlerList}
										isEditGoal={this.state.isEditGoal}
									/>
									*/}
									<div className="spbHeader"><label htmlFor="f_planMonth">Месяц</label></div>
									<div className="spbBody">
										<DatePicker 
											key="1"
											id="f_planMonth" 
											selected={this.state.formControls['f_planMonth'].value} 
											onChange={(event, date) => this.onChangeHandler(event, 'f_planMonth', date)}
											locale="ru"
											dateFormat="LLLL ’yy"
											showMonthYearPicker
											showFullMonthYearPicker
											showFourColumnMonthYearPicker
										/>		
									</div>

								</div>

								<div className="spBlock shortInput">									
									<Input 
										key="15"
										type="text"
										value={this.props.isNeedUpdateCurrentClient.rate ? this.props.isNeedUpdateCurrentClient.rate : ''}			
										placeholder="Клиент ?"	
										disabled="disabled"									
										label="Ставка, руб./ч."												
									/>
								</div>	

								<div className="spBlock">									
									<Input 
										key="5"
										type="text"
										value={this.state.formControls['f_sum'].value}												
										label="Сумма, руб."
										placeholder={this.state.formControls['f_sum'].placeholder}	
										onChange={event => this.onChangeHandler(event, 'f_sum')}	
										isValid={this.state.formControls['f_sum'].valid}	
										name="f_sum"	
									/>	
								</div>
								<div className="spBlock">
									<Input 
										key="6"
										type="text"
										value={this.state.formControls['f_planTime'].value}		
										placeholder={this.state.formControls['f_planTime'].placeholder}											
										label="План, ч."
										disabled="disabled"
										onChange={event => this.onChangeHandler(event, 'f_planTime')}			
									/>									
								</div>
								<div className="spBlock">
									{/*}
									<Input 
										key="7"
										type="text"
										value={this.state.formControls['f_deadLine'].value}												
										label="Дедлайн"
										placeholder="гггг-мм-дд чч:мм:сс"
										onChange={event => this.onChangeHandler(event, 'f_deadLine')}			
									/>
									*/}
									<div className="spbHeader"><label htmlFor="f_deadLine">Дедлайн</label></div>
									<div className="spbBody">
										<DatePicker 
											key="2"
											id="f_deadLine" 
											selected={this.state.formControls['f_deadLine'].value} 
											onChange={(event, date) => this.onChangeHandler(event, 'f_deadLine', date)}
											locale="ru"
											dateFormat="dd.MM.yyyy"
										/>		
									</div>								
								</div>
							</div>
							<div className="tmspdRow">
								<div className="spBlock">									

									<Checkbox 
										key="8"
										type="F"
										value={this.state.formControls['f_isFix'].value}												
										label="Фикс"										
										onChange={event => this.onChangeHandler(event, 'f_isFix')}			
									/>					

								</div>
								<div className="spBlock">

									<Checkbox 
										key="9"
										type="R"
										value={this.state.formControls['f_isRepeat'].value}												
										label="Повтор"										
										onChange={event => this.onChangeHandler(event, 'f_isRepeat')}			
									/>	
									
								</div>
								<div className="spBlock">
									{/*									
									<Input 
										key="10"
										type="text"
										value={this.state.formControls['f_goalRepeatInterval'].value}			
										placeholder={this.state.formControls['f_goalRepeatInterval'].placeholder}										
										label="Интервал, д."										
										onChange={event => this.onChangeHandler(event, 'f_goalRepeatInterval')}			
									/>
									*/}

									<SimpleList 
										key="10"
										type="text"
										value={this.state.formControls['f_goalRepeatInterval'].value}			
										placeholder={this.state.formControls['f_goalRepeatInterval'].placeholder}										
										label="Интервал, д."	
										list={
											[
												{name: '1 день', value: 1},
												{name: '1 неделя', value: 7},
												{name: '2 недели', value: 14},
												{name: '1 месяц', value: 30}
											]	
										}									
										onChangeHandler={event => this.onChangeHandler(event, 'f_goalRepeatInterval')}			
									/>
								</div>
							</div>


							{				
							this.props.isShowCorrected
							?
							<div className="tmspdRow">	
								<div className="spBlock w100percent">									
									<Input 
										key="11"
										type="text"
										value={this.state.formControls['f_sumCorrected'].value}												
										label="Скорректированная сумма, руб."
										placeholder={this.state.formControls['f_sumCorrected'].placeholder}	
										onChange={event => this.onChangeHandler(event, 'f_sumCorrected')}			
									/>	
								</div>			
							</div>
							:
							<></>
							}

							<div className="tmspdRow">								

								<Textarea 
									key="12"													
									value={this.state.formControls['f_taskResult'].value}		
									placeholder="Ссылка на результат (https://...)"													
									label="Ссылка на результат"
									onChange={event => this.onChangeHandler(event, 'f_taskResult')}
									className="nomargin"			
								/>

							</div>

							<div className="tmspdRow">								

								<Textarea 
									key="13"													
									value={this.state.formControls['f_goalComment'].value}		
									placeholder="Укажите комментарий"													
									label="Комментарий"
									onChange={event => this.onChangeHandler(event, 'f_goalComment')}			
								/>

							</div>
							<div className="tmspdFooter"><button data-value="#createGoal" onClick={this.saveGoalData}>{this.state.isEditGoal ? 'Сохранить' : 'Добавить задачу'}</button></div>
						</div>


						
							
							

						

					</div>
				/*))			*/
			);
		/*}			*/
		
	}

}

function mapDispatchToProps(dispatch) {
	return {
		/*hideModal: () => {
			dispatch(hideModal())
		},*/

		saveGoalData: (goalId, cardText, formData, fields, userId) => dispatch(saveGoalData(goalId, cardText, formData, fields, userId)),
		setTaskTableFilterClient: (client) => dispatch(setTaskTableFilterClient(client)),
		dispatch: dispatch		

	}
}


const mapStateToProps = (state, ownProps) => ({	
	userId: state.auth.userId,
	isNeedUpdateCurrentClient: state.goal.isNeedUpdateCurrentClient
});  

export default connect(mapStateToProps, mapDispatchToProps)(AddGoalPanelWrapper);